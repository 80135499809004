import { Grid } from "@material-ui/core";
import { stringify } from "querystring";
import { useResourceContext } from "react-admin";
import { useQuery } from "react-query";
import { client } from "../../dataProvider";
import { useListQueryParams } from "../../shared/hooks/useListQueryParams";
import { DescriptionOutlined, Euro } from "@material-ui/icons";
import { StatCard } from "../../components/StatCard";
import { formatThousandsNumber } from "../../shared/utils";

export const CachetBoxHeader = () => {
  const resource = useResourceContext();
  const query = useListQueryParams();
  const url = `${resource}/statistics/?${stringify(query)}`;
  const { data: stats } = useQuery(
    [url],
    () => client(url, { method: "GET" }),
    {
      initialData: {
        total_cash: 0, total_check: 0, total_payament_card: 0, 
        total_rebate: 0
      }
    }
  );
  return (
    <Grid container style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`Contanti: ${formatThousandsNumber(stats.total_cash)}`}
          icon={<Euro />}
          subtitle="Contanti"
        />
      </Grid>
      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`Assegno: ${formatThousandsNumber(stats.total_check)}`}
          icon={<Euro />}
          subtitle="Assegno"
        />
      </Grid>
      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`Carta di credito: ${formatThousandsNumber(stats.total_payament_card)}`}
          icon={<Euro />}
          subtitle="Carta di credito"
        />
      </Grid>
      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`Abbuono: ${formatThousandsNumber(stats.total_rebate)}`}
          icon={<Euro />}
          subtitle="Abbuono"
        />
      </Grid>

    </Grid>
  );
};
