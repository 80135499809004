import { Typography } from "@material-ui/core";
import { Record } from "ra-core";
import { FieldProps, FunctionField, useRecordContext } from "react-admin";
import { getUser } from "../../authProvider";
import { CarrierField } from "../../components/CarrierField";
import { DriverField } from "../../components/DriverField";
import { initField } from "../../shared/utils";
import { useEffect, useMemo, useState } from "react";

export const DriverCarrierField = (
    props: FieldProps & { source?: string, forceTypografy?: boolean }
) => {
    const record = useRecordContext(props);
    const [newRecord, setNewRecord] = useState<Record | undefined>(record);
    const user = getUser();

    useMemo(() => {
        if (record && props.source && record[props.source]) {
            setNewRecord(record[props.source]);
        }
    }, [record]);

    return newRecord ? (
        <FunctionField
            record={newRecord}
            render={(r: Record | undefined) => {
                return (r ? (
                    r.driver ? (((
                        (new Date(r.planned_date).getTime() - new Date().getTime()) / 3600000 < 1.5) ||
                        user.permissions.includes("distribution.change_route") &&
                        user.permissions.includes("employees.view_employee")
                    ) ? (
                        props.forceTypografy ? (
                            <Typography>{r.driver.surname + " " + r.driver.name}</Typography>
                        ) : (
                            <DriverField source="driver" label={"Autista"} />)
                    ) : (
                        <Typography style={{
                            filter: "blur(4px)",
                        }}>E' colpa di Be20</Typography>))
                        : (
                            props.forceTypografy ? (
                                <Typography>{r.carrier.business_name}</Typography>
                            ) : (
                                <CarrierField source="carrier" label={"Vettore"} />
                            )
                        )
                ) : null
                )
            }
            }
        />

    ) : null;
};
