import {
  Grid,
  Typography,
  ButtonGroup,
  Dialog,
  Button,
  TextField as MuiTextInput,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  TableContainer,
  Box,
  makeStyles,
} from "@material-ui/core";
import { SetStateAction, useMemo, useState } from "react";
import {
  TextInput,
  SelectArrayInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ButtonProps,
  useNotify,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  ReferenceManyField,
  useRecordContext,
  useRedirect,
  Datagrid,
  DeleteButton,
  FunctionField,
  TextField,
} from "react-admin";
import { NumberInput } from "../components/NumberInput";
import { MapContainer, TileLayer } from "react-leaflet";
import { AddressInput } from "../components/AddressInput";
import { DrawControl } from "../components/DrawControls";
import { useToggler } from "../shared/hooks/useToggler";
import { initField, initInput } from "../shared/utils";
import { useForm, useFormState } from "react-final-form";
import MapIcon from "@material-ui/icons/Map";
import { useOptions } from "../shared/hooks/useOptions";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { client } from "../dataProvider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TariffDatagrid } from "../Items/Tariff/TariffDatagrid";
import { ReferenceInputTermsPayment } from "../components/reference-inputs/ReferenceInputTermsPayment";
import { getUser } from "../authProvider";
import { ReferenceInputBank } from "../components/reference-inputs/ReferenceInputBank";
import { AddSubjectAttachmentButton } from "./AddSubjectAttachmentButton";
import { IdentifierField } from "../components/IdentifierField";
import { CustomFileField } from "../components/CustomFIleField";
import SubjcetTimeSelector from "../components/SubjectTimeSelector";

const useIteratorStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1em',
    flexWrap: 'wrap',
  }
}));

const validateTime = (value: string) => {
  // Regex per validare ore e minuti nel formato HH:MM
  const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

  // Se non corrisponde alla regex, ritorna un messaggio di errore
  if (!regex.test(value)) {
    return 'Il formato deve essere HH:MM (ore tra 00 e 23, minuti tra 00 e 59)';
  }
  return undefined;
};

const CalculateCoordinatesButton = (props: ButtonProps) => {
  const { values } = useFormState();
  const form = useForm();
  const notify = useNotify();
  return (
    <Button
      {...props}
      onClick={() => {
        if (
          (values.locality || values.postal_code) &&
          values.province &&
          values.address
        ) {
          const url = `autocomplete/address_latlon`;
          client(url, {
            method: "GET",
            params: {
              postal_code: values.postal_code,
              province: values.province,
              locality: values.locality,
              address: values.address,
            },
          })
            .then((response) => {
              if (response) {
                form.change("latitude", response.lat);
                form.change("longitude", response.lon);
                form.change("altitude", response.altitude);
              } else {
                notify("Nessun risultato trovato!", "warning");
              }
            })
            .catch(() => notify("Errore!", "error"));
        } else {
          notify(
            "Inserire indirizzo, localita, cap e provincia, prima di calcolare le coordinate",
            "error"
          );
        }
      }}
    >
      Calcola
    </Button>
  );
};

const ValidateAddressButton = (props: ButtonProps) => {
  const { values } = useFormState();
  const form = useForm();
  const notify = useNotify();
  return (
    <Button
      {...props}
      onClick={() => {
        if (values.locality || values.province || values.postal_code) {
          const url = `autocomplete/address_info`;
          client(url, {
            method: "GET",
            params: {
              postal_code: values.postal_code,
              province: values.province,
              locality: values.locality,
            },
          })
            .then((response) => {
              form.change("locality", response.locality);
              form.change("province", response.province);
              form.change("postal_code", response.postal_code);
            })
            .catch(() => notify("Errore!", "error"));
        } else {
          notify(
            "Inserire almeno un dato tra localita, cap e provincia, prima di validare",
            "error"
          );
        }
      }}
    >
      Valida indirizzo
    </Button>
  );
};
const AddTariffButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  return (
    <Button
      color="primary"
      onClick={() =>
        redirect(
          "create",
          "/tariffs",
          undefined,
          {},
          { record: { client_id: record.id } }
        )
      }
    >
      Crea tariffa
    </Button>
  );
};


export const SubjectFormContent = (props: {
  canEdit?: boolean;
  fieldOptions: any;
}) => {
  const drawOptions = useMemo<L.Control.DrawOptions>(() => {
    return {
      polygon: false,
      polyline: false,
      circle: false,
      rectangle: false,
      circlemarker: false,
      marker: false,
    };
  }, []);
  const { data: optionsNotes } = useOptions("specification-note", "GET");
  const { data: optionsReferrers } = useOptions("referrers", "GET");
  const { data: optionsSubjectOpenings } = useOptions("subjectopenings", "GET");
  const { data: optionsAttachment } = useOptions("subject_attachments", "GET");
  const { value, setTrue, setFalse } = useToggler();
  const { getState, change } = useForm();
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const iteratorClasses = useIteratorStyle();
  const [accrodinonTariffs, setAccrodinonTariffs] = useState(false);
  const [accrodinonAttachments, setAccrodinonAttachments] = useState(false);
  const [days, setDays] = useState<[]>([]);
  const [timeFrom, setTimeFrom] = useState("08:00");
  const [timeTo, setTimeTo] = useState("13:00");

  const handleChangeDays = (event: any) => {
      setDays(event.target.value);
  };

  const handleTimeFromChange = (event: any) => {
      setTimeFrom(event.target.value);
  };

  const handleTimeToChange = (event: any) => {
      setTimeTo(event.target.value);
  };

  const handleSubjectOpenings = () => {
    for (let day of days) {
      setTimeout(() => {
        const formState = getState();
        let old_subjectopenings = formState.values.subjectopenings || [];
  
        const new_hours = {
          day: day,
          hour_from: timeFrom,
          hour_to: timeTo
        };  
        let updated_subjectopenings = [...old_subjectopenings, new_hours];  
        const dayOrder:any = {
          "MO": 1,
          "TU": 2,
          "WE": 3,
          "TH": 4,
          "FR": 5,
          "SA": 6,
          "SU": 7
        };
  
        updated_subjectopenings.sort((a, b) => {
          return dayOrder[a.day] - dayOrder[b.day];
        });
  
        change('subjectopenings', updated_subjectopenings);
      }, 2000);
    }
  };
  

  return optionsNotes && optionsReferrers && optionsSubjectOpenings ? (
    <>
      <Grid container justifyContent="flex-start" direction={"row"} spacing={2}>
        <Grid item container xs={8}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Info principali
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              {...initInput("business_name", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectArrayInput
              optionValue="value"
              choices={props.fieldOptions.type.child.choices}
              optionText="display_name"
              {...initInput("type", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="phone"
              {...initInput("phone_number", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="phone"
              {...initInput("fax", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="email"
              {...initInput("email", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="pec"
              {...initInput("pec", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="email_op"
              {...initInput("email_op", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              type="text"
              {...initInput("notes", props.fieldOptions, props.canEdit)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Contabilità
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              {...initInput("vat_number", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              {...initInput("fiscal_code", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              {...initInput("cod_sdi", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInputTermsPayment
              {...initInput(
                "payment_terms_id",
                props.fieldOptions,
                props.canEdit
              )}
              perPage={100}
            />
            <Grid item xs={12}>
              <TextInput
                {...initInput("iban", props.fieldOptions, props.canEdit)}
              />
            </Grid>
            <Grid>
              <ReferenceInputBank
                {...initInput(
                  "bank_id",
                  props.fieldOptions,
                  props.canEdit
                )}
                allowEmpty
              />
            </Grid>
            {
              /*
              <ReferenceInput
              {...initInput(
                "payment_terms_id",
                props.fieldOptions,
                props.canEdit
              )}
              reference="payment-terms"
              perPage={10000}
            >
              <SelectInput optionText="label" />
            </ReferenceInput>
              */
            }
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item direction="row" container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">Posizione</Typography>
            </Grid>
            <Grid item>
              <ButtonGroup variant="text" color="primary">
                <ValidateAddressButton />
                <CalculateCoordinatesButton />
                <Button aria-label="Apri mappa" onClick={setTrue}>
                  <MapIcon color="primary" />
                </Button>
                <Button
                  onClick={() => {
                    const { values } = getState();
                    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      (
                        values.business_name +
                        " " +
                        values.address +
                        " " +
                        values.locality +
                        " " +
                        values.postal_code +
                        " " +
                        values.province
                      ).replaceAll("null", "")
                    )}`;

                    window.open(url, "_blank");
                  }}
                  aria-label="Apri google maps"
                >
                  Gmaps
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            <MuiTextInput
              onChange={(event) => {
                const value = event.target.value;
                const coords = value.replaceAll(" ", "").split(",");
                if (value.includes(",") && coords.length === 2) {
                  change("latitude", coords[0]);
                  change("longitude", coords[1]);
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
              label="Incolla da gmaps"
            />
          </Grid>
          <Grid item>
            <AddressInput
              options={props.fieldOptions}
              canEdit={props.canEdit}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <NumberInput
                {...initInput("latitude", props.fieldOptions, props.canEdit)}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                {...initInput("longitude", props.fieldOptions, props.canEdit)}
              />
            </Grid>

            <Grid item xs={4}>
              <NumberInput
                {...initInput("altitude", props.fieldOptions, props.canEdit)}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Dettagli secondari
            </Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "internal_notes",
                    props.fieldOptions,
                    props.canEdit
                  )}
                /></Grid>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "no_print_notes",
                    props.fieldOptions,
                    props.canEdit
                  )}
                /></Grid>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "to_be_tractioned",
                    props.fieldOptions,
                    props.canEdit
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "hide",
                    props.fieldOptions,
                    props.canEdit
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <TextInput
              {...initInput(
                "external_reference",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>
          <Grid item>
            <TextInput
              {...initInput(
                "business_name_print",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

          <Grid item>
            <ReferenceInputSubject
              {...initInput(
                "main_subject_id",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Dettagli magazzino
            </Typography>
          </Grid>
          <Grid item>
            <ReferenceInputSubject
              {...initInput(
                "starting_point_id",
                props.fieldOptions,
                props.canEdit
              )}
              filter={{ type: "receiver" }}
              allowEmpty
            />
          </Grid>

          <Grid item>
            <ReferenceArrayInput
              {...initInput("stores", props.fieldOptions, props.canEdit)}
              reference="autocomplete/subject"
              filter={{ type: "receiver" }}
            >
              <AutocompleteArrayInput optionText="label" />
            </ReferenceArrayInput>
          </Grid>
          <Grid item>
            <ReferenceInputSubject
              {...initInput(
                "back_point_id",
                props.fieldOptions,
                props.canEdit
              )}
              filter={{ type: "receiver" }}
              allowEmpty
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h6">Referenti</Typography>
      <ArrayInput
        {...initInput("referrers", props.fieldOptions, props.canEdit)}
        label="Referenti"
      >
        <SimpleFormIterator classes={iteratorClasses}>
          <TextInput source="first_name" label="Nome" />
          <TextInput source="surname" label="Cognome" />
          <SelectArrayInput
            optionValue="value"
            choices={optionsReferrers.type.child.choices}
            optionText="display_name"
            source="type"
            label="Tipo"

          />
          <TextInput source="phone_number" label="Telefono" />

          <TextInput source="email" label="Email" />
          <TextInput multiline source="notes" label="Note" />

        </SimpleFormIterator>
      </ArrayInput>
      <Typography variant="h6">Note di capitolato</Typography>
      <ArrayInput
        {...initInput("specification_notes", props.fieldOptions, props.canEdit)}
        label="Note di capitolato"
      >
        <SimpleFormIterator classes={iteratorClasses}>
          <ReferenceInputSubject
            source="client"
            filter={{ type: "client" }}
            label="Cliente"
          />
          <SelectInput
            optionValue="value"
            choices={optionsNotes.type.choices}
            optionText="display_name"
            source="type"
            label="Tipo"
          />
          <TextInput multiline source="notes" label="Note" />
        </SimpleFormIterator>
      </ArrayInput>
      <Typography variant="h6">Orari apertura</Typography>

      <SubjcetTimeSelector 
      days={days} timeFrom={timeFrom} timeTo={timeTo} 
      handleChangeDays={handleChangeDays} handleTimeFromChange={handleTimeFromChange} handleTimeToChange={handleTimeToChange}
      handleSubjectOpenings={handleSubjectOpenings}
      />

      <ArrayInput
        {...initInput("subjectopenings", props.fieldOptions, props.canEdit)}
        label="Orari apertura"
      >
        <SimpleFormIterator classes={iteratorClasses}>
          <SelectInput
            optionValue="value"
            choices={optionsSubjectOpenings.day.choices}
            optionText="display_name"
            source="day"
            label="Giorno"
            validate={[() => {
              if (getState().values["subjectopenings"].length > 1) {
                for (let i = 1; i < getState().values["subjectopenings"].length; i++) {
                  if (!getState().values["subjectopenings"][i - 1] || !getState().values["subjectopenings"][i])
                    continue
                  // check if week day is less than the previous one, days are formatted
                  /**
                    MONDAY = 'MO', "Lunedì"
                    TUESDAY = 'TU', "Martedì"
                    WEDNESDAY = 'WE', "Mercoledì"
                    THURSDAY = 'TH', "Giovedì"
                    FRIDAY = 'FR', "Venerdì"
                    SATURDAY = 'SA', "Sabato"
                    SUNDAY = 'SU', "Domenica"
                   */
                  switch (getState().values["subjectopenings"][i].day) {
                    case "MO":
                      if (["TU", "WE", "TH", "FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                        return "Errore ordine giorni"
                      }
                      break;
                    case "TU":
                      if (["WE", "TH", "FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                        return "Errore ordine giorni"
                      }
                      break;
                    case "WE":
                      if (["TH", "FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                        return "Errore ordine giorni"
                      }
                      break;
                    case "TH":
                      if (["FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                        return "Errore ordine giorni"
                      }
                      break;
                    case "FR":
                      if (["SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                        return "Errore ordine giorni"
                      }
                      break;
                    case "SA":
                      if (["SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                        return "Errore ordine giorni"
                      }
                      break;
                    case "SU":
                      break;
                  }
                }
              }
            }]}
          />
          <TextInput source="hour_from" label="Dalle" required validate={[(e: any) => {
            let key = getState().active?.split(".")[0].replace("subjectopenings", "").replace("[", "").replace("]", "")
            if (key && getState().values && getState().values.subjectopenings[key] && getState().values["subjectopenings"][key].hour_to) {
              if (getState().values["subjectopenings"][key].hour_to < getState().values["subjectopenings"][key].hour_from) {
                return "L'orario di apertura deve essere minore di quello di chiusura"
              }
            }
          },
            validateTime,
          () => {
            if (getState().values["subjectopenings"].length > 1) {
              for (let i = 1; i < getState().values["subjectopenings"].length; i++) {
                if (
                  getState().values["subjectopenings"][i - 1] && getState().values["subjectopenings"][i] &&
                  getState().values["subjectopenings"][i - 1].day === getState().values["subjectopenings"][i].day &&
                  getState().values["subjectopenings"][i].hour_from < getState().values["subjectopenings"][i - 1].hour_to) {
                  return "Errore sovrapposizoone orari"
                }
              }
            }
          }]} />
          <TextInput source="hour_to" label="Alle" required validate={[() => {
            let key = getState().active?.split(".")[0].replace("subjectopenings", "").replace("[", "").replace("]", "")
            if (key && getState().values && getState().values.subjectopenings[key] && getState().values["subjectopenings"][key].hour_from) {
              if (getState().values["subjectopenings"][key].hour_to < getState().values["subjectopenings"][key].hour_from) {
                return "L'orario di chiusura deve essere maggiore di quello di apertura"
              }
            }
          },
            validateTime,
          () => {
            if (getState().values["subjectopenings"].length > 1) {
              for (let i = 1; i < getState().values["subjectopenings"].length; i++) {
                if (
                  getState().values["subjectopenings"][i - 1] && getState().values["subjectopenings"][i] &&
                  getState().values["subjectopenings"][i - 1].day === getState().values["subjectopenings"][i].day &&
                  getState().values["subjectopenings"][i].hour_from < getState().values["subjectopenings"][i - 1].hour_to) {
                  return "Errore sovrapposizoone orari"
                }
              }
            }
          }]} />
        </SimpleFormIterator>
      </ArrayInput>

      {window.location.pathname !== "/subjects/create" && <Grid item xs={12}>

        <Accordion
          expanded={accrodinonAttachments}
          onClick={() => setAccrodinonAttachments(!accrodinonAttachments)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="h6" gutterBottom>
              Allegati
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}
            onClick={(e) => { e.stopPropagation() }}>
            {
              accrodinonAttachments ? <>
                <AddSubjectAttachmentButton />
                <Grid item xs={12}>
                  <TableContainer>
                    <ReferenceManyField
                      reference="subject_attachments"
                      target="subject"
                    >
                      <Datagrid>
                        <FunctionField
                          render={(record: any) => (
                            <DeleteButton
                              record={record}
                              resource="subject_attachments"
                              redirect={`/subjects/${record.subject}`}
                              mutationMode="pessimistic"
                            />
                          )}
                        />
                        <IdentifierField
                          {...initField("id", optionsAttachment)}
                        />

                        <CustomFileField
                          {...initField("file_info", optionsAttachment)}
                          title="name"
                          src="url"
                        />

                        <TextField
                          {...initField("notes", optionsAttachment)}
                        />
                      </Datagrid>
                    </ReferenceManyField>
                  </TableContainer>
                </Grid>
              </> : null
            }
          </AccordionDetails>
        </Accordion>
      </Grid>}
      {getState().values && getState().values.id && optionsTariff &&
        getUser().permissions.includes("distribution.change_tariff") && (
          <Accordion
            expanded={accrodinonTariffs}
            onChange={() => setAccrodinonTariffs(!accrodinonTariffs)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="h6" gutterBottom>
                Tariffe
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}
              onClick={(e) => { e.stopPropagation() }}>
              {accrodinonTariffs ? <>
                <Box display="flex" justifyContent="flex-end">
                  <AddTariffButton />
                </Box>
                <Grid item xs={12}>
                  <TableContainer>
                    <ReferenceManyField reference="tariffs" target="client" filter={{ last_tariff: true }}>
                      <TariffDatagrid options={optionsTariff} />
                    </ReferenceManyField>
                  </TableContainer>
                </Grid>
              </> : null
              }
            </AccordionDetails>
          </Accordion>
        )}
      <Dialog open={value} onClose={setFalse} fullWidth>
        <MapContainer
          center={[41.2925, 12.5736]}
          zoom={5}
          style={{ height: "300px", width: "auto" }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <DrawControl edit={{}} drawOptions={drawOptions} />
        </MapContainer>
      </Dialog>
    </>
  ) : null;
};
