import React from "react";
import { AutocompleteArrayInput, AutocompleteInput, BooleanInput, DateTimeInput, NumberInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { Grid, Typography } from "@material-ui/core";

export const BadgeForm = (props: FormProps) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
    >
      <ReferenceInput
        {...initInput("employee", props.fieldOptions, props.canEdit)}
        reference="autocomplete/employee"
        source="employee"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>

      <ReferenceInput
        {...initInput("user", props.fieldOptions, props.canEdit)}
        reference="autocomplete/user"
        source="user"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>

      <BooleanInput {...initInput("can_login", props.fieldOptions, props.canEdit)} />


      <NumberInput
        {...initInput("max_work_time", props.fieldOptions, props.canEdit)}
        lbel="Massime ore lavorative"
      />

    </SimpleForm >
  );
};
