import {
  BooleanField,
  Datagrid,
  FunctionField,
  Loading,
  NumberField,
  ReferenceArrayField,
} from "ra-ui-materialui";
import {
  Identifier,
  ListContextProvider,
  useList,
  useListContext,
  useQueryWithStore,
  useResourceContext,
  Record,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { useGroupedListContext } from "../../components/GroupedList/GroupedListContext";
import { SubjectField } from "../../components/SubjectField";
import { CreateRemittanceButton } from "./CreateRemittanceButton";
import { CachetPaymentDatagrid } from "./CachetPayment/CachetPaymentDatagrid";
import { Checkbox } from "@material-ui/core";

interface GroupedDatagridProps {
  showCreateRemittanceButton: boolean;
  selectValue?: any;
  setSelectValue?: any;
}
const Expand = ({
  record,
}: {
  basePath: string;
  id: Identifier;
  record: Record;
  resource: string;
}) => {
  return (
    <ReferenceArrayField
      reference="cachet-payments"
      record={record}
      source="payments_ids"
    >
      <CachetPaymentDatagrid />
    </ReferenceArrayField>
  );
};


export const GroupedDatagrid = ({
  showCreateRemittanceButton,
  selectValue,
  setSelectValue
}: GroupedDatagridProps) => {
  const resource = useResourceContext();
  const { filterValues, filter } = useListContext();
  const { groupedBy } = useGroupedListContext();
  const { data, loading, loaded } = useQueryWithStore({
    type: "getGroupedBy",
    resource: resource,
    payload: { group_by: groupedBy, ...filterValues, ...filter },
  });
  const listContext = useList({
    data,
    ids: data ? data.map((d: any) => d.driver) : [],
    loaded,
    loading,
  });

  const handleClick = (record: any, isSelected: boolean) => {
    if(!setSelectValue || !selectValue) return;
    let base = 1;
    let payments_ids = {}
    let ids = []

    if(isSelected) {
      base = -1;
      ids = selectValue["ids"].filter((id: any) => id !== record.id)
      delete selectValue["payments_ids"][record.id]
    } else {
      base = 1;
      ids = [...selectValue["ids"], record.id]
      payments_ids = {...selectValue["payments_ids"], [record.id]: record.payments_ids}
    }

    setSelectValue({
      "ids": ids,
      "payments_ids": payments_ids,
      "Contanti": selectValue ? parseFloat((selectValue["Contanti"] + record.total_cash * base).toFixed(2)) : 0,
      "Assegno": selectValue ? parseFloat((selectValue["Assegno"] + record.total_check * base).toFixed(2)) : 0,
      "Abbuono": selectValue ? parseFloat((selectValue["Abbuono"] + record.total_rebate * base).toFixed(2)) : 0,
      "Incasso": selectValue ? parseFloat((selectValue["Incasso"] + record.total_cashed * base).toFixed(2)) : 0,
      "Da incassare": selectValue ? parseFloat((selectValue["Da incassare"] + record.total_to_cash_in * base).toFixed(2)) : 0,
    });

  };

  if (loading) return <Loading />;
  return (
    <ListContextProvider value={listContext}>
      <Datagrid expand={showCreateRemittanceButton ? Expand : undefined}>
        {(selectValue ) && (
          <FunctionField
            label=""
            render={(tableRecord: any) => {
              const isSelected = selectValue["ids"].some((id: any) => id === tableRecord.id);
              return (
                <Checkbox
                  checked={isSelected}
                  onChange={() => handleClick(tableRecord, isSelected)}
                  inputProps={{ 'aria-label': 'seleziona riga' }}
                />
              );
            }}
          />
        )}
        {groupedBy === "client" && (
          <SubjectField label="Cliente" source="client"></SubjectField>
        )}
        {groupedBy === "driver" && (
          <DriverField label="Autista" source="driver"></DriverField>
        )}
        {groupedBy === "carrier" && (
          <SubjectField label="Vettore" source="carrier"></SubjectField>
        )}
        <NumberField
          label="Tot. contanti"
          source="total_cash"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. assegno"
          source="total_check"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. abbuono"
          source="total_rebate"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. incassato"
          source="total_cashed"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. da incassare"
          source="total_to_cash_in"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>

        {groupedBy === "client" && showCreateRemittanceButton && (
          <FunctionField
            render={(record: any) => (
              <CreateRemittanceButton ids={record.payments_ids} />
            )}
          />
        )}
      </Datagrid>
    </ListContextProvider>
  );
};
