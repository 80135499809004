import { Grid, Typography } from "@material-ui/core";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  Record,
  SelectField,
  SingleFieldList
} from "react-admin";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { RouteField } from "../../components/RouteField";
import { VehicleField } from "../../components/VehicleField";
import { DriverCarrierField } from "../../Distribution/Route/DriverCarrierField";
import { DatagridPropsWithOptions, DeliveryStatusChoice } from "../../shared/types";
import { initField } from "../../shared/utils";
import { RouteSegmentMonitorShow } from "./RouteSegmentMonitorShow";

export const RouteMonitorDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  return (
    <Datagrid {...rest}>
      <RouteField routeSource={"id"} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <FunctionField
        label={"Progresso e info"}
        render={(record: Record | undefined) => {

          let info = {
            done: 0,
            anomaly: 0,
            deliveries: 0
          }
          for (let segment of record!.ordered_segments.segments) {

            if ([DeliveryStatusChoice.DELIVERED].includes(segment.delivery_last_status)) {
              info.done += 1;
            }
            else if ([DeliveryStatusChoice.SUSPENDED].includes(segment.delivery_last_status)) {
              info.anomaly += 1;
            }
            else if ([
              DeliveryStatusChoice.CANCELED,
              DeliveryStatusChoice.STOCKED,
              DeliveryStatusChoice.STOCKED_END,
            ].includes(segment.delivery_last_status)) {
              continue;
            }

            info.deliveries += 1;

          }

          return record && record.ordered_segments ? (
            <Grid container>
              <Grid item xs={9}>
                <div style={{
                  display: "flex", flexDirection: "row"
                }} >
                  <div style={{
                    display: "flex", flexDirection: "row", width: "200px", height: "15px",
                    borderRadius: "5px", border: "2px solid gray", padding: "0px"
                  }}>
                    <div style={{
                      backgroundColor: "green",
                      width: (Math.round(info.done / info.deliveries * 10000) / 100).toString() + "%", height: "100%", margin: "0px"
                    }}>
                    </div>
                    <div style={{
                      backgroundColor: "red",
                      width: (Math.round(info.anomaly / info.deliveries * 10000) / 100).toString() + "%", height: "100%", margin: "0px"
                    }}>
                    </div>
                  </div>
                  <div> <Typography>% {(Math.round((info.done + info.anomaly) / info.deliveries * 10000) / 100)}</Typography></div>
                </div>

              </Grid>
              <Grid>
                <div title={info.done + " COMPLETATE \n" + info.anomaly + " IN ANOMALIA \n" + (info.deliveries - info.done - info.anomaly) + " MANCANTI\n" + info.deliveries + " TOTALI"}>
                  {info.done} / {info.anomaly} / -{info.deliveries - info.done - info.anomaly} / {info.deliveries}
                </div>
              </Grid>
            </Grid>

          ) : null
        }

        } />

      <RouteSegmentMonitorShow {...{ label: "Prossima consegnaaa" }} />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <DriverCarrierField label={"Autista/Vettore"} />
      <VehicleField {...initField("vehicle", options)} />
      <GoToDeliveriesButton filterAttribute={"route"} label="Miss." />
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>

    </Datagrid >
  );
};
