import React from "react";
import {
  DateInput,
  FormDataConsumer,
  FormTab,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import { AddressInput } from "../components/AddressInput";
import { CustomToolbar } from "../components/CustomToolbar";
import { FormProps } from "../shared/types";
import { initInput } from "../shared/utils";

export const EmployeeForm = (props: FormProps) => {
  return (
    <TabbedForm
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
      {...props}
    >
      <FormTab label="Info generali">
        <TextInput {...initInput("name", props.fieldOptions, props.canEdit)} />
        <TextInput
          {...initInput("surname", props.fieldOptions, props.canEdit)}
        />
        <SelectInput
          {...initInput("gender", props.fieldOptions, props.canEdit, "array")}
          choices={props.fieldOptions.gender.choices}
        />
        <DateInput
          {...initInput("date_of_birth", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("place_of_birth", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("fiscal_code", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("identity_card", props.fieldOptions, props.canEdit)}
        />
        <ReferenceArrayInput
          {...initInput("departments", props.fieldOptions, props.canEdit)}
          reference="autocomplete/department"
        >
          <SelectArrayInput optionText="label" />
        </ReferenceArrayInput>
        <ReferenceInput
          {...initInput("site", props.fieldOptions, props.canEdit)}
          reference="autocomplete/site"
        >
          <SelectInput optionText="label" />
        </ReferenceInput>
        <SelectInput
          {...initInput("status", props.fieldOptions, props.canEdit, "array")}
          choices={props.fieldOptions.status.choices}
        />
      </FormTab>
      <FormTab label="Residenza">
        <AddressInput options={props.fieldOptions} canEdit={props.canEdit} />
      </FormTab>
      <FormTab label="Contatti">
        <TextInput
          {...initInput("identity_card", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("phone_number", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("phone_number_2", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          type="email"
          {...initInput("email", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          type="email"
          placeholder="Email personale"
          {...initInput("personal_email", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          type="email"
          {...initInput("pec", props.fieldOptions, props.canEdit)}
        />
      </FormTab>

      <FormTab label="Dispositivo">

        <TextInput
          {...initInput("device_name", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_serial", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_imei1", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_imei2", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_unlock", props.fieldOptions, props.canEdit)}
        />
        <DateInput
          {...initInput("device_date", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          type="email"
          {...initInput("device_email", props.fieldOptions, props.canEdit)}
        />

      </FormTab>
      <FormTab label="SIM">
        <TextInput
          {...initInput("work_phone_number", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_iccd", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_pin", props.fieldOptions, props.canEdit)}
        />
        <TextInput
          {...initInput("device_puk", props.fieldOptions, props.canEdit)}
        />
      </FormTab>
      <FormDataConsumer>
        {({ formData, scopedFormData, getSource, ...rest }) =>
          formData.departments && formData.departments.includes("Autista") && (
            <FormTab {...rest} label="Info autista">
              <SelectInput
                choices={props.fieldOptions.license.choices}
                {...initInput(
                  "license",
                  props.fieldOptions,
                  props.canEdit,
                  "array"
                )}
              />
              <TextInput
                {...initInput("device", props.fieldOptions, props.canEdit)}
              />
              <TextInput
                {...initInput(
                  "fuel_card_number",
                  props.fieldOptions,
                  props.canEdit
                )}
              />
            </FormTab>
          )
        }
      </FormDataConsumer>
    </TabbedForm>
  );
};
