import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  Button,
  DateInput,
  FormWithRedirect,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useNotify,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { client } from "../../dataProvider";
import { useState } from "react";


export const CreateMultiRemittanceButton = (props: any) => {
  const { ids } = props;
  const { value, setFalse, setTrue } = useToggler();
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    client("remittances/create_multi_remittance", {
      method: "POST",
      data: values,
    })
      .then(() => {
        notify("Fatto!", "success");
        window.open("/remittances/", "_blank");
        window.location.reload();
      })
      .catch((response) => {
        notify(
          response.response.data.detail,
          "error"
        );
      })
      .finally(() => setLoading(false));
  };
  const { data: options } = useOptions("remittances", "GET");
  return options ? (
    <>
      <Button
        disabled={ids.length <= 0}
        label="Crea rimessa multipla"
        onClick={() => setTrue()}
        style={{ paddingTop: 15 }}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          initialValues={{ cachet_payments: ids }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DateInput {...initInput("remittance_date", options, true)} />
                <SelectArrayInput
                  style={{ width: "100%" }}
                  {...initField("payment_type", options, "array")}
                  choices={options.payment_type.choices}
                />

                <SelectInput
                  {...initInput("type", options, true, "array")}
                  choices={options.type.choices}
                />
                <TextInput {...initInput("note", options, true)} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
