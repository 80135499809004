import { useEffect, useMemo, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { DeliveryGroup } from "../types";
import { TableButton } from "./TableButton";
import { etaIsGood, getKeyStore, timeForEta } from "../utils";
import { DndDatagrid } from "../../components/DndDatagrid";
import {
  BooleanField,
  FunctionField,
  ListContextProvider,
  NumberField,
  Record,
  RecordContextProvider,
  TextField,
  useList,
  useListContext,
  useRecordContext,
} from "react-admin";
import { DragStart, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { Typography, useTheme } from "@material-ui/core";

import { TextField as TextFieldMui } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DeliveryType, Subject } from "../../shared/types";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { ca } from "date-fns/locale";
import CommentIcon from '@material-ui/icons/Comment';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';


const useStyles = makeStyles((theme) => ({
  inputColor: {
    color: "white",
    width: "40px",
    height: "26px",
    borderRadius: "40px",
    background: "black",
    textAlign: "center",
    fontSize: "1.1em",
    borderColor: "None",
  },
}));

const ReorderInput = (props: any) => {
  const listContext = useListContext();
  const record = useRecordContext();

  const [initialOrder, setInitialOrder] = useState<number>(props.initialOrder);

  const classes = useStyles();
  useEffect(() => {
    setInitialOrder(props.initialOrder);
  }, [props.initialOrder]);

  return (
    <TextFieldMui
      InputProps={{
        disableUnderline: true,
        classes: { input: classes.inputColor },
      }}
      value={initialOrder}
      onChange={(e) => {
        setInitialOrder(parseInt(e.target.value));
      }}
      onBlur={(e) => {
        setInitialOrder(props.initialOrder);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          var iOrd = initialOrder;
          if (iOrd > listContext.ids.length) {
            iOrd = listContext.ids.length;
          }

          if (!isNaN(iOrd)) {
            props.onOrdering(listContext.ids.indexOf(record.id), iOrd - 1);
          }
        }
      }}
    />
  );
};

interface Props {
  data: DeliveryGroup[];
  onOrdering: (dragIndex: number, hoverIndex: number) => void;
  onDeleteItem: (startingPointId: string, receiverId: string) => void;
  endPoint?: Subject;
  pointsDistanceMapping?: [];
  startDate?: Date;
  endDate?: string;
  setCanSaveForEta: (canSave: string) => void;
  canSaveForEta: string;
}

const getStartingPointIndex = (
  data: DeliveryGroup[],
  delivery: DeliveryGroup
) =>
  data.findIndex((deliveryCompare) => {
    return deliveryCompare.id === getKeyStore(delivery.startingPointId);
  });

const getFirstDeliveryOfStartingPointIndex = (
  data: DeliveryGroup[],
  deliveryStartingPoint: DeliveryGroup,
  deliveryStartingPointIndex: number
) =>
  data.findIndex((deliveryCompare, index) => {
    return (
      index !== deliveryStartingPointIndex &&
      deliveryStartingPoint.id ===
      getKeyStore(deliveryCompare.startingPointId) &&
      index > deliveryStartingPointIndex
    );
  });

const isDisabledStartingPoint = (
  index: number,
  startingPointIndex: number,
  firstDeliveryIndex: number
) => {
  return index !== startingPointIndex && index >= firstDeliveryIndex;
};

const isDisabledDelivery = (
  index: number,
  deliveryIndex: number,
  startingPointIndex: number
) => {
  return index !== deliveryIndex && index <= startingPointIndex;
};

const useStylesDatagrid = makeStyles(() => ({
  rowCell: {
    whiteSpace: "normal",
  },
}));
export const TableGroupedDeliveries = (props: Props) => {
  const { onDeleteItem, data, onOrdering, endDate, pointsDistanceMapping, startDate, setCanSaveForEta, canSaveForEta } = props;
  const classesToolbar = useStylesDatagrid();
  const [tempStartDate, setTempStartDate] = useState<Date | undefined>(startDate);
  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    updateDisabledStatus();
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    onOrdering(result.source.index, result.destination.index);
  }

  useEffect(() => {
    setTempStartDate(startDate);
  }, [startDate]);

  const [dataCopy, setDataCopy] = useState(data);
  const listContext = useList({
    data: dataCopy,
    ids: dataCopy.map((item) => item.id),
    loading: false,
    loaded: true,
  });

  useEffect(() => {
    setDataCopy(data);
  }, [data]);

  useEffect(() => {
    setCanSaveForEta("")
  }, [listContext.ids, startDate, endDate]);

  const updateDisabledStatus = (compareFunction?: (index: number) => boolean) =>
    setDataCopy(
      data.map((item, index) => {
        return {
          ...item,
          isDisabled: compareFunction ? compareFunction(index) : false,
        };
      })
    );

  const onDragStart = (initial: DragStart) => {
    const dragIndex = initial.source.index;
    const delivery = { ...data[dragIndex] }; //trasportando
    if (delivery.isPickUpPoint === false) {
      const startingPointIndex = getStartingPointIndex(data, delivery);

      updateDisabledStatus((index: number) =>
        isDisabledDelivery(index, dragIndex, startingPointIndex)
      );
    } else {
      const firstDeliveryIndex = getFirstDeliveryOfStartingPointIndex(
        data,
        delivery,
        dragIndex
      );
      updateDisabledStatus((index: number) =>
        isDisabledStartingPoint(index, dragIndex, firstDeliveryIndex)
      );
    }
  };
  const theme = useTheme();

  return (
    <ListContextProvider value={listContext}>
      <DndDatagrid
        dndContextProps={{
          onDragEnd,
          onDragStart,
        }}
        datagridProps={{
          rowStyle: (record) => ({
            backgroundColor:
              record!.isDisabled && record!.isDisabled === true
                ? theme.palette.error.light
                : record!.isPickUpPoint && record!.delivery_type !== DeliveryType.WITHDRAW
                  ? theme.palette.info.light
                  : "transparent",
          }),
          classes: classesToolbar,
        }}
      >
        <FunctionField
          label=""
          render={(record: Record | undefined) =>
            record && (
              <ReorderInput
                initialOrder={listContext.ids.indexOf(record.id) + 1}
                setOldOrder={listContext.ids.indexOf(record.id) + 1}
                onOrdering={onOrdering}
              />
            )
          }
        />
        <FunctionField
          label=""
          render={(record: Record | undefined) => (
            <>
              {
                record ?
                  (
                    record.isPickUpPoint === false ? (
                      <TableButton
                        title={"Elimina"}
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteItem(record.startingPointId, record.receiverId);
                        }}
                      >
                        <DeleteIcon />
                      </TableButton>
                    ) : (
                      <TableButton
                        title={"Elimina"}
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteItem(record.receiverId, record.startingPointId);
                        }}
                      >
                        <DeleteIcon />
                      </TableButton>
                    )
                  ) :
                  null
              }
            </>
          )}
        />
        <FunctionField
          label="Destino"
          render={(record: Record | undefined) => {
            return (
              <TextField source="receiver" label="Destinatario"
                style={
                  record && record.isPickUpPoint === true &&
                    (record.delivery_type === DeliveryType.WITHDRAW) ?
                    { color: "red" } : {}
                }
              />
            );
          }}
        />

        <DeliveryTypeField source="delivery_type" label={"Tipo"} />


        <NumberField source="weight" label="Peso" />
        <FunctionField
          label=""
          render={(record: Record | undefined) => {
            if (!record)
              return null
            return (
              (
                (
                  (
                    record.isPickUpPoint === true && record.delivery_type === DeliveryType.WITHDRAW
                  )
                  ||
                  (
                    record.isPickUpPoint === false && record.delivery_type !== DeliveryType.WITHDRAW
                  )
                )
                &&
                (record.packages_number >= 21 || record.weight >= 150)
              ) ?
                <Typography style={{ color: "red" }}><PriorityHighIcon /></Typography> :
                null
            )
          }}
        />
        <NumberField source="packages_number" label="Colli" />
        <FunctionField
          label="Indirizzo"
          render={(record: Record | undefined) => {
            return (
              <TextField source="address" label="Indirizzo"
                style={
                  record && record.isPickUpPoint === true &&
                    (record.delivery_type === DeliveryType.WITHDRAW) ?
                    { color: "red" } : {}
                }
              />
            );
          }}
        />

        <FunctionField
          label=""
          render={(record: Record | undefined) => {
            if (!record)
              return null
            return (record.notes ?
              <Typography style={{ color: "red" }} title={record.notes}
                onClick={(e) => {
                  e.stopPropagation();
                  window.alert("Nota operativa " + record.address + ": \n" + record.notes)
                }}
              ><CommentIcon /></Typography> :
              null
            )
          }}
        />

        <FunctionField
          label="Eta"
          render={(record: Record | undefined) => {
            if (!record || !pointsDistanceMapping || !tempStartDate)
              return null
            let best_i = 0
            let score = 10000000;
            for (let p = record.id.toString().includes("endstore") ? 2 : 0; p < pointsDistanceMapping.length; p++) {
              let point = pointsDistanceMapping[p] as { point: string, duration: number }
              let d = Math.abs(parseFloat(point['point'].split(",")[0]) - record.longitude) + Math.abs(parseFloat(point['point'].split(",")[1]) - record.latitude)
              if (d < score) {
                score = d
                best_i = p
              }
            }
            if (pointsDistanceMapping && pointsDistanceMapping[best_i]) {
              let duration = Math.floor(pointsDistanceMapping[best_i]["duration"]) + 180 * best_i;
              let isOpen = etaIsGood(tempStartDate, duration, record.subjectopening_set)
              if (isOpen === false) {
                setCanSaveForEta(record.id as string)
              }
              else {
                if (canSaveForEta === (record.id as string))
                  setCanSaveForEta("")
              }
              return timeForEta(tempStartDate, duration, isOpen)
            }
            else
              return <Typography>"--"</Typography>

          }}
        />

        {/*
        <FunctionField
          label="Destino"
          render={(record: Record | undefined) => {
            return (
              <TextField source="main_id" label="ID-M" />
            );
          }}
        />
        */}

      </DndDatagrid>
    </ListContextProvider>
  );
};
