// botton crea riconsegna
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  useNotify,
  AutocompleteInput,
  useUpdateMany,
  useListContext,
  required
} from "react-admin";
import { useToggler } from "../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";

export const AssociatMainSubjectButton = () => {
  const { value, setTrue, setFalse } = useToggler();
  const notify = useNotify();

  const [updateMany, { loading }] = useUpdateMany();
  const { selectedIds, refetch } = useListContext();
  const onClick = (values: any) => {
    updateMany("subjects", selectedIds, values, {
      onFailure: () => notify("Errore!", "error"),
      onSuccess: (response: any) => {
        let errors = "";
        for (let k in response.errors) {
          errors += k + " -> " + response.errors[k] + "\n "
        }
        if (errors !== "") {
          window.alert(errors);
        }
        else {
          notify("Missioni aggiornate");
        }
        setFalse();
        refetch();
      },
    });
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTrue();
        }}
        label={"Associa soggetto principale"}
      ></Button>
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  fullWidth
                  label="Soggetto principale"
                  source="main_subject_id"
                  reference="autocomplete/subject"
                  validate={[required()]}
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
