import {
  Card,
  createStyles,
  TableContainer,
  makeStyles,
  Theme,
  Box,
  Button,
  Grid,
  Table,
  TableRow,
  Typography,
  TableCell,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { Rnd } from "react-rnd";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ListContextProvider,
  Record,
  Tab,
  TextField,
  useList,
} from "react-admin";
import { Link } from "react-router-dom";

import { SubjectField } from "../../components/SubjectField";
import { Segment } from "../types";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { TableButton } from "./TableButton";
import { Delivery } from "../../shared/types";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useStore } from "../store";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { now } from "lodash";

function weekDayConvertion(dayString: string): string {
  const mapping: { [key: string]: string } = {
    "MO": "Lunedì",      // Monday -> Lunedì
    "TU": "Martedì",     // Tuesday -> Martedì
    "WE": "Mercoledì",    // Wednesday -> Mercoledì
    "TH": "Giovedì",     // Thursday -> Giovedì
    "FR": "Venerdì",     // Friday -> Venerdì
    "SA": "Sabato",      // Saturday -> Sabato
    "SU": "Domenica"     // Sunday -> Domenica
  };

  // Restituisce il giorno in italiano o un messaggio di errore
  const resp = mapping[dayString.toUpperCase()];

  return resp || "--";
}

interface Props {
  onAddClick: (segment: Segment) => void;
  onSelectDelivery: (delivery: Delivery) => void;
}
const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      zIndex: 10000,
    },
  })
);
const useStylesTable = makeStyles((theme) => ({
  root: { maxHeight: "400px", overflow: "auto" },
}));
const useStylesDatagrid = makeStyles(() => ({
  rowCell: {
    whiteSpace: "normal",
  },
}));

const useMarkerSubjectTableStyles = makeStyles({
  root: {
    padding: '5px',
    width: '100%',
    "& .MuiTableCell-root": {
      padding: '1px',
    }
  },

});

export const MarkerPopup = React.forwardRef<Rnd, Props>((props, ref) => {
  const classes = useStyles(props);
  const subjectTableStyle = useMarkerSubjectTableStyles()
  const classesTable = useStylesTable(props);
  const classesDatagrid = useStylesDatagrid();
  const {
    subjectByCoordsMap,
    clusters,
    activeClusterKey,
    addedSegments,
    selectedRoute,
  } = useStore();
  const segments = useMemo(
    () => (activeClusterKey ? clusters[activeClusterKey] : []),
    [activeClusterKey, clusters]
  );
  const listContext = useList({
    data: segments,
    ids: segments.map((segment) => segment.id),
    loading: false,
    loaded: true,
  });

  return (
    <Rnd
      ref={ref}
      className={classes.root}
      enableResizing={true}
      maxWidth={"1450px"}
      minWidth={"650px"}
      width={"850px"}
      default={{ x: 0, y: 0, width: "auto", height: "auto" }}
    >
      {activeClusterKey && segments.length > 0 && (
        <Card>
          <Box padding={2}>
            <Grid container>
              <Grid item xs={6} style={{ padding: "5px" }}>
                <Table classes={subjectTableStyle}>
                  <TableRow>
                    <TableCell >
                      <Typography>Destinatario</Typography>
                    </TableCell>
                    <TableCell style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word"
                    }}>
                      <Typography><Link
                        to={`/subjects/${subjectByCoordsMap[activeClusterKey].id}`}
                        target="_blank">{`${subjectByCoordsMap[activeClusterKey].id}`}
                      </Link>{
                          `:${subjectByCoordsMap[activeClusterKey].business_name}`
                        }</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Indirizzo</Typography>
                    </TableCell>
                    <TableCell style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word"
                    }}>
                      <Typography>{`${subjectByCoordsMap[activeClusterKey].address}, ${subjectByCoordsMap[activeClusterKey].locality} ${subjectByCoordsMap[activeClusterKey].province}`}</Typography>
                    </TableCell>
                  </TableRow>

                </Table>
              </Grid>
              <Grid item container xs={6}>
                {
                  subjectByCoordsMap &&
                    subjectByCoordsMap[activeClusterKey].subjectopening_set ? (
                    subjectByCoordsMap![activeClusterKey].subjectopening_set!.map(
                      (tr: any) => {
                        return (
                          <Grid item container xs={6} style={{
                            padding: "1px", paddingLeft: "5px",
                            border: "1px solid #000"
                          }}>
                            {weekDayConvertion(tr.day)} ({tr.hour_from} - {tr.hour_to})
                          </Grid>
                        )
                      })
                  ) : null
                }
              </Grid>
            </Grid>
          </Box>
          <TableContainer classes={classesTable}>
            <ListContextProvider value={listContext}>
              <Datagrid hover={false} classes={classesDatagrid}>
                <FunctionField
                  label=""
                  render={(tableRecord: Record | undefined) => {
                    if (
                      tableRecord &&
                      (!tableRecord.route_id ||
                        (selectedRoute &&
                          tableRecord.route_id === selectedRoute.id))
                    ) {
                      const added = addedSegments.find(
                        (segment) => segment.id === tableRecord?.id
                      );

                      return (
                        <TableButton
                          title={added ? "Elimina" : "Aggiungi"}
                          onClick={() => {
                            props.onAddClick(tableRecord as Segment);
                          }}
                        >
                          {added ? <DeleteIcon /> : <AddIcon />}
                        </TableButton>
                      );
                    }
                    return null;
                  }}
                />
                <FunctionField
                  label=""
                  render={(tableRecord: Record | undefined) => {
                    const record = tableRecord ? tableRecord.delivery : null;
                    if (record) {
                      return (
                        <TableButton
                          title={"Seleziona"}
                          onClick={() => {
                            props.onSelectDelivery(record);
                          }}
                        >
                          <PlaylistAddIcon />
                        </TableButton>
                      );
                    }
                    return null;
                  }}
                />
                <FunctionField
                  label="Bolle"
                  source="note_set"
                  render={(tableRecord: Record | undefined) => {
                    if (tableRecord && tableRecord.note_set) {
                      return tableRecord.note_set.map((note: any) => (
                        <DownloadNoteFileButton
                          key={note.id}
                          title={note.file}
                          noteId={note.id}
                          final={note.final}
                        />
                      ));
                    }
                    return null;
                  }}
                />
                <DeliveryTypeField source="delivery_type" label={"Tipo"} />
                <TextField source="delivery.document_number" label="N. DDT" />
                <FunctionField
                  source="delivery"
                  label="ID"
                  render={(record: any) => (
                    <Button
                      color="primary"
                      component={Link}
                      to={"/deliveries/" + record.delivery.id}
                      target="_blank"
                    >
                      <>
                        {record
                          ? `${record.delivery.id}/${record.order_on_delivery}`
                          : null}
                      </>
                    </Button>
                  )}
                />
                <FunctionField
                  source="delivery"
                  label="Cliente"
                  render={(record: Record | undefined) => (
                    <>
                      {record
                        ? `${record.delivery.client.id}: ${record.delivery.client.business_name}`
                        : null}
                    </>
                  )}
                />
                <SubjectField
                  source="starting_point"
                  label="Punto di partenza"
                />

                <FunctionField
                  source="delivery"
                  label="Colli"
                  render={(record: Record | undefined) => (
                    <>{record ? record.delivery.packages_number : null}</>
                  )}
                />

                <FunctionField
                  source="delivery"
                  label="Peso"
                  render={(record: Record | undefined) => (
                    <>{record ? record.delivery.weight : null}</>
                  )}
                />

                <FunctionField
                  source="delivery"
                  label="Parziale"
                  render={(record: Record | undefined) => (
                    <>{record && record.delivery.partial ? (<>
                      <CheckBox /></>) : (<CheckBoxOutlineBlank />)}</>
                  )}
                />

                <FunctionField
                  source="delivery"
                  label="Giro"
                  render={(record: Record | undefined) => (
                    <>{record ? record.route_id : null}</>
                  )}
                />
                <FunctionField
                  source="delivery"
                  label="Note operative"
                  render={(record: Record | undefined) => (
                    <>{record ? record.operative_notes : null}</>
                  )}
                />

              </Datagrid>
            </ListContextProvider>
          </TableContainer>
        </Card>
      )}
    </Rnd>
  );
});
