import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloseIcon from '@material-ui/icons/Close';
import TocIcon from '@material-ui/icons/Toc';
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  Identifier,
  NumberField,
  Record,
  SelectField,
  SingleFieldList,
  TextField
} from "react-admin";
import { getUser } from "../../authProvider";
import { DownloadFileButton } from "../../components/DownloadFileButton";
import { GoToCachetsButton } from "../../components/GoToCachetsButton";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { IdentifierField } from "../../components/IdentifierField";
import { VehicleField } from "../../components/VehicleField";
import { url } from "../../constants";
import { clientNoJson } from "../../dataProvider";
import { DatagridPropsWithOptions } from "../../shared/types";
import { getFileNameFromContentDisposition, initField } from "../../shared/utils";
import { DriverCarrierField } from "./DriverCarrierField";
import { DriverSummaryPdfButton } from "./DriverSummaryPdfButton";
import { Print2XDocumentsButton } from "./Print2XDocuments";
import { PrintDocumentsButton } from "./PrintDocuments";
import { PrintRouteRemittanceButton } from "./PrintRouteRemittanceButton";
import { SendTrackMeButtonsButtonSingle } from "./SendTrackMeButtonSingle";
import { UndoSplitAndDeleteButton } from "./UndoSplitAndDelete";
import Close from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { tr } from 'date-fns/locale';

let green = "rgb(76, 175, 80)";
let yellow = "rgb(255, 152, 0)";
let red = "#f44336";

export const downloadNote = async (routeId: Identifier, resource?: string) => {
  if (!resource) {
    resource = "notes"
  }
  let res = null;
  try {
    res = await clientNoJson(`${url}/${`${resource}/${routeId}/download/`}`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const saveNoteToFile = async (routeId: Identifier) => {
  const res = await downloadNote(routeId, "routes");
  if (res) {
    const data = res.data;
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  }
};

export const RouteDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <FunctionField
        {...initField("file_info", options)}
        label={"File"}
        render={(tableRecord: any) => {
          if (tableRecord.file_info.name) {
            return (
              <Button onClick={() => {
                saveNoteToFile(tableRecord.id)
              }} >
                <AssignmentTurnedInIcon style={{ color: green[500] }} />
              </Button>);

          }
          return (<CloseIcon style={{ color: red[500] }} />);
        }}
      />
      <FunctionField
        sortable={false}
        source="deliveries_not_done"
        label={"!!!"}
        render={(tableRecord: Record | undefined) => {
          if (!tableRecord) {
            return null;
          }
          let color = green;
          let n = tableRecord.deliveries_not_done;

          if (tableRecord.deliveries_not_done == -1) {
            n = "X"
            color = green;
          }
          else if (tableRecord.deliveries_not_done > 0) {
            color = red;
          }

          return (
            <div style={{
              borderRadius: "50%",
              width: "15px",
              height: "15px",
              padding: "3px",
              background: color,
              //border: "1px solid #000",
              color: "#000",
              textAlign: "center",
              font: "14px Arial, sans-serif",
              cursor: "pointer"
            }}
              title={n !== "X" ? `Numero di missioni non completate ${n.toString()}` : "Non disponibile per missioni con stato diverso da partito"}
            >
              <b>{n}</b>
            </div>
          );
          return null;
        }}
      />
      <DateField showTime {...initField("printed_time", options)} />
      <TextField {...initField("device", options)} />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <BooleanField {...initField("sent", options)} />

      <FunctionField
        sortable={true}
        source="show_on_maps"
        label={"MsM"}
        title="Mostra su mappa"

        render={(tableRecord: Record | undefined) => {
          if (!tableRecord) {
            return null;
          }
          return (<a href={`/planner?displayedFilters=%7B%7D&filter=%7B%22to_be_tractioned%22%3Afalse%2C%22route_id%22%3A%5B${tableRecord.id}%5D%7D&order=ASC&page=1&perPage=10&sort=id`} target='_blank' >
            {
              tableRecord!.show_on_maps ? <CheckIcon /> : <Close />
            }
          </a>)
        }}
      />

      <DriverCarrierField label={"Autista/Vettore"} />
      <VehicleField {...initField("vehicle", options)} />
      <GoToDeliveriesButton filterAttribute={"route"} label="Miss." />
      <GoToCachetsButton filterAttribute={"route"} label="Contr." />
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      {/*
      <ArrayField source="zones" label="Zone">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" record={{ id: record }} source="id" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      */}
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <Print2XDocumentsButton mission_max_page={record.mission_max_page} />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DownloadFileButton
              title="Borderau pdf"
              urlDownload={`routes/${record.id}/get_pdf_borderau/`}
            />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DownloadFileButton
              title="Borderau excel"
              urlDownload={`routes/${record.id}/get_excel_borderau/`}
              icon={<TocIcon />}
            />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <PrintDocumentsButton mission_max_page={record.mission_max_page} />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DriverSummaryPdfButton props={record} />
          ) : null
        }
      />

      {getUser().permissions.includes("route_management.delete_route") && <UndoSplitAndDeleteButton />}
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <SendTrackMeButtonsButtonSingle {...{ id: `${record.id}` }} />
          ) : null
        }
      />
      {getUser().permissions.includes("cash_on_delivery.display_cachet_cashed") && <PrintRouteRemittanceButton />}

    </Datagrid>
  );
};
