import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  Input,
  makeStyles,
  Paper,
  Slider,
  Snackbar,
  Switch,
  Typography,
} from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Record,
  useNotify,
  useCreate,
  Identifier,
  useUpdate,
  useRefresh,
  useLoading,
  fetchEnd,
  fetchStart,
  useTranslate,
  useGetOne,
  useGetList,
  useListContext,
  ListProps,
  useDataProvider,
  Button,
} from "react-admin";
import {
  MapContainer,
  Marker,
  TileLayer,
  GeoJSON,
  CircleMarker,
} from "react-leaflet";
import { useDispatch } from "react-redux";
import { Sidebar } from "../components/Sidebar";
import {
  Route,
  Segment,
  EditSegmentDeliveryPayload,
  CreateRoutePayload,
  SegmentPayload,
} from "../types";
import {
  getAddedSegments,
  getKeyLatLon,
  getPointsGroupedDeliveries,
  getSegmentsGrouped,
  keyToLatLon,
  sortDeliveryGroupForSequence,
} from "../utils";
import { MarkerPopup } from "../components/MarkerPopup";
import { Rnd } from "react-rnd";
import { Routing } from "../components/Routing";
import L from "leaflet";
import { DriverDialog } from "../components/DriverDialog";
import { VehicleDialog } from "../components/VehicleDialog";
import React from "react";
import { RouteDialog } from "../components/RouteDialog";
import { NewSegmentDialog } from "../components/NewSegmentDialog";
import { useDialoglUpdate } from "../../shared/hooks/useDialogUpdate";
import { client } from "../../dataProvider";
import { endIcon, startZoomIcon, startIcon } from "../components/Icons";
import { MapLegend } from "../components/MapControls/MapLegend";
import { useStore } from "../store";
import { Subject } from "../../shared/types";

import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";
import "leaflet-area-select";
import randomColor from "randomcolor";
import { CarrierDialog } from "../components/CarrierDialog";
import { SplitSegmentsDialog } from "../components/SplitSegmentsDialog";
import { MarkerPlanner } from "../components/MarkerPlanner";
import { useSize } from "../../shared/hooks/useSize";
import { string } from "prop-types";
import { generateError } from "../../shared/utils";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      overflow: "auto",
      position: "fixed",
    },
    main: {
      width: "100%",
      height: "100%",
    },
    tableContainer: { flexGrow: 1 },
  })
);

export const RoutePlannerMap = (
  props: ListProps & {
    onRoutePointsFound: (totalDistance: number, totalDuration: number, pointsDurationMapping: any[]) => void;
    points: L.LatLng[];
    mainStats: {
      leftWeight: number;
      maxWeight: number;
      currentWeight: number;
      showWeightAlert: boolean;
      totalPackages: number;
      maxPackages: number;
      currentPackages: number;
    };
    totKm: number;
    pointsDurationMapping: any;
    endDate?: string;
  }
) => {
  const { onRoutePointsFound, points, mainStats, totKm, pointsDurationMapping } = props;
  const dispatch = useDispatch();
  const { data: sitesMap, ids: idsList } = useGetList("sites");
  const size = useSize(document.getElementById("layout-main-content"));
  const [mapHeight, setMapHeight] = useState(size?.height);
  const [mapWidth, setMapWidth] = useState(size?.width);
  const sites = useMemo(
    () => idsList.map((id) => sitesMap[id]),
    [idsList, sitesMap]
  );
  const {
    addedSegments,
    editMode,
    carrier,
    driver,
    vehicle,
    clusters,
    groupedDeliveries,
    routes,
    selectedRoute,
    colors,
    tags,
    finalPointId,
    startPointId,
    startDate,
    warehouseAlert,
    updateTags,
    updateStartPointId,
    initPlanner,
    toggleEditMode,
    setActiveClusterKey,
    resetActiveClusterKey,
    addDeliveries,
    deleteDeliveries,
    toggleSegment,
    resetCarrierDriverVehicle,
    updateSelectedCarrier,
    updateSelectedDriver,
    updateSelectedVehicle,
    toggleSelectedRoutes,
    onActiveRouteChange,
    groupDeliveries,
    setSelectedRoutes,
    addMultipleKeys,
  } = useStore();
  const notify = useNotify();
  const refMap = useRef<L.Map & any>();

  const [showZones, setShowZones] = useState(false);
  const [selectedZoneIds, setSelectedZoneIds] = useState([]);
  const [openDriverDialog, setOpenDriverDialog] = useState(false);
  const [openCarrierDialog, setOpenCarrierDialog] = useState(false);
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
  const [openRoutesDialog, setOpenRoutesDialog] = useState(false);
  const [handlerCarrier, setHandlerCarrier] = useState(false);
  const [openSplitSegmentsDialog, setOpenSplitSegmentsDialog] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [highlightClusterKeys, setHightlightClusterKey] = useState<string[]>(
    []
  );
  const onHandlerChange = (hC: boolean) => {
    hC ? setHandlerCarrier(false) : setHandlerCarrier(true);
    resetCarrierDriverVehicle();
  };

  const routesList = useGetList(
    "route_maps", { page: 1, perPage: 150 },
    { field: "id", order: "DESC" }, { "maps": true }
  );
  const { data: dataZones, ids: idsZones } = useGetList(
    "delivery-zones", { page: 1, perPage: 1000 }, { field: "name", order: "ASC" },
    { zones: selectedZoneIds }
  );

  const { data: finalPointSubject } = useGetOne("subjects", finalPointId!, {
    enabled: !!finalPointId,
  });
  const { data: startPointSubject } = useGetOne("subjects", startPointId!, {
    enabled: !!startPointId,
  });
  const loading = useLoading();
  const translate = useTranslate();
  const {
    activeItem: activeDelivery,
    setActiveItem: setActiveDelivery,
    isOpenUpdateModal,
    closeModalUpdate,
  } = useDialoglUpdate();

  const refetch = useRefresh();
  const { data, ids, setFilters, displayedFilters, filterValues } =
    useListContext();

  const updateFilterOnRoutesSelection = (routes: Route[]) => {
    setFilters(
      {
        ...filterValues,
        route_id: routes.map((route) => route.id),
      },
      displayedFilters
    );
  };

  useEffect(() => {
    setSelectedZoneIds(filterValues.zones ? filterValues.zones : [])
  },
    [filterValues]);

  const onToggleRoute = (route: Record) => {
    toggleSelectedRoutes(route, updateFilterOnRoutesSelection);
  };
  const segments = useMemo(
    () => ids.map((id) => data[id]) as Segment[],
    [data, ids]
  );
  const dataProvider = useDataProvider();
  const [createRoute] = useCreate("routes", undefined, {
    onFailure: (e: any) => {
      console.log(e.response);
      if ("detail" in e.response.data) {
        notify(e.response.data.detail.toString(), { type: "error" });
      }
      else if ("planned_date" in e.response.data) {
        notify("Attenzione verificare data partenza mezzo", { type: "error" });
      }
      else if (e.response.data[0])
        notify(e.response.data[0], { type: "error" });
      else if (e.response.data.parent_delivery) {
        notify(e.response.data.parent_delivery.join(", "), { type: "error" });
      }
      else if (e.response.data.delivery) {
        notify(e.response.data.delivery.join(", "), { type: "error" });
      }
      else
        notify("Errore", { type: "error" });
      //notify("Errore creazione giro", "error");
    },
    onSuccess: () => {
      notify("Fatto", "info");
      refetch();
      onActiveRouteChange(editMode, selectedRoute);
      resetCarrierDriverVehicle();
    },
  });

  const simulateRouteAssign = (data: any) => {

    client("routes/simulate_carrier_assignment", { method: "POST", data })
      .then((data) => {
        if (data) {
          notify(`Totale competenze vettore: ${data.tot} €`, "info");
        }
      })
      .catch((e: any) => {
        if (e.response.data[0])
          notify(e.response.data[0], { type: "error" });
        else
          notify("Errore", { type: "error" });
      });

  }

  useEffect(() => {
    if (editMode === false && filterValues.route_id && filterValues.route_id.length > 0) {
      dataProvider
        .getMany("routes", { ids: filterValues.route_id })
        .then(({ data }) => {
          setSelectedRoutes(data);
          toggleEditMode();
        })
        .catch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterValues.store) {
      updateStartPointId(filterValues.store);
    }
  }, [filterValues.store, updateStartPointId]);
  const [updateRoute] = useUpdate("routes", undefined);

  const classes = useStyles();
  const popupRef = useRef<Rnd>(null);
  useClosedSidebar();
  useEffect(() => {
    initPlanner(segments);
  }, [initPlanner, segments]);

  useEffect(() => {
    onActiveRouteChange(editMode, selectedRoute);
  }, [onActiveRouteChange, editMode, selectedRoute, routes]);

  const openPopup = (key: string, x: number, y: number) => {
    if (popupRef.current) popupRef.current.updatePosition({ x: x, y: y });
    setActiveClusterKey(key);
  };

  const openWeigthAlert = () => {
    alert("Attenzione hai superato il peso massimo trasportabile dal mezzo");
  };
  const openPackageAlert = () => {
    alert("Attenzione hai superato i colli massimi trasportabili dal mezzo");
  };

  const openWeigthPackageAlert = () => {
    alert(
      "Attenzione hai superato peso e colli massimi trasportabili dal mezzo"
    );
  };

  const weigthPackageAlertOnCluster = (keyArrivalPoint: string) => {
    var newLeftWeight = mainStats.leftWeight;
    var newCurrentPackage = mainStats.currentPackages;
    for (const segment of clusters[keyArrivalPoint]) {
      if (
        !addedSegments.some((e) => e.id === segment.id) &&
        !segment.route_id
      ) {
        newLeftWeight -= segment.delivery.weight ? segment.delivery.weight : 0;
        newCurrentPackage += segment.delivery.packages_number
          ? segment.delivery.packages_number
          : 0;
      }
    }
    if (
      newLeftWeight < 0 &&
      mainStats.maxPackages !== 0 &&
      mainStats.maxWeight !== 0 &&
      newCurrentPackage > mainStats.maxPackages &&
      !mainStats.showWeightAlert
    ) {
      openWeigthPackageAlert();
    } else {
      if (
        newCurrentPackage > mainStats.maxPackages &&
        mainStats.maxPackages !== 0 &&
        !mainStats.showWeightAlert
      ) {
        openPackageAlert();
      }
      if (newLeftWeight < 0 &&
        mainStats.maxWeight !== 0 &&
        !mainStats.showWeightAlert
      ) {
        openWeigthAlert();
      }
    }
  };

  const toggleSegmentWeigthPackageAlert = (segment: Segment) => {
    var newLeftWeight = mainStats.leftWeight;
    if (!addedSegments.some((e) => e.id === segment.id) && !segment.route_id) {
      newLeftWeight -= segment.delivery.weight ? segment.delivery.weight : 0;
    }

    var newCurrentPackage = mainStats.currentPackages;
    if (!addedSegments.some((e) => e.id === segment.id) && !segment.route_id) {
      newCurrentPackage += segment.delivery.packages_number
        ? segment.delivery.packages_number
        : 0;
    }

    if (
      newLeftWeight < 0 &&
      mainStats.maxPackages !== 0 &&
      mainStats.maxWeight !== 0 &&
      newCurrentPackage > mainStats.maxPackages &&
      !mainStats.showWeightAlert
    ) {
      openWeigthPackageAlert();
    } else {
      if (
        newCurrentPackage > mainStats.maxPackages &&
        mainStats.maxPackages !== 0 &&
        !mainStats.showWeightAlert
      ) {
        openPackageAlert();
      }

      if (newLeftWeight < 0 &&
        mainStats.maxWeight !== 0 &&
        !mainStats.showWeightAlert) {
        openWeigthAlert();
      }
    }

    toggleSegment(segment);
  };

  const invertRouteOrder = () => {
    let tempAddetSegments = [
      ...addedSegments.map((segment) => ({
        ...segment,
        sequence: 0,
        sequence_starting_point: 0,
      })),
    ];
    tempAddetSegments.reverse();
    groupDeliveries(tempAddetSegments);

  };

  const saveRoute = () => {
    if (
      ((vehicle && driver) || carrier) &&
      finalPointSubject &&
      startPointSubject &&
      tags.length > 0
    ) {
      const segments = getAddedSegments(
        groupedDeliveries,
        addedSegments
      ) as SegmentPayload[];
      const data: CreateRoutePayload = {
        segments,
        carrier: carrier ? carrier.id : undefined,
        driver: driver ? driver.id : undefined,
        vehicle: vehicle ? vehicle.id : undefined,
        total_distance: totKm,
        final_point: finalPointSubject.id as number,
        start_point: startPointSubject.id as number,
        planned_date: startDate,
        tags: tags
      };
      if (!editMode && segments.length > 0) {
        createRoute({ payload: { data } });
      } else if (editMode && selectedRoute) {
        updateRoute(
          {
            resource: "routes",
            payload: { id: selectedRoute.id, data },
          },
          {
            onFailure: (response) => {
              generateError(response, notify);
            },
            onSuccess: () => {
              notify("Fatto", "info");
              refetch();
            },
          }
        );
      }
    }
  };

  const simulateRouteAssignment = () => {
    if (
      (carrier) &&
      finalPointSubject &&
      startPointSubject
    ) {
      const segments = getAddedSegments(
        groupedDeliveries,
        addedSegments
      ) as SegmentPayload[];
      const data: CreateRoutePayload = {
        segments,
        carrier: carrier ? carrier.id : undefined,
        driver: undefined,
        vehicle: undefined,
        total_distance: totKm,
        final_point: finalPointSubject.id as number,
        start_point: startPointSubject.id as number,
        planned_date: startDate,
        tags: tags
      };
      if (segments.length > 0) {
        simulateRouteAssign(data);
      }
    }
  };

  const editSegments = (data: EditSegmentDeliveryPayload) => {
    dispatch(fetchStart());
    if (data && data.segments) {
      for (let i in data.segments) {
        data.segments[i].arrival_point_id = data.segments[i]?.arrival_point?.id
        data.segments[i].starting_point_id = data.segments[i]?.starting_point?.id
      }
    }

    client("segments/edit_delivery_segments", { method: "POST", data })
      .then(() => {
        closeModalUpdate();
        refetch();
        notify("ra.notification.updated", "info", { smart_count: 1 });
      })
      .catch((error) => {
        const data = error.response.data;
        let errorMessage = "";
        Object.keys(data).forEach(
          (key) => (errorMessage += data[key].join(". "))
        );
        notify(errorMessage, "warning");
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };
  const renderExtremeMarker = (point: Subject, icon: L.Icon) => {
    if (
      !(getKeyLatLon(point.latitude, point.longitude) in clusters) &&
      !groupedDeliveries.some((deliveryGroup) => {
        const clusterKey = getKeyLatLon(
          deliveryGroup.latitude,
          deliveryGroup.longitude
        );
        return getKeyLatLon(point.latitude, point.longitude) === clusterKey;
      })
    ) {
      return (
        <Marker icon={icon} position={[point.latitude, point.longitude]} />
      );
    }
  };

  const autoOrder = () => {
    const data = {
      finalPoint: finalPointSubject,
      startPoint: startPointSubject,
      segments: addedSegments,
    };
    dispatch(fetchStart());
    client("routes/order_segment_route", { method: "POST", data })
      .then((data) => {
        console.log(addedSegments)
        console.log(data)
        if (data) {
          const segments = addedSegments.map((old) => {
            const newSegment = data.find(
              (segment: Segment) => old.id === segment.id
            );
            if (newSegment)
              return {
                ...old,
                sequence: newSegment.sequence,
                sequence_starting_point: newSegment.sequence_starting_point,
              };
            return old;
          });
          groupDeliveries(segments);
        }
      })
      .catch(() => notify("Nessuna combinazione trovata!", "warning"))
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  useEffect(() => {
    function onselect(e: any) {
      const keys = Object.keys(clusters).filter((keyArrivalPoint) =>
        e.bounds.contains(keyToLatLon(keyArrivalPoint))
      );
      addMultipleKeys(keys);
    }
    if (refMap && refMap.current) {
      const map = refMap.current;
      map.on("click", resetActiveClusterKey);
      map.selectArea.enable();
      map.on("areaselected", onselect);
      return () => {
        map.off("click", resetActiveClusterKey);
        map.off("areaselected", onselect);
      };
    }
  }, [addDeliveries, addMultipleKeys, clusters, resetActiveClusterKey]);

  useEffect(() => {
    let factor = 1;

    if (window.devicePixelRatio <= 1.7) {
      factor = 2
    }
    else {
      factor = 1.3
    }

    if (size) {
      setMapHeight(size?.height * factor);
      setMapWidth(size?.width);
    }

  }, [window.devicePixelRatio])

  const [blueCircles, setBlueCircles] = useState<any[]>([]);
  const [showAllRoute, setShowAllRoute] = useState(false);

  return (
    <Grid container className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ top: "54px" }}
        open={loading}
        message={translate("ra.message.loading")}
        action={
          <React.Fragment>
            <CircularProgress />
          </React.Fragment>
        }
      />
      <Box position="absolute" top="0" left="0" zIndex="1000" maxWidth="450px">
        <Paper square variant="outlined" elevation={2}>
          <Grid container direction="column">
            <Box
              paddingX={1}
              paddingY={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Zone</Typography>
              <Switch
                checked={showZones}
                onChange={() => setShowZones((old) => !old)}
                name="mode"
              />
            </Box>
          </Grid>
        </Paper>
      </Box>
      <Sidebar
        updateFilterOnRoutesSelection={updateFilterOnRoutesSelection}
        startPointSubject={startPointSubject}
        finalPointSubject={finalPointSubject}
        simulateRouteAssignment={simulateRouteAssignment}
        saveRoute={saveRoute}
        autoOrder={autoOrder}
        invertRouteOrder={invertRouteOrder}
        routesData={routesList.data}
        setOpenDriverDialog={setOpenDriverDialog}
        setOpenCarrierDialog={setOpenCarrierDialog}
        setOpenVehicleDialog={setOpenVehicleDialog}
        setOpenRoutesDialog={setOpenRoutesDialog}
        setOpenSplitSegmentsDialog={setOpenSplitSegmentsDialog}
        handlerCarrier={handlerCarrier}
        onHandlerChange={onHandlerChange}
        pointsDurationMapping={pointsDurationMapping}
        endDate={props.endDate}
        showAllRoute={showAllRoute}
        setShowAllRoute={setShowAllRoute}
      />
      <Box position="absolute"
        bottom="150px" left="0" zIndex="1000" width="165px"
        padding="0">
        <Paper square variant="outlined" elevation={2}>
          <Grid container direction="column">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Slider
                    value={rotation}
                    onChange={(_, value) => {
                      setRotation(value as number)
                    }}
                    min={0}
                    max={360}
                    step={1}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}°`}
                  />
                </Grid>
                <Grid xs={12}>
                  <Input title="Cerca missione/DDT" placeholder="Cerca missione/DDT" onChange={(e) => {
                    let s_temp = segments.filter((segment) => (segment.delivery.document_number === e.target.value || segment.delivery.id.toString() === e.target.value))
                    for (let blueCircle of blueCircles) {
                      refMap.current.removeLayer(blueCircle)
                    }
                    setBlueCircles([])
                    for (let i in s_temp) {
                      let newBlueCricle = L.circleMarker([s_temp[i].arrival_point.latitude, s_temp[i].arrival_point.longitude], { radius: 15, color: 'blue' })
                      refMap.current.addLayer(
                        newBlueCricle
                      )
                      let newBlueCricle2 = L.circleMarker([s_temp[i].starting_point.latitude, s_temp[i].starting_point.longitude], { radius: 15, color: 'blue' })
                      refMap.current.addLayer(
                        newBlueCricle2
                      )
                      setBlueCircles([...blueCircles, newBlueCricle, newBlueCricle2])
                    }
                  }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Box>
      <Box height={mapHeight} width={"100%"} style={{ transform: `rotate(${rotation}deg)` }}>
        <MapContainer
          whenCreated={(map: L.Map) => {
            refMap.current = map;
            map.on("click", () => {
              setHightlightClusterKey([])
            })
          }}
          center={[40.482687, 17.834368]}
          zoom={8}
          className={classes.main}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            //url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
          //url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
          />
          {sites.map((site) => {
            const keyLatLon = getKeyLatLon(
              site.default_store.latitude,
              site.default_store.longitude
            );
            return keyLatLon in clusters ||
              site.default_store.id === startPointId ||
              site.default_store.id === finalPointId ? (
              <CircleMarker
                key={site.id}
                color="red"
                center={[
                  site.default_store.latitude,
                  site.default_store.longitude,
                ]}
              />
            ) : null;
          })}
          {showZones &&
            dataZones &&
            idsZones &&
            idsZones.map((id) => (
              <GeoJSON
                key={id}
                data={dataZones[id] as any}
                onEachFeature={(feature, layer: any) => {
                  layer.bindTooltip(`<b>${feature.properties.name}</b>`);
                  layer.options.color = feature.properties.color; //randomColor();
                }}
              />
            ))}
          <>
            {Object.keys(clusters).map((thisPoint) => {
              if (highlightClusterKeys.includes(thisPoint))
                return (
                  <Marker
                    key={thisPoint}
                    icon={startZoomIcon}
                    position={keyToLatLon(thisPoint)}
                  />
                );
              else
                return (
                  <MarkerPlanner
                    key={thisPoint}
                    segmentsMarker={clusters[thisPoint]}
                    keyArrivalPoint={thisPoint}
                    openPopup={openPopup}
                    allSegments={segments}
                    weigthPackageAlertOnCluster={weigthPackageAlertOnCluster}
                    setHightlightClusterKey={setHightlightClusterKey}
                  />
                );
            })}
            {startPointId &&
              startPointSubject &&
              renderExtremeMarker(startPointSubject as Subject, startIcon)}
            {finalPointId &&
              finalPointSubject &&
              renderExtremeMarker(finalPointSubject as Subject, endIcon)}
            <MapLegend />
          </>
          <Routing onRoutesFound={onRoutePointsFound} points={points} />
          {segments &&
            editMode &&
            showAllRoute &&
            routes.map((route, index) => {
              if (!selectedRoute || selectedRoute.id !== route.id) {
                const segmentsFiltered = segments
                  .filter((segment) => segment.route_id === route.id)
                  .sort(sortDeliveryGroupForSequence);
                const points = getPointsGroupedDeliveries(
                  getSegmentsGrouped(segmentsFiltered, route.final_point!.id, route.start_point!.id, route.planned_date),
                  route.start_point,
                  route.final_point
                );
                return (
                  <Routing
                    color={colors[index]}
                    key={route.id}
                    points={points}
                  />
                );
              }
              return null;
            })}
        </MapContainer>
      </Box>
      <MarkerPopup
        onAddClick={toggleSegmentWeigthPackageAlert}
        onSelectDelivery={setActiveDelivery}
        ref={popupRef}
      />
      <DriverDialog
        onClose={() => setOpenDriverDialog(false)}
        onSelect={updateSelectedDriver}
        open={openDriverDialog}
      />
      <CarrierDialog
        onClose={() => setOpenCarrierDialog(false)}
        onSelect={updateSelectedCarrier}
        open={openCarrierDialog}
      />
      <VehicleDialog
        onClose={() => setOpenVehicleDialog(false)}
        onSelect={updateSelectedVehicle}
        open={openVehicleDialog}
      />

      <SplitSegmentsDialog
        onClose={() => setOpenSplitSegmentsDialog(false)}
        open={openSplitSegmentsDialog}
        segments={addedSegments}
        onActiveRouteChange={onActiveRouteChange}
      />

      <RouteDialog
        {...routesList}
        selectedIds={routes.map((route) => route.id as Identifier)}
        onClose={() => setOpenRoutesDialog(false)}
        onSelect={onToggleRoute}
        open={openRoutesDialog}
        onSelectAll={(routes) =>
          setSelectedRoutes(routes, updateFilterOnRoutesSelection as any)
        }
      />
      <NewSegmentDialog
        delivery={activeDelivery}
        onClose={() => closeModalUpdate()}
        open={isOpenUpdateModal}
        onSave={editSegments}
      />
    </Grid>
  );
};
