import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  SelectField,
  SingleFieldList,
  TextField
} from "react-admin";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { GoToLoadDeliveriesButton } from "../../components/GoToLoadDeliveriesButton";
import { VehicleField } from "../../components/VehicleField";
import { DriverCarrierField } from "../../Distribution/Route/DriverCarrierField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";

export const LoadRouteDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <TextField {...initField("id", options)} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <TextField {...initField("device", options)} />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <BooleanField {...initField("sent", options)} />
      <DriverCarrierField label={"Autista/Vettore"} />
      <VehicleField {...initField("vehicle", options)} />
      <GoToLoadDeliveriesButton filterAttribute={"route"} label="Carica missioni" />
      <GoToDeliveriesButton filterAttribute={"route"} label="Vedi Missioni" />
      {/*
      <ArrayField source="zones" label="Zone">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" record={{ id: record }} source="id" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      */}
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  );
};
