// botton crea riconsegna
import { Dialog, DialogContent, DialogActions, makeStyles } from "@material-ui/core";
import {
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  useNotify,
  AutocompleteInput,
  useUpdateMany,
  useListContext,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TextInput
} from "react-admin";
import { useToggler } from "../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import SubjcetTimeSelector from "../components/SubjectTimeSelector";
import { initInput } from "../shared/utils";
import { useState } from "react";
import { useForm } from "react-final-form";
import { useOptions } from "../shared/hooks/useOptions";

const validateTime = (value: string) => {
  // Regex per validare ore e minuti nel formato HH:MM
  const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

  // Se non corrisponde alla regex, ritorna un messaggio di errore
  if (!regex.test(value)) {
    return 'Il formato deve essere HH:MM (ore tra 00 e 23, minuti tra 00 e 59)';
  }
  return undefined;
};

const useIteratorStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1em',
    flexWrap: 'wrap',
  }
}));

export const AddOpeningSubjectBulkButton = () => {
  const { value, setTrue, setFalse } = useToggler();

  return <>
    <Button
      onClick={(e) => {
        e.stopPropagation();
        setTrue();
      }}
      label={"Associa orari apertura"}
    ></Button>
    <Dialog
      open={value}
      onClick={(e) => e.stopPropagation()}
      fullWidth
      maxWidth="lg"
    >
      <FormWithRedirect
        render={(formProps) => {
          return <OpeningSubjectForm
            setFalse={setFalse}
            formProps={formProps}
          />
        }}
      />
    </Dialog >
  </>
};


const OpeningSubjectForm = ({ setFalse, formProps }: any) => {

  const { getState, change } = useForm(formProps);
  const iteratorClasses = useIteratorStyle();

  const notify = useNotify();

  const [updateMany, { loading }] = useUpdateMany();
  const { selectedIds, refetch } = useListContext();

  const { data: optionsSubjectOpenings } = useOptions("subjectopenings", "GET");

  const [days, setDays] = useState<[]>([]);
  const [timeFrom, setTimeFrom] = useState("08:00");
  const [timeTo, setTimeTo] = useState("13:00");

  const handleChangeDays = (event: any) => {
    setDays(event.target.value);
  };

  const handleTimeFromChange = (event: any) => {
    setTimeFrom(event.target.value);
  };

  const handleTimeToChange = (event: any) => {
    setTimeTo(event.target.value);
  };

  const handleSubjectOpenings = () => {
    for (let day of days) {
      setTimeout(() => {
        const formState = getState();
        let old_subjectopenings = formState.values.subjectopenings || [];

        const new_hours = {
          day: day,
          hour_from: timeFrom,
          hour_to: timeTo
        };
        let updated_subjectopenings = [...old_subjectopenings, new_hours];
        const dayOrder: any = {
          "MO": 1,
          "TU": 2,
          "WE": 3,
          "TH": 4,
          "FR": 5,
          "SA": 6,
          "SU": 7
        };

        updated_subjectopenings.sort((a, b) => {
          return dayOrder[a.day] - dayOrder[b.day];
        });

        change('subjectopenings', updated_subjectopenings);
      }, 2000);
    }
  };

  const onClick = () => {
    let data = {
      subjectopenings: getState().values["subjectopenings"],
    }
    updateMany("subjects", selectedIds, data, {
      onFailure: () => notify("Errore!", "error"),
      onSuccess: (response: any) => {
        let errors = "";
        for (let k in response.errors) {
          errors += k + " -> " + response.errors[k] + "\n "
        }
        if (errors !== "") {
          window.alert(errors);
        }
        else {
          notify("Missioni aggiornate");
        }
        setFalse();
        refetch();
      },
    });
  };


  return optionsSubjectOpenings ? (
    <>
      <DialogContent>
        <SubjcetTimeSelector
          days={days} timeFrom={timeFrom} timeTo={timeTo}
          handleChangeDays={handleChangeDays} handleTimeFromChange={handleTimeFromChange} handleTimeToChange={handleTimeToChange}
          handleSubjectOpenings={handleSubjectOpenings}
        />

        <ArrayInput
          {...initInput("subjectopenings", {}, true)}
          label="Orari apertura"
        >
          <SimpleFormIterator classes={iteratorClasses}>
            <SelectInput
              optionValue="value"
              choices={optionsSubjectOpenings.day.choices}
              optionText="display_name"
              source="day"
              label="Giorno"
              validate={[() => {
                if (getState().values["subjectopenings"].length > 1) {
                  for (let i = 1; i < getState().values["subjectopenings"].length; i++) {
                    if (!getState().values["subjectopenings"][i - 1] || !getState().values["subjectopenings"][i])
                      continue
                    // check if week day is less than the previous one, days are formatted
                    /**
                      MONDAY = 'MO', "Lunedì"
                      TUESDAY = 'TU', "Martedì"
                      WEDNESDAY = 'WE', "Mercoledì"
                      THURSDAY = 'TH', "Giovedì"
                      FRIDAY = 'FR', "Venerdì"
                      SATURDAY = 'SA', "Sabato"
                      SUNDAY = 'SU', "Domenica"
                    */
                    switch (getState().values["subjectopenings"][i].day) {
                      case "MO":
                        if (["TU", "WE", "TH", "FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                          return "Errore ordine giorni"
                        }
                        break;
                      case "TU":
                        if (["WE", "TH", "FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                          return "Errore ordine giorni"
                        }
                        break;
                      case "WE":
                        if (["TH", "FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                          return "Errore ordine giorni"
                        }
                        break;
                      case "TH":
                        if (["FR", "SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                          return "Errore ordine giorni"
                        }
                        break;
                      case "FR":
                        if (["SA", "SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                          return "Errore ordine giorni"
                        }
                        break;
                      case "SA":
                        if (["SU"].includes(getState().values["subjectopenings"][i - 1].day)) {
                          return "Errore ordine giorni"
                        }
                        break;
                      case "SU":
                        break;
                    }
                  }
                }
              }]}
            />
            <TextInput source="hour_from" label="Dalle" required validate={[(e: any) => {
              let key = getState().active?.split(".")[0].replace("subjectopenings", "").replace("[", "").replace("]", "")
              if (key && getState().values && getState().values.subjectopenings[key] && getState().values["subjectopenings"][key].hour_to) {
                if (getState().values["subjectopenings"][key].hour_to < getState().values["subjectopenings"][key].hour_from) {
                  return "L'orario di apertura deve essere minore di quello di chiusura"
                }
              }
            },
              validateTime,
            () => {
              if (getState().values["subjectopenings"].length > 1) {
                for (let i = 1; i < getState().values["subjectopenings"].length; i++) {
                  if (
                    getState().values["subjectopenings"][i - 1] && getState().values["subjectopenings"][i] &&
                    getState().values["subjectopenings"][i - 1].day === getState().values["subjectopenings"][i].day &&
                    getState().values["subjectopenings"][i].hour_from < getState().values["subjectopenings"][i - 1].hour_to) {
                    return "Errore sovrapposizoone orari"
                  }
                }
              }
            }]} />
            <TextInput source="hour_to" label="Alle" required validate={[() => {
              let key = getState().active?.split(".")[0].replace("subjectopenings", "").replace("[", "").replace("]", "")
              if (key && getState().values && getState().values.subjectopenings[key] && getState().values["subjectopenings"][key].hour_from) {
                if (getState().values["subjectopenings"][key].hour_to < getState().values["subjectopenings"][key].hour_from) {
                  return "L'orario di chiusura deve essere maggiore di quello di apertura"
                }
              }
            },
              validateTime,
            () => {
              if (getState().values["subjectopenings"].length > 1) {
                for (let i = 1; i < getState().values["subjectopenings"].length; i++) {
                  if (
                    getState().values["subjectopenings"][i - 1] && getState().values["subjectopenings"][i] &&
                    getState().values["subjectopenings"][i - 1].day === getState().values["subjectopenings"][i].day &&
                    getState().values["subjectopenings"][i].hour_from < getState().values["subjectopenings"][i - 1].hour_to) {
                    return "Errore sovrapposizoone orari"
                  }
                }
              }
            }]} />
          </SimpleFormIterator>
        </ArrayInput>
      </DialogContent>
      <DialogActions>
        <Button
          label="ra.action.cancel"
          onClick={setFalse}
          disabled={loading}
        >
          <IconCancel />
        </Button>
        <SaveButton
          handleSubmitWithRedirect={onClick}
          disabled={loading}
        />
      </DialogActions></>) : <></>
}