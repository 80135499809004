import { Button, Record, useEditContext, useListContext, useNotify } from "react-admin";
import { Datagrid, DateField, FunctionField, NumberField, SelectField } from "react-admin";
import { IdentifierField } from "../../components/IdentifierField";
import { SubjectField } from "../../components/SubjectField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { generateError, initField } from "../../shared/utils";
import { CreatePlaceholderDeliveryButton } from "./CreatePlaceholderDeliveryButton";
import { DeliveryField } from "../../components/DeliveryField";
import SendIcon from "@material-ui/icons/Send";
import { useState } from "react";
import { client } from "../../dataProvider";
import { CircularProgress } from "@material-ui/core";
import { DownloadRemittanceExcleButton } from "./DownloadRemittanceExcelButton";
import { DownloadRemittancePdfButton } from "./DownloadRemittancePdfButton";

export const RemittanceDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  const { refetch } = useListContext();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const emitRemittance = async (id: number) => {
    setLoading(true);

    client(`/remittances/${id}`, {
      method: "PATCH",
      data: { status: "B" }
    }).then((response) => {
      let errors = "";
      for (let k in response.errors) {
        errors += k + " -> " + response.errors[k] + "\n "
      }
      if (errors !== "") {
        window.alert(errors);
      }
      else {
        notify("Rimessa emessa");
      }
      refetch();
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      });

  };


  return (
    <Datagrid {...rest} isRowSelectable={(record) => record.status !== "B"}>
      <IdentifierField {...initField("id", options)} />
      <NumberField
        {...initField("remittance_sequential_number", options)}
      />
      <NumberField
        {...initField("total", options)}
        options={{
          style: "currency",
          currency: "EUR",
        }}
      />
      <DateField {...initField("remittance_date", options)} />
      <SelectField
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />
      <SelectField
        {...initField("type", options, "array")}
        choices={options.type.choices}
      />
      <SubjectField {...initField("client", options, "array")} />
      <FunctionField
        label="Incassi"
        source="id"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.id) {
            return <a href={`/cachet-payments?displayedFilters=%7B%22remittance%22%3Atrue%7D&filter=%7B%22remittance%22%3A${tableRecord.id}%7D&order=DESC&page=1&perPage=50&sort=id`} target="_blank">Incassi</a>
          }
          return <></>;
        }}
        sortable={false}
      />
      <FunctionField
        label="Missione segnaposto"
        source="delivery"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.delivery) {
            return <DeliveryField
              noTitle
              deliverySource={"delivery"}
              {...initField("delivery", options)} />
          }
          return <CreatePlaceholderDeliveryButton />;
        }}
        sortable={false}
      />
      <FunctionField
        label="Missione segnaposto"
        source="delivery"
        render={(tableRecord: Record | undefined) => {

          if (tableRecord && tableRecord.status && tableRecord.status !== "B") {

            return loading ? <CircularProgress size={18} thickness={2} /> : <Button variant="contained" label="Emetti" onClick={(e) => {
              e.stopPropagation();
              emitRemittance(tableRecord!.id as number);
            }
            }>
              <SendIcon />
            </Button>
          }
          return <></>
        }}

        sortable={false}
      />
      <FunctionField
        label=""
        source="delivery"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord) {
            return (
              <>
                <DownloadRemittanceExcleButton record={tableRecord} />
                <DownloadRemittancePdfButton record={tableRecord} />
              </>
            )
          }
          return <></>
        }}

        sortable={false}
      />


    </Datagrid >
  );
};


