import { Card, CardContent, CardHeader, Checkbox, Grid, makeStyles, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { getUser } from "../../authProvider";


import { useEffect, useState, useCallback, useRef, useMemo } from "react";

import dataprovider, { client } from "../../dataProvider";
import { AutocompleteArrayInput, AutocompleteInput, Button, DateTimeInput, ReferenceArrayField, ReferenceArrayInput, ReferenceInput, Tab, useNotify, useRedirect } from "react-admin";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "1px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 4px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "1px solid lightgray",
      padding: "4px 4px",
      fontSize: "1.1em",
    },
    "& tr": {
      '&:nth-child(odd)': {
        backgroundColor: '#EAEAEA',
      },
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' // fourth bg color
      },
      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },
    //alternative row only firs column
    '& tr:nth-child(even) td:first-child': {
      backgroundColor: '#FFFFFF',
    },
    '& tr:nth-child(odd) td:first-child': {
      backgroundColor: '#EAEAEA',
    },
  }
});

export const PresenceSummary = (props: any) => {
  const classes = useStyles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlStartDate = urlParams.get('urlStartDate') ? urlParams.get('startDate') : new Date().toISOString().substring(0, 10);
  const urlEndDate = urlParams.get('endDate') ? urlParams.get('endDate') : new Date().toISOString().substring(0, 10);
  const urlSite = urlParams.get('site') ? urlParams.get('site') : "";
  const urlDepartments = urlParams.get('departments') ? urlParams.get('departments') : "";

  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [calendarData, setCalendarData] = useState<any>([]);
  const [calendarDays, setCalendarDays] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(urlStartDate);
  const [site, setSite] = useState<any>(urlSite);
  const [departments, setDepartments] = useState<any>(urlDepartments);
  const [endDate, setEndDate] = useState<any>(urlEndDate);
  const [months, setMonths] = useState<any>([]);
  const [sitesOptions, setSitesOptions] = useState<any>([]);
  const [departmentsOptions, setDepartmentsOptions] = useState<any>([]);
  const [tableHeight, setTableHeight] = useState<any>(150);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const refSerarchBar = useRef(null)

  function reloadCalendar() {

    setLoading(true)
    client(`./presences/get_employees_summary/?departments=${departments}&site=${site}&start_date=${startDate}&end_date=${endDate}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let presence_empy = []
          for (let p_e in response.calendar) {
            presence_empy.push(response.calendar[p_e])
          }
          presence_empy.sort(((a, b) => a.surname.localeCompare(b.surname)))
          setCalendarData(presence_empy)
          setCalendarDays(response.days)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useMemo(() => {
    reloadCalendar()
  }, [])


  useEffect(() => {
    if (refSerarchBar !== null && refSerarchBar.current !== null && refSerarchBar!.current!['clientHeight'] !== null)
      try {
        setTableHeight(refSerarchBar!.current!['clientHeight'] + 50)
      } catch (e) {
      }
  }, [
    refSerarchBar.current
  ])

  useEffect(() => {
    if (months.length > 0) {
      setStartDate(new Date(new Date(new Date(startDate).setDate(1)).setMonth(months[0] - 1)).toISOString().substring(0, 10))
      let d = new Date(new Date(new Date(endDate).setDate(1)).setMonth(months[months.length - 1]))
      d.setDate(d.getDate() - 1)
      setEndDate(d.toISOString().substring(0, 10))
    }

  }, [months])

  useMemo(() => {
    setLoading(true)
    client(`./sites`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let sites = []
          for (let s in response.results) {
            sites.push({
              id: response.results[s].id,
              label: response.results[s].business_name
            })
          }
          sites.sort(((a, b) => a.label.localeCompare(b.label)))
          setSitesOptions(sites)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }, [])

  useMemo(() => {
    setLoading(true)
    client(`./departments`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let departments = []
          for (let s in response.results) {
            departments.push({
              id: response.results[s].pk,
              label: response.results[s].description
            })
          }
          departments.sort(((a, b) => a.label.localeCompare(b.label)))
          setDepartmentsOptions(departments)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }, [])


  function getWorkPermitColor(presence: any) {
    let color_1 = "rgba(255, 165, 5, 0.5)"

    if (presence.info.toLowerCase().includes("feri")) {
      color_1 = "rgba(255, 165, 5, 0.5)";
    }
    else if (presence.info.toLowerCase().includes("permess")) {
      color_1 = "rgba(255, 0, 0, 0.5)";
    }
    else if (presence.info.toLowerCase().includes("malatti")) {
      color_1 = "rgba(25, 5, 255, 0.5)";
    }
    else {
      color_1 = "rgba(165, 5, 255, 0.5)";
    }

    return color_1

  }

  function getSumColor(minutes: any) {
    let color_1 = "rgba(5, 141, 0, 0.5)"

    if (minutes > 0 && minutes < 480) {
      color_1 = "rgba(5, 141, 0, 0.5)";
    }
    else if (minutes < 540) {
      color_1 = "rgba(5, 141, 0, 0.3)";
    }
    else if (minutes < 600) {
      color_1 = "rgba(255, 217, 0, 0.5)";
    }
    else {
      color_1 = "rgba(255, 0, 0, 0.5)";
    }

    return color_1

  }


  function isSunday(date: any) {
    let d = new Date(date)
    return d.toLocaleString('it-IT', { 'weekday': 'long' }).toUpperCase() == "DOMENICA"
  }


  function formatDate(date: any) {
    let d = new Date(date)
    return d.toLocaleString('it-IT', { 'weekday': 'short', 'month': '2-digit', 'day': '2-digit' }).toUpperCase()
  }

  return (
    <>
      <div style={{
        display: (loading) ? "block" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 999,
      }}>
        <Typography style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2em",
        }}>
          Loading...
        </Typography>
      </div>
      <div onKeyDown={(e) => {
        if (e.key === "Enter") {
          reloadCalendar()
        }
      }}>

        <Grid container
          ref={refSerarchBar}
          style={{
            padding: "10px",
            marginRight: "30px",
            width: "100%"
          }}>
          <TextField
            style={
              {
                width: "250px",
                marginRight: "10px"
              }
            }
            className={classes.root}

            id="start_date"
            label="Da"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
            }}
          />
          <TextField
            style={
              {
                width: "250px",
                marginRight: "10px"
              }
            }
            className={classes.root}
            id="end_date"
            label="A"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
            }}
          />
          <Autocomplete
            style={
              {
                width: "300px",
                marginRight: "10px"
              }
            }
            multiple
            id="months"
            options={[
              { id: 1, label: 'Gennaio' },
              { id: 2, label: 'Febbraio' },
              { id: 3, label: 'Marzo' },
              { id: 4, label: 'Aprile' },
              { id: 5, label: 'Maggio' },
              { id: 6, label: 'Giugno' },
              { id: 7, label: 'Luglio' },
              { id: 8, label: 'Agosto' },
              { id: 9, label: 'Settembre' },
              { id: 10, label: 'Ottobre' },
              { id: 11, label: 'Novembre' },
              { id: 12, label: 'Dicembre' },
            ]}
            getOptionLabel={(option: any) => option.label}
            filterSelectedOptions
            onChange={(event: any, newValue: any) => {
              let tempMonths = []
              for (let e in newValue) {
                tempMonths.push(newValue[e].id)
              }
              tempMonths.sort(function (a, b) { return a - b })
              setMonths(tempMonths)
            }}

            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Seleziona mesi"
              />
            )}
          />

          {
            sitesOptions && <Autocomplete
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              id="site"
              options={sitesOptions}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                if (newValue)
                  setSite(newValue.id)
                else
                  setSite("")
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona sede"
                />
              )}
            />
          }

          {
            departmentsOptions && <Autocomplete
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              multiple
              id="departments"
              options={departmentsOptions}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempDepartments = []
                for (let e in newValue) {
                  tempDepartments.push(newValue[e].id)
                }
                tempDepartments.sort(function (a, b) { return a - b })
                setDepartments(tempDepartments)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona reparti"
                />
              )}
            />
          }

          <Button
            style={
              {
                height: "55px",
                width: "100px",
                marginRight: "10px"
              }
            }
            onClick={() => {
              reloadCalendar()
            }}
            label=""
            color="primary"
            variant="contained"
          >
            <Typography>Filtra</Typography>
          </Button>
        </Grid>


        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  position: "sticky",
                  left: 0,
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 999,
                  minWidth: "300px"
                }}
                >Operatore
                </TableCell>
                <TableCell style={{
                  position: "sticky",
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 99
                }}
                >Sede
                </TableCell>
                <TableCell style={{
                  position: "sticky",
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 99
                }}
                >Mansione
                </TableCell>
                <TableCell style={{
                  position: "sticky",
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 99
                }}
                >Giorni lavorativi
                </TableCell>
                <TableCell style={{
                  position: "sticky",
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 99
                }}
                >I.G.H.
                </TableCell>
                {
                  calendarDays.map((day: any) => {
                    return (
                      <TableCell style={{
                        minWidth: "50 px",
                        position: "sticky",
                        top: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99
                      }}
                      >
                        {formatDate(Date.parse(day))}
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                calendarData &&
                calendarData.map((item: any) => {
                  return (
                    <TableRow style={{
                      backgroundColor: selectedRows.includes(item.id) ? "rgba(255, 255, 0, 0.2)" : ""
                    }}>
                      <TableCell style={{
                        paddingLeft: "5px",
                        position: "sticky",
                        left: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99,
                        backgroundColor: selectedRows.includes(item.id) ? "rgb(255, 255, 0)" : ""
                      }}>
                        <Grid container >
                          <Grid item xs={11}>{item.id}: {item.surname} {item.name}</Grid>
                          <Grid item xs={1}><Checkbox
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setSelectedRows([...selectedRows, item.id])
                              }
                              else {
                                setSelectedRows(selectedRows.filter((i: any) => i != item.id))
                              }
                            }}></Checkbox></Grid>
                        </Grid>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "center"
                      }}>{item.site}</TableCell>
                      <TableCell style={{
                        textAlign: "center"
                      }}>{item.departments}</TableCell>
                      <TableCell style={{
                        textAlign: "center"
                      }}>{item.days_sum}</TableCell>
                      <TableCell style={{
                        textAlign: "center",
                        backgroundColor: getSumColor(item.minutes_sum / item.days_sum)
                      }}>
                        {item.days_sum !== 0 ? (Math.floor(item.minutes_sum / item.days_sum / 60) + ":" + Math.floor(item.minutes_sum / item.days_sum) % 60) : "0:00"}
                      </TableCell>
                      {
                        item.presences.map((presence: any) => {
                          return (
                            <TableCell
                              style={{
                                backgroundColor: isSunday(presence.date) ? "rgba(255, 0, 0, 0.2)" : "rgba(255, 255, 255, 0)",
                                margin: isSunday(presence.date) ? "0px !important" : "",
                                padding: isSunday(presence.date) ? "0px !important" : "",
                              }}
                            >
                              <div style={{
                                display: "flex", flexDirection: "row",
                                textAlign: "center",
                              }}>
                                {
                                  presence.minutes_sum > 0 ?
                                    <div
                                      style={{
                                        //backgroundColor: getDoubleDateColor(presence)[0],
                                        //border: "1px solid black",
                                        borderRadius: "5px",
                                        width: "100%",
                                        height: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}

                                      title={""}
                                      onMouseEnter={(e) => {
                                        e.currentTarget.style.border = "3px solid purple"
                                        e.currentTarget.style.cursor = "pointer"
                                      }}
                                      onMouseLeave={(e) => {
                                        e.currentTarget.style.border = "0px"
                                        e.currentTarget.style.cursor = "default"
                                      }}
                                    >
                                      {Math.floor(presence.minutes_sum / 60)}:{Math.floor(presence.minutes_sum) % 60}
                                    </div> : <div></div>
                                }
                                {presence.extra.length > 0 &&
                                  <div
                                    style={{
                                      //backgroundColor: getDoubleDateColor(presence)[0],
                                      //border: "1px solid black",
                                      borderRadius: "5px",
                                      width: "100%",
                                      height: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }}
                                  >
                                    {
                                      presence.extra.map((extra: any) => {
                                        return (
                                          <div
                                            style={{
                                              backgroundColor: getWorkPermitColor(extra),
                                              border: "1px solid black",
                                              borderRadius: "5px",
                                              width: "100%",
                                              height: "30px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center"
                                            }}
                                            title={extra.info + " - " + extra.date_in + "/" + extra.date_out + " " + extra.notes_employee}
                                            onMouseEnter={(e) => {
                                              e.currentTarget.style.border = "3px solid purple"
                                              e.currentTarget.style.cursor = "pointer"
                                            }}
                                            onMouseLeave={(e) => {
                                              e.currentTarget.style.border = "1px solid black"
                                              e.currentTarget.style.cursor = "default"
                                            }}
                                            onClick={(e) => {
                                              window.open("/workpermits/??displayedFilters={\"id\"%3Atrue}&filter={\"id\"%3A" + extra.id + "}", "_blank")
                                            }}
                                          >
                                            {
                                              presence.minutes_sum > 0 ?
                                                "*"
                                                : extra.info.substring(0, 5)}
                                          </div>
                                        )
                                      })
                                    }
                                  </div>

                                }
                              </div>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          {calendarData &&
            calendarData.map((item: any) => {
              return (
                <div>
                  <Typography variant="h4">{item.employee}</Typography>
                  <Typography variant="h4">{item.date_in}</Typography>
                  <Typography variant="h4">{item.date_out}</Typography>
                </div>
              )
            })
          }
        </div>
      </div >
    </>
  );
};
