import { Grid, Typography } from "@material-ui/core";

import { useEffect, useState, useCallback, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { client } from "../dataProvider";
import Webcam from "react-webcam";
import { useNotify } from "react-admin";

import ReplayIcon from '@material-ui/icons/Replay';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { getUser } from "../authProvider";

const videoConstraints = {
  width: 800,
  height: 1200,
  facingMode: "environment",
};

const WebcamCapture = (props: { imgSrc: any, setImgSrc: any }) => {
  const webcamRef = useRef<any>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    props.setImgSrc(imageSrc);
  }, [webcamRef, props.setImgSrc,]);

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        minScreenshotHeight={1200}
        minScreenshotWidth={800}
        style={{ width: "200px", height: "200px" }}
      />
      <button onClick={capture} style={{ marginRight: "20px" }}><PhotoCameraIcon fontSize={"large"} /></button>

      {props.imgSrc && <img src={props.imgSrc} alt="img" width={200} height={300} />}

    </div>
  );
};

export const RouteScanner = (props: any) => {

  const user = getUser();
  const [scanActive, setScanActive] = useState(true);
  const [lastRouteStarted, setLastRouteStarted] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(true);
  const [loading, setLoading] = useState(false);

  const notify = useNotify();



  const [imgSrc, setImgSrc] = useState<any>(null);

  const resetView = () => {
    setScanActive(true)
    setShowQrScanner(true)
    setImgSrc(null)
  }

  return (
    <>
      <div style={{
        visibility: loading ? "visible" : "hidden",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: 1000,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}>
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Typography variant="h3" style={{ color: "white" }}>Caricamento...</Typography>
        </div>
      </div>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Grid item spacing={2} alignItems="center" justifyContent="center">
            <Typography
              variant="h5"
              align="center">
              Scansiona il QR per avviare il tuo giro <button><ReplayIcon onClick={() => resetView()} /></button>
            </Typography>
          </Grid>
          <Grid container item spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={6} style={{ backgroundColor: lastRouteStarted ? "green" : "" }}>
              <Typography
                variant="h3"
                align="center" >
                {lastRouteStarted ? ("GIRO AVVIATO: " + lastRouteStarted) : "ATTESA SCANSIONE..."}
              </Typography>
            </Grid>

          </Grid>
          <div>
            <div style={{ transform: "translate(50%, 0%)", height: "200px", width: "200px", margin: "0px 20px 0px 20px", textAlign: "center" }}>

              {
                showQrScanner === true ? (<QrReader
                  onResult={(result: any, error: any) => {
                    if (scanActive === false)
                      return
                    if (result && result.text) {
                      setLoading(true)
                      client(`./routes/qr_code_start_route/?qr=${result.text}`, {
                        method: "GET",
                      })
                        .then((response) => {
                          if (response.route) {
                            setLastRouteStarted(response.route);
                            notify("Giro avviato", { type: "success" });
                          }
                          setScanActive(false)
                          setShowQrScanner(false)
                          setImgSrc(null)
                        })
                        .catch((e: any) => {
                          notify(e.response.data.detail, { type: "error" });
                        })
                        .finally(() => setLoading(false));
                    }
                  }}
                  constraints={{ facingMode: 'environment' }}

                />) : (<></>)
              }

            </div>

          </div>

        </Grid>

      </Grid>
    </>

  );
};
