import { Dialog, DialogContent, DialogActions, Typography } from "@material-ui/core";
import {
  ArrayField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, dateTimeFormatter, generateError, initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { getPossibleNextStatusDelivery } from "./utils";
import { DeliveryStatusChoice, DeliveryType } from "../../shared/types";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { DeliveryStatusReasonField } from "./DeliveryStatusReasonField";
import { useState } from "react";
import { TariffClientDatagrid } from "../../components/TariffClientDatagrid";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { memoize } from "lodash";

interface AddDeliveryStatusButtonWarehouseProps {
  refreshMain?: boolean | undefined,
  disabled?: boolean | undefined
}

export const AddDeliveryStatusButtonWarehouse = (props: AddDeliveryStatusButtonWarehouseProps) => {
  const record = useRecordContext();
  const { value, setFalse, setTrue } = useToggler();
  const [newDelivery, setNewDelivery] = useState(true);
  const [fault, setFault] = useState();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    create("delivery-states", values, {
      onSuccess: ({ data }) => {
        if (data.new_delivery) {
          const url = `/deliveries/${data.new_delivery}?`;
          window.open(url);
        }
        setFalse();
        refresh();
        if (props.refreshMain) {
          window.location.reload();
        }
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const { data: options } = useOptions("delivery-states", "GET");
  const { data: optionsDeliveries } = useOptions("deliveries", "GET");
  const { data: optionsTariffs } = useOptions("tariffs", "GET");

  const [deliveryTypeNew, setDeliveryTypeNew] = useState(DeliveryType.NORMAL)

  function isEmpty(value: any) {
    return value === undefined || value === null || value === "";
  }
  const fieldRequired = memoize((message = 'ra.validation.required') =>
    Object.assign(
      (value: any, values: any) => {
        return [DeliveryStatusChoice.STOCKED, DeliveryStatusChoice.SUSPENDED].includes(
          values["status"]
        ) && isEmpty(value) ? "Selezionare un valore"
          : undefined
      },
      (value: any, values: any) => {
        return {
          isRequired: [DeliveryStatusChoice.STOCKED, DeliveryStatusChoice.SUSPENDED].includes(
            values["status"]
          )
        }
      }


    )
  );

  return options && optionsTariffs && record && props ? (
    <>{
      props.disabled === true ? <>
        <Typography style={{ "padding": "0px" }} tabIndex={-1}>{record.delivery_status_display.substring(0, 7)}</Typography>
      </> : (
        <>
          <Button style={{ "padding": "0px" }} tabIndex={-1} label={record.delivery_status_display.substring(0, 7)} onClick={() => setTrue()} />
          <Dialog open={value} maxWidth="sm" fullWidth>
            <FormWithRedirect
              resource="delivery-states"
              save={handleSubmit}
              initialValues={{ delivery: record.id }}
              render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
                <>
                  <DialogContent>
                    <DateTimeInput
                      initialValue={dateTimeFormatter(new Date())}
                      {...initInput("date", options, true)}
                      showTime={true}
                    />
                    <SelectInput
                      {...initInput("status", options, true, "array")}
                      choices={getPossibleNextStatusDelivery(record)}
                    />
                    <DeliveryStatusReasonField
                      options={options}
                      setFault={setFault}
                      form={form}
                      isRequired={
                        [DeliveryStatusChoice.STOCKED, DeliveryStatusChoice.SUSPENDED].includes(
                          form.getState().values?.status)
                      }
                    />
                    <TextInput {...initInput("status_notes", options, true)} />
                    {form.getState().values?.status ===
                      DeliveryStatusChoice.STOCKED && (
                        <ReferenceInputSubject
                          {...initInput(
                            "stock_store",
                            true
                          )}
                          validate={fieldRequired()}
                          label="Magazzino"
                          filter={{ type: "default_store" }}
                        />
                      )}

                    {form.getState().values?.status ===
                      DeliveryStatusChoice.STOCKED_END && (
                        <BooleanInput
                          source="cancel_new_delivery"
                          label="Da Annullare"
                          defaultValue={false}
                          onChange={(value: any) => {
                            setNewDelivery(!value);
                          }}
                        />
                      )}
                    {form.getState().values?.status ===
                      DeliveryStatusChoice.STOCKED_END &&
                      newDelivery &&
                      optionsTariffs && (

                        <>
                          <SelectInput
                            {...initInput(
                              "delivery_type_new",
                              {},
                              true,
                              "array"
                            )}
                            label="Tipo missione figlia"
                            defaultValue={DeliveryType.NORMAL}
                            choices={optionsDeliveries.delivery_type.choices}
                            onChange={(value: any) => {
                              form.change("create_return", null);
                              setDeliveryTypeNew(value.target.value);
                            }}
                          />

                          {!fault ? (
                            <ReferenceInput
                              source="create_return"
                              reference="tariffs-client"
                              label="Tariffa riconsegna"
                              filter={{
                                client: record.client,
                                document_date: record.document_date,
                                delivery_type: deliveryTypeNew,
                                receiver: record.receiver
                              }}
                              fullWidth
                              validate={required()}
                            >
                              <DialogSelectInput optionText="label">
                                <TariffClientDatagrid options={optionsTariffs} />
                              </DialogSelectInput>
                            </ReferenceInput>) : null
                          }
                        </>
                      )}



                  </DialogContent>
                  <DialogActions>
                    <Button label="ra.action.cancel" onClick={setFalse}>
                      <IconCancel />
                    </Button>
                    <FormSaveButton
                      handleSubmit={() => handleSubmitWithRedirect!()}
                      submitting={loading}
                      pristine={pristine}
                      label="Crea"
                    />
                  </DialogActions>
                </>
              )}
            />
          </Dialog>
        </>
      )
    }
    </>) : null;
};
