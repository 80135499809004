import { Card, CardContent, CardHeader, Grid, makeStyles, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { getUser } from "../../authProvider";


import { useEffect, useState, useCallback, useRef, useMemo } from "react";

import dataprovider, { client } from "../../dataProvider";
import { AutocompleteArrayInput, Button, DateTimeInput, ReferenceArrayField, ReferenceArrayInput, Tab, useNotify, useRedirect } from "react-admin";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "1px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 0px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "1px solid lightgray",
      padding: "4px 0px",
      fontSize: "1.1em",
    },
    "& tr": {
      '&:nth-child(odd)': {
        backgroundColor: '#EAEAEA',
      },
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' // fourth bg color
      },
      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },
    //alternative row only firs column
    '& tr:nth-child(even) td:first-child': {
      backgroundColor: '#FFFFFF',
    },
    '& tr:nth-child(odd) td:first-child': {
      backgroundColor: '#EAEAEA',
    },
  }
});

export const PresenceCalendar = (props: any) => {
  const classes = useStyles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlStartDate = urlParams.get('urlStartDate') ? urlParams.get('startDate') : new Date().toISOString().substring(0, 16);
  const urlEndDate = urlParams.get('endDate') ? urlParams.get('endDate') : new Date().toISOString().substring(0, 16);
  const urlEmployees = urlParams.get('employees') ? urlParams.get('employees')!.split(',').map(function (x) {
    return parseInt(x);
  }) : [];
  const urlSite = urlParams.get('site') ? urlParams.get('site') : "";
  const urlDepartments = urlParams.get('departments') ? urlParams.get('departments') : "";

  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [calendarData, setCalendarData] = useState<any>([]);
  const [calendarDays, setCalendarDays] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(urlStartDate);
  const [endDate, setEndDate] = useState<any>(urlEndDate);
  const [months, setMonths] = useState<any>([]);
  const [employees, setEmployees] = useState<any>(urlEmployees);
  const [employeesList, setEmployeesList] = useState<any>([]);
  const [tableHeight, setTableHeight] = useState<any>(150);
  const [sitesOptions, setSitesOptions] = useState<any>([]);
  const [site, setSite] = useState<any>(urlSite);
  const refSerarchBar = useRef(null)
  const [departmentsOptions, setDepartmentsOptions] = useState<any>([]);
  const [departments, setDepartments] = useState<any>(urlDepartments);



  const hourMultiplier = 10;

  function loadEmpoyessList() {

    client(`employees/get_all_employees`, {
      method: "GET",
      data: {
      }
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let employeesTemp = []
          for (let e in response) {
            employeesTemp.push({
              id: response[e].id,
              name: response[e].name,
              surname: response[e].surname,
              label: response[e].id + ": " + response[e].surname + " " + response[e].name
            })
          }
          setEmployeesList(employeesTemp)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }


  function reloadCalendar() {

    setLoading(true)
    client(`./presences/get_calendar/?start_date=${startDate}&end_date=${endDate}&site=${site}&departments=${departments}&employees=${employees}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let presence_empy = []
          for (let p_e in response.calendar) {
            presence_empy.push(response.calendar[p_e])
          }
          presence_empy.sort(((a, b) => a.surname.localeCompare(b.surname)))
          setCalendarData(presence_empy)
          setCalendarDays(response.days)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useMemo(() => {
    reloadCalendar()
    loadEmpoyessList()
  }, [])

  useMemo(() => {
    setLoading(true)
    client(`./sites`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let sites = []
          for (let s in response.results) {
            sites.push({
              id: response.results[s].id,
              label: response.results[s].business_name
            })
          }
          sites.sort(((a, b) => a.label.localeCompare(b.label)))
          setSitesOptions(sites)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }, [])

  useMemo(() => {
    setLoading(true)
    client(`./departments`, {
      method: "GET",
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let departments = []
          for (let s in response.results) {
            departments.push({
              id: response.results[s].pk,
              label: response.results[s].description
            })
          }
          departments.sort(((a, b) => a.label.localeCompare(b.label)))
          setDepartmentsOptions(departments)
          setLoading(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoading(false)
      });
  }, [])


  useEffect(() => {
    if (refSerarchBar !== null && refSerarchBar.current !== null && refSerarchBar!.current!['clientHeight'] !== null)
      try {
        setTableHeight(refSerarchBar!.current!['clientHeight'] + 50)
      } catch (e) {
      }
  }, [
    refSerarchBar.current
  ])

  useEffect(() => {
    if (months.length > 0) {
      setStartDate(new Date(new Date(new Date(startDate).setDate(1)).setMonth(months[0] - 1)).toISOString().substring(0, 11) + "02:00")
      setEndDate(new Date(new Date(new Date(endDate).setDate(1)).setMonth(months[months.length - 1])).toISOString().substring(0, 11) + "02:00")
    }

  }, [months])


  function getDoubleDateColor(presence: any) {
    let color_1 = "#f5a623"
    let color_2 = "#f5a623"

    if (presence.type === "work_permit") {
      if (presence.info.toLowerCase().includes("feri")) {
        color_1 = "orange";
        color_2 = "orange";
      }
      else if (presence.info.toLowerCase().includes("permess")) {
        color_1 = "red";
        color_2 = "red";
      }
      else if (presence.info.toLowerCase().includes("malatti")) {
        color_1 = "blue";
        color_2 = "blue";
      }
      else {
        color_1 = "purple";
        color_2 = "purple";
      }
    }
    else {
      if (presence.info !== null && presence.info !== "" && presence.info !== undefined) {
        if (presence.info.includes("MAN_IN")) {
          color_1 = "yellow";
        }
        else if (presence.info.includes("AUT_IN")) {
          color_1 = "grey";
        }
        else {
          color_1 = "green";
        }

        if (presence.info.includes("MAN_OUT")) {
          color_2 = "yellow";
        }
        else if (presence.info.includes("AUT_OUT")) {
          color_2 = "grey";
        }
        else {
          color_2 = "green";
        }
      }
      else {
        color_1 = "green";
        color_2 = "green";
      }
    }


    return [color_1, color_2]

  }

  return (
    <>
      <div style={{
        display: (loading) ? "block" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 999,
      }}>
        <Typography style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2em",
        }}>
          Loading...
        </Typography>
      </div>
      <div onKeyDown={(e) => {
        if (e.key === "Enter") {
          reloadCalendar()
        }
      }}>
        {employeesList && employees &&
          <Grid container
            ref={refSerarchBar}
            style={{
              padding: "10px",
              marginRight: "30px",
              width: "100%"
            }}>
            <TextField
              style={
                {
                  width: "200px",
                  marginRight: "10px"
                }
              }
              className={classes.root}

              id="start_date"
              label="Da"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
            <TextField
              style={
                {
                  width: "200px",
                  marginRight: "10px"
                }
              }
              className={classes.root}
              id="end_date"
              label="A"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Autocomplete
              style={
                {
                  width: "200px",
                  marginRight: "10px"
                }
              }
              multiple
              id="months"
              options={[
                { id: 1, label: 'Gennaio' },
                { id: 2, label: 'Febbraio' },
                { id: 3, label: 'Marzo' },
                { id: 4, label: 'Aprile' },
                { id: 5, label: 'Maggio' },
                { id: 6, label: 'Giugno' },
                { id: 7, label: 'Luglio' },
                { id: 8, label: 'Agosto' },
                { id: 9, label: 'Settembre' },
                { id: 10, label: 'Ottobre' },
                { id: 11, label: 'Novembre' },
                { id: 12, label: 'Dicembre' },
              ]}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempMonths = []
                for (let e in newValue) {
                  tempMonths.push(newValue[e].id)
                }
                tempMonths.sort(function (a, b) { return a - b })
                setMonths(tempMonths)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona mesi"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              multiple
              id="tags-outlined"
              options={employeesList}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let emp = []
                for (let e in newValue) {
                  emp.push(newValue[e].id)
                }
                setEmployees(emp)
              }}
              value={employeesList.filter((option: any) => employees.indexOf(option.id) > -1)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona dipendenti"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              id="site"
              options={sitesOptions}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                if (newValue)
                  setSite(newValue.id)
                else
                  setSite("")
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona sede"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              multiple
              id="departments"
              options={departmentsOptions}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempDepartments = []
                for (let e in newValue) {
                  tempDepartments.push(newValue[e].id)
                }
                tempDepartments.sort(function (a, b) { return a - b })
                setDepartments(tempDepartments)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona reparti"
                />
              )}
            />
            <Button
              style={
                {
                  height: "55px",
                  width: "100px",
                  marginRight: "10px"
                }
              }
              onClick={() => {
                reloadCalendar()
              }}
              label=""
              color="primary"
              variant="contained"
            >
              <Typography>Filtra</Typography>
            </Button>
          </Grid>
        }

        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  position: "sticky",
                  left: 0,
                  top: 0,
                  boxShadow: "1px 1px 1px grey",
                  borderRight: "1px solid black",
                  zIndex: 999
                }}
                >Operatore</TableCell>
                {
                  calendarDays.map((day: any) => {
                    return (
                      <TableCell style={{
                        minWidth: 24 * hourMultiplier + "px",
                        maxWidth: 24 * hourMultiplier + "px",
                        position: "sticky",
                        top: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99
                      }}
                      >{day}</TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                calendarData &&
                calendarData.map((item: any) => {
                  return (
                    <TableRow>
                      <TableCell style={{
                        paddingLeft: "5px",
                        position: "sticky",
                        left: 0,
                        boxShadow: "1px 1px 1px grey",
                        borderRight: "1px solid black",
                        zIndex: 99

                      }}>{item.id}: {item.surname} {item.name}</TableCell>
                      {
                        calendarDays.map((day: any) => {
                          return (
                            <TableCell>
                              <div style={{
                                display: "flex", flexDirection: "row",
                                width: 24 * hourMultiplier + "px !important",
                                minWidth: 24 * hourMultiplier + "px",
                                maxWidth: 24 * hourMultiplier + "px"
                              }}>
                                {
                                  item.presences[day] ?
                                    item.presences[day].map((presence: any) => {
                                      let colors = getDoubleDateColor(presence)
                                      return (
                                        <div
                                          style={{
                                            position: "relative",
                                            left: (parseInt(presence.date_in.substring(11, 13)) + (presence.date_in.substring(14, 16) / 60)) * hourMultiplier + "px",
                                            background: `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`,
                                            width: (presence.hours * hourMultiplier).toString() + "px",
                                            height: "34px",
                                            borderRadius: "5px",
                                          }}
                                          title={presence.date_in + "/" + presence.date_out + " " + presence.info}
                                          onMouseEnter={(e) => {
                                            e.currentTarget.style.border = "3px solid purple"
                                            e.currentTarget.style.cursor = "pointer"
                                          }}
                                          onMouseLeave={(e) => {
                                            e.currentTarget.style.border = "0px"
                                            e.currentTarget.style.cursor = "default"
                                          }}
                                          onClick={(e) => {
                                            if (presence.type === "work_permit")
                                              window.open("/workpermits/??displayedFilters={\"id\"%3Atrue}&filter={\"id\"%3A" + presence.id + "}", "_blank")
                                            else {
                                              window.open("/presences/??displayedFilters={\"id\"%3Atrue}&filter={\"id\"%3A" + presence.id + "}", "_blank")
                                            }
                                          }}
                                        >
                                          <div style={{
                                            position: "relative",
                                            left: "1px",
                                            marginRight: "305px",
                                            float: "left",
                                            fontSize: "0.9em",
                                            lineHeight: "1.1"
                                          }}>{
                                              presence.date_in.substring(11, 16)
                                            }
                                          </div>
                                          <div style={{
                                            position: "relative",
                                            bottom: "0px",
                                            right: "1px",
                                            marginLeft: "5px",
                                            paddingBottom: "5px",
                                            float: "right",
                                            fontSize: "0.9em",
                                            lineHeight: "1.1"
                                          }}>{
                                              presence.date_out ? presence.date_out.substring(11, 16) : null
                                            }
                                          </div>
                                        </div>
                                      )
                                    }) : null

                                }
                              </div>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          {calendarData &&
            calendarData.map((item: any) => {
              return (
                <div>
                  <Typography variant="h4">{item.employee}</Typography>
                  <Typography variant="h4">{item.date_in}</Typography>
                  <Typography variant="h4">{item.date_out}</Typography>
                </div>
              )
            })
          }
        </div>
      </div >
    </>
  );
};
