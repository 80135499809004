import { Button, Card, Dialog, DialogActions, DialogContent, TableContainer, Typography } from "@material-ui/core";
import { ListBase, useUpdate } from "ra-core";
import {
  Datagrid,
  DateField,
  FunctionField,
  TextField,
  Record,
  ArrayField,
  ChipField,
  SingleFieldList,
  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  NullableBooleanInput,
  FilterForm,
  useRecordContext,
  useNotify,
  useRedirect,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { CarrierField } from "../../components/CarrierField";
import { DriverField } from "../../components/DriverField";
import { SubjectField } from "../../components/SubjectField";
import { VehicleField } from "../../components/VehicleField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";
import { useToggler } from "../../shared/hooks/useToggler";
import { useState } from "react";
import IconCancel from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import useSound from 'use-sound'
import digital_alarm from '../../components/sounds/digital_alarm.mp3'
import { getUser } from "../../authProvider";
import { ReferenceArrayInputSubject } from "../../components/reference-inputs/ReferenceArrayInputSubject";
import { DriverCarrierField } from "../../Distribution/Route/DriverCarrierField";

export const ConfirmDialog = () => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const [loading, setLoading] = useState(false);
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");

  const [update] = useUpdate();


  const onClick = () => {
    setLoading(true);
    update({
      resource: "routes",
      payload: {
        id: record.id,
        data: {
          status: record.status === "P" ? "L" : "S",
        },
      },
    });
  };

  return record ? (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTrue()
        }}
        color="primary"
      >
        {record.status === "P"
          ? ""
          : "Giro in partenza"}
      </Button>
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >

        <DialogContent>
          <Typography>Ciao <b>{
            record.driver ? record.driver.name + " " + record.driver.surname : record.carrier.business_name
          }</b>, sicuro di voler avviare la partenza per il giro <b>{record.id}</b>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={setFalse}
            disabled={loading}
          >
            <IconCancel />
          </Button>
          <Button
            color="primary"
            onClick={(onClick)}
            disabled={loading}
          >SI, sto partendo</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};


interface PropsBoardRoute {
  filters: any;
  title: string;
  soundEmitted?: boolean;
  setSoundEmitted?: any;
}

export const BoardRoute = (props: PropsBoardRoute) => {
  const [playSound] = useSound(digital_alarm)
  const { filters, title } = props;
  const { data: options } = useOptions("routes", "GET");
  const delayedRouteRowStyle = (record: any, index: any) => ({
    backgroundColor: canPlaySound(record) ? '#feb9b9' : 'white',
  });
  const user = getUser();
  const filtersa = options
    ? [
      <TextInput {...initField("id", options)} />,
      <DateInput {...initField("planned_date", options)} />,
      <ReferenceArrayInputSubject
        alwaysOn
        {...initField("start_points", options)}
        label="Partenza"
        filter={{ departments: "" }}
        className="filter-field-small"
        style={{ width: "200px !important" }}
      />,

    ]
    : [];

  function canPlaySound(record: Record) {
    let datenow = new Date();
    datenow.setMinutes(datenow.getMinutes() - 15);
    let return_value = record && record.planned_date && new Date(String(record.planned_date)) < datenow;

    if (return_value === true && props.soundEmitted === false) {
      props.setSoundEmitted(true)
      playSound();
    }
    return return_value
  }

  return options ? (
    <ListBase
      resource="routes"
      syncWithLocation={false}
      basePath="/routes"
      filterDefaultValues={filters}
      perPage={1000}
      sort={{ field: "planned_date", order: "ASC" }}
    >
      <div style={{ width: "100%" }}>
        <h2>{title}</h2>
        <FilterForm filters={filtersa}
        />

        <Card>
          <TableContainer style={{ overflowY: "hidden" }}>
            <Datagrid rowStyle={delayedRouteRowStyle}>
              <TextField source="id" />
              <ArrayField source="tags_objects" label="Tag">
                <SingleFieldList>
                  <FunctionField
                    render={(record: any) => (
                      <ChipField size="small" source="label" />
                    )}
                  />
                </SingleFieldList>
              </ArrayField>
              <VehicleField source="vehicle" label="Veicolo" />
              <DriverCarrierField label={"Autista/Vettore"} />
              <FunctionField
                label=""
                render={(tableRecord: Record | undefined) => {
                  if (tableRecord) {
                    return (
                      <div style={{
                        borderRadius: "50%",
                        border: "0.5px solid black",
                        width: "20px", height: "20px",
                        backgroundColor: tableRecord["presence_flag"] === true ? "green" : "red",
                        opacity: 0.8
                      }}></div>);
                  }
                  return null;
                }}
                sortable={false}
              />

              <SubjectField source="start_point" label="Partenza" />
              <DateField
                source="planned_date"
                label="Ora partenza pian."
                showTime
              />


              {/* <ArrayField source="zones" label="Zone">
                <SingleFieldList>
                  <FunctionField
                    render={(record: any) => (
                      <ChipField record={{ id: record }} source="id" />
                    )}
                  />
                </SingleFieldList>
              </ArrayField> */}
              <FunctionField
                render={(record: Record | undefined) =>
                  record && record.status === "L" ? (
                    <ConfirmDialog />
                  ) : null
                }
              />

            </Datagrid>
          </TableContainer>
        </Card>
      </div>
    </ListBase>
  ) : null;
};
