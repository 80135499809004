// botton crea riconsegna
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useCallback, useState } from "react";
import {
  useRecordContext,
  Button,
  FormWithRedirect,
  SaveButton,
  useNotify,
  TextInput,
  FileInput,
  FileField,
  useCreate,
  useRefresh,
} from "react-admin";
import { useToggler } from "../shared/hooks/useToggler";
import { useOptions } from "../shared/hooks/useOptions";
import { initInput } from "../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";


export const AddSubjectAttachmentButton = () => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const [create] = useCreate();
  const refresh = useRefresh();
  const onClick = (values: any) => {
    setLoading(true);
    create("subject_attachments", values, {
      onSuccess: () => {
        notify("ra.notification.created", "success");
        setLoading(false);
        setFalse()
        refresh();
      },
      onFailure: (e: any) => {
        setLoading(false);
        notify("ra.notification.bad_item", "error")
      },
    });
  };

  const [loading, setLoading] = useState(false);
  const { data: optionsAttachment } = useOptions("subject_attachments", "GET");

  return optionsAttachment ? (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTrue();
        }}
        label={"Aggiungi Allegato"}
      ></Button>

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{ subject: record.id }}
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>

                <FileInput
                  {...initInput("file", optionsAttachment, true)}
                  labelSingle="Trascina o seleziona un file 
                  (tiff, tif, png, jpeg, jpg, pdf, txt, doc, docx, xls, xlsx, msg, eml)"
                >
                  <FileField source="src" title="title" />
                </FileInput>

                <TextInput
                  source="notes"
                  label="Note"
                  fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
