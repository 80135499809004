import { Drawer, Theme } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { TableGroupedDeliveries } from "../components/TableGroupedDeliveries";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonIcon from "@material-ui/icons/Person";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Form } from "react-final-form";
import { useStore } from "../store";

import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import "leaflet-area-select";
import { RecordMap, Record, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import { Route } from "../types";
import { useSize } from "../../shared/hooks/useSize";
import { Subject } from "../../shared/types";
import { ca } from "date-fns/locale";
import { set } from "lodash";

const useStylesSidebar = makeStyles<Theme, any>((theme) => ({
  paper: {
    top: "auto",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.only("sm")]: {
      height: (props) => props.height,
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      maxWidth: "700px",
      height: (props) => props.height,
    },
    [theme.breakpoints.up("md")]: {
      width: "700px",
    },
  },
}));
interface SidebarProps {
  startPointSubject?: Record;
  finalPointSubject?: Record;
  simulateRouteAssignment: () => void;
  saveRoute: () => void;
  autoOrder: () => void;
  invertRouteOrder: () => void;
  routesData: RecordMap<Record>;
  setOpenDriverDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCarrierDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenVehicleDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRoutesDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSplitSegmentsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handlerCarrier: boolean;
  onHandlerChange: (hC: boolean) => void;
  updateFilterOnRoutesSelection: (routes: Route[]) => void;
  pointsDurationMapping: [];
  endDate?: string;
  setShowAllRoute: (showAllRoute: boolean) => void;
  showAllRoute: boolean;
}
export const Sidebar = (props: SidebarProps) => {
  const {
    startPointSubject,
    finalPointSubject,
    simulateRouteAssignment,
    saveRoute,
    autoOrder,
    invertRouteOrder,
    routesData,
    setOpenDriverDialog,
    setOpenCarrierDialog,
    setOpenVehicleDialog,
    setOpenRoutesDialog,
    setOpenSplitSegmentsDialog,
    handlerCarrier,
    onHandlerChange,
    updateFilterOnRoutesSelection,
    pointsDurationMapping,
    setShowAllRoute,
    showAllRoute
  } = props;


  const [open, setOpen] = useState<boolean>(true);
  const [canSaveForEta, setCanSaveForEta] = useState<string>("");
  const [canSaveForEtaTemp, setCanSaveForEtaTemp] = useState<number>(0);

  function ignoreEtaWarning() {
    setCanSaveForEtaTemp(4);

    setTimeout(() => {
      setCanSaveForEtaTemp(3);
    }, 1000);
    setTimeout(() => {
      setCanSaveForEtaTemp(2);
    }
      , 2000);
    setTimeout(() => {
      setCanSaveForEtaTemp(1);
    }
      , 3000);
    setTimeout(() => {
      setCanSaveForEtaTemp(0);
    }
      , 4000);
  }

  const {
    editMode,
    carrier,
    driver,
    vehicle,
    groupedDeliveries,
    routes,
    selectedRoute,
    tags,
    finalPointId,
    startPointId,
    startDate,
    warehouseAlert,
    updateTags,
    updateStartPointId,
    updateFinalPointId,
    toggleEditMode,
    deleteDeliveries,
    reorderGroupedDeliveries,
    updateSelectedRoute,
  } = useStore();
  const saveButtonDisabled = useMemo(
    () =>
      (!(vehicle && driver) && !carrier) ||
      groupedDeliveries.length <= 0 ||
      !finalPointSubject ||
      !startPointSubject ||
      (tags === undefined || tags.length <= 0) ||
      !startDate ||
      warehouseAlert ||
      (canSaveForEta !== "" && canSaveForEtaTemp === 0),
    [
      driver,
      carrier,
      finalPointSubject,
      groupedDeliveries.length,
      startPointSubject,
      vehicle,
      tags,
      startDate,
      warehouseAlert,
      canSaveForEta,
      canSaveForEtaTemp,
    ]
  );
  const simulateButtonDisabled = useMemo(
    () =>
      (!carrier) ||
      groupedDeliveries.length <= 0 ||
      !finalPointSubject ||
      !startPointSubject,
    [
      carrier,
      finalPointSubject,
      groupedDeliveries.length,
      startPointSubject,
    ]
  );
  const size = useSize(document.getElementById("layout-main-content"));

  const classes = useStylesSidebar({ height: size?.height });

  const toggleSidebar = () => setOpen((value) => !value);


  return (
    <Drawer
      variant="permanent"
      open={open}
      onClose={toggleSidebar}
      classes={classes}
      anchor="right"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Grid container >
          <Grid xs={6} item container>
            <Grid item xs={2}>
              <Switch
                disabled={!editMode}
                checked={editMode ? showAllRoute : false}
                onChange={() =>
                  setShowAllRoute(!showAllRoute)
                }
                name="mode"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography>Mostra TUTTI i percorsi su mappa</Typography>
            </Grid>
          </Grid>
          <Grid xs={6} item container>
            <Grid item xs={10} spacing-xs-1>
              <Typography align="right" >Modalità di modifica</Typography>
            </Grid>
            <Grid item xs={2}>
              <Switch
                checked={editMode}
                onChange={() =>
                  toggleEditMode(() => updateFilterOnRoutesSelection([]))
                }
                name="mode"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {
        editMode && (
          <>
            <Divider />
            <Grid item>
              <Box padding={1}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      Selezione giri
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => setOpenRoutesDialog(true)}
                      title="Seleziona giro"
                    >
                      <ListAltIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {routes.length <= 0 ? (
                    <Typography variant="body1" color="textPrimary">
                      Nessun giro selezionato
                    </Typography>
                  ) : (
                    <RadioGroup
                      aria-label="giri"
                      name="route"
                      value={selectedRoute ? selectedRoute.id! : null}
                      row
                      onChange={(e, value) =>
                        updateSelectedRoute(
                          parseInt(value),
                          Object.values(routesData)
                        )
                      }
                    >
                      {routes.map((route: any) => {
                        return (<FormControlLabel
                          key={route.id!}
                          value={route.id!}
                          control={<Radio />}
                          label={`${route.id!}/${route["tags_objects"].map((tag: any) => tag.label).join(", ")}`}
                        />
                        )
                      }
                      )}
                    </RadioGroup>
                  )}
                </Grid>
              </Box>
            </Grid>
          </>
        )
      }
      <Divider />

      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Affida vettore</Typography>
        <Switch
          checked={handlerCarrier}
          onChange={() => {
            onHandlerChange(handlerCarrier);
          }}
          name="checkedB"
          color="primary"
        />
      </Box>
      <Divider />
      <Grid item container direction="column">
        {!handlerCarrier ? (
          <Grid item container>
            <Grid item xs={6}>
              <Box padding={1}>
                <Typography variant="caption" color="textSecondary">
                  Automezzo
                </Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" color="textPrimary">
                    {vehicle ? vehicle.internal_code : "-------"}
                  </Typography>
                  <IconButton
                    onClick={() => setOpenVehicleDialog(true)}
                    title="Seleziona automezzo"
                  >
                    <LocalShippingIcon />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
            <Divider />

            <Grid item xs={6}>
              <Box padding={1}>
                <Typography variant="caption" color="textSecondary">
                  Autista
                </Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" color="textPrimary">
                    {driver ? `${driver.surname} ${driver.name}` : "-------"}
                  </Typography>
                  <IconButton
                    onClick={() => setOpenDriverDialog(true)}
                    title="Seleziona autista"
                  >
                    <PersonIcon />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Box padding={1}>
              <Typography variant="caption" color="textSecondary">
                Vettore
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="textPrimary">
                  {carrier
                    ? `${carrier.id} ${carrier.business_name}`
                    : "-------"}
                </Typography>
                <IconButton
                  onClick={() => setOpenCarrierDialog(true)}
                  title="Seleziona vettore"
                >
                  <PersonIcon />
                </IconButton>
              </Grid>
            </Box>
          </Grid>
        )}

        <Divider />

        <Grid item>
          <Box padding={1}>
            <Form
              initialValues={{
                finalPoint: finalPointId,
                startPoint: startPointId,
                tags: tags,
              }}
              onSubmit={() => { }}
              render={() => (
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ marginBottom: "-40px" }}>
                    <ReferenceArrayInput
                      onChange={(value: any) => {
                        updateTags(value)
                      }}
                      reference="autocomplete/tag"
                      defaultValue={[]}
                      source="tags"
                      isRequired={true}
                      label="Tag"
                      sort={{ "field": "label", "order": "ASC" }}
                      className="filter-field-small">
                      <AutocompleteArrayInput optionText="label" fullWidth />
                    </ReferenceArrayInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInputSubject
                      onChange={(value: any) => updateStartPointId(value)}
                      fullWidth
                      allowEmpty={false}
                      source="startPoint"
                      label="Punto iniziale"
                      required={true}
                      helperText={false}
                      filter={{ type: "planner_receiver" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInputSubject
                      onChange={(value: any) => {
                        updateFinalPointId(value)
                      }}
                      fullWidth
                      allowEmpty={false}
                      source="finalPoint"
                      label="Punto finale"
                      required={true}
                      helperText={false}
                      filter={{ type: "planner_receiver" }}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider />
      {
        warehouseAlert && (
          <Typography align="center" variant="h3" color="error">
            Merce non in sede!
          </Typography>)
      }
      <Box padding={1} display="flex" justifyContent="space-between">
        <ButtonGroup variant="text" color="primary">
          <Button
            disabled={groupedDeliveries.length <= 0}
            onClick={() => setOpenSplitSegmentsDialog(true)}
          >
            Spezza tratte
          </Button>
          <Button onClick={simulateRouteAssignment} disabled={simulateButtonDisabled}>
            Simula € Vett.
          </Button>
        </ButtonGroup>
        <ButtonGroup variant="text" color="primary">
          <Button
            onClick={ignoreEtaWarning}
            disabled={
              canSaveForEta === ""
            }
            title="Ignora eta"
          >
            {canSaveForEtaTemp > 0 ? `(${canSaveForEtaTemp})` : "Ignora eta"}
          </Button>
          <Button
            onClick={invertRouteOrder}
            disabled={
              groupedDeliveries.length <= 0 ||
              !startPointSubject ||
              !finalPointSubject ||
              warehouseAlert
            }
          >
            Inverti ordine
          </Button>
          <Button
            disabled={
              groupedDeliveries.length <= 0 ||
              !startPointSubject ||
              !finalPointSubject ||
              warehouseAlert
            }
            onClick={autoOrder}
          >
            Ordina auto
          </Button>
          <Button onClick={saveRoute} disabled={saveButtonDisabled}>
            Salva
          </Button>
        </ButtonGroup>
      </Box>
      <Box display="flex">
        <TableContainer style={{ flexGrow: 1 }}>
          <TableGroupedDeliveries
            onOrdering={reorderGroupedDeliveries}
            onDeleteItem={deleteDeliveries}
            data={groupedDeliveries}
            endPoint={props.finalPointSubject as Subject}
            pointsDistanceMapping={pointsDurationMapping}
            startDate={startDate}
            endDate={props.endDate}
            setCanSaveForEta={setCanSaveForEta}
            canSaveForEta={canSaveForEta}
          />
        </TableContainer>
      </Box>
    </Drawer >
  );
};
