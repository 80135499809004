import React, { useEffect } from "react";
import {
  ArrayField,
  Datagrid,
  DatagridProps,
  DateField,
  FunctionField,
  NumberField,
  SelectField,
  TextField,
} from "react-admin";
import { CachetField } from "../../../components/CachetField";
import { DeliveryField } from "../../../components/DeliveryField";
import { DriverField } from "../../../components/DriverField";
import { SubjectField } from "../../../components/SubjectField";
import { DeliveryTypeField } from "../../../components/DeliveryTypeField";
import { useOptions } from "../../../shared/hooks/useOptions";
import Typography from "@material-ui/core/Typography";


export type CachetPaymentDatagridProps = DatagridProps & {};

export const CachetPaymentDatagrid = (props: CachetPaymentDatagridProps) => {

  const { data: optionsDelivery } = useOptions("deliveries", "GET");

  return (
    <Datagrid {...props} isRowSelectable={(r: any) => { return r && r.remittance === null }}>
      <NumberField source="id" label="ID" />
      <CachetField source="cachet" label="Contrassegno" />
      <DeliveryTypeField
        source="delivery_type" sortBy="cachet__delivery__delivery_type" options={optionsDelivery} label={"Tipo"} />
      <DeliveryField deliverySource={"delivery.id"} label="Missione" noTitle={true} />
      <ArrayField source="delivery.routes" label="Autista/Corriere -&gt; Giro" sortable={false}>
        <Datagrid header={<></>}>
          <FunctionField
            render={(record: any) => {
              return record && record.route ? (
                <Typography variant="body2">{
                  record.driver ?
                    (record.driver.surname + " " + record.driver.name) :
                    record.carrier
                } -&gt; {record.route} </Typography>
              ) : null
            }
            }
          />
        </Datagrid>
      </ArrayField>
      <TextField source="delivery.document_number" label="N. documento" sortBy="cachet__delivery__document_number" />
      <DateField source="delivery.document_date" label="Data documento" sortBy="cachet__delivery__document_date" />
      <SubjectField source="delivery.client" label="Cliente" />
      <SelectField
        choices={[
          { value: "CO", display_name: "Contanti" },
          { value: "AS", display_name: "Assegno" },
          { value: "CC", display_name: "Carta di credito" },
          { value: "AB", display_name: "Abbuono" },
        ]}
        source="type"
        label="Tipo incasso"
        optionText="display_name"
        optionValue="value"
      />
      <NumberField source="total" label={"Totale"} />
      <DateField label="Data di inserimento" source="created_date" />
      <DateField label="Data incasso" source="collection_date" />
      <TextField source="user_name" label="Utente" />
      <TextField source="cachet_box" label="Cassa" />
      <TextField source="note" label="Note" />
      <FunctionField
        label="Rimessa"
        render={(record: any) =>
          record.remittance ? (
            <span onClick={(e) => e.stopPropagation()}>
              <a
                href={`/remittances?displayedFilters={"id"%3Atrue}&filter={"id"%3A${record.remittance}}&order=DESC&page=1&perPage=50&sort=id`}
                target="_blank"
              >
                {record.remittance}
              </a>
            </span>
          ) : null
        }
      />
    </Datagrid>
  );
};
