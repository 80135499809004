import { useLeafletContext } from "@react-leaflet/core";
import L, { LatLng } from "leaflet";
import { useEffect, useRef } from "react";
import "leaflet-routing-machine";
import { CREATE_ROUTE_API } from "../../constants";
import { useNotify } from "react-admin";

interface Props {
  points: L.LatLng[];
  onRoutesFound?: (distance: number, timeInSeconds: number, onRoutePointsFound: any[]) => void;
  color?: string;
  onRouteClick?: () => void;
}


class OSRMNEW extends L.Routing.OSRMv1 {
  polyline = require('@mapbox/polyline');
  osrmTextInstructions = require('osrm-text-instructions')('v5');

  options: any = {
    serviceUrl: CREATE_ROUTE_API,
    profile: 'driving',
    timeout: 30 * 1000,
    routingOptions: {
      alternatives: true,
      steps: true
    },
    requestParameters: { continue_straight: "false" },
    polylinePrecision: 5,
    useHints: false,
    suppressDemoServerWarning: false,
    language: 'it'
  }

  _hints: any = {
    locations: {}
  };

  constructor(options: any) {
    super(options);
  }

  /*
  _routeDone(response: any, inputWaypoints: any, options: any, callback: any, context: any) {
    super._routeDone(response, inputWaypoints, options, callback, context);
  }
 
  */

  _camelCase(s: any) {
    var words = s.split(' '),
      result = '';
    for (var i = 0, l = words.length; i < l; i++) {
      result += words[i].charAt(0).toUpperCase() + words[i].substring(1);
    }

    return result;
  }

  _leftOrRight(d: any) {
    return d.indexOf('left') >= 0 ? 'Left' : 'Right';
  }

  _decodePolyline(routeGeometry: any) {
    var cs = this.polyline.decode(routeGeometry, this.options.polylinePrecision),
      result = new Array(cs.length),
      i;
    for (i = cs.length - 1; i >= 0; i--) {
      result[i] = L.latLng(cs[i]);
    }

    return result;
  }

  _maneuverToInstructionType(maneuver: any, lastLeg: any) {
    switch (maneuver.type) {
      case 'new name':
        return 'Continue';
      case 'depart':
        return 'Head';
      case 'arrive':
        return lastLeg ? 'DestinationReached' : 'WaypointReached';
      case 'roundabout':
      case 'rotary':
        return 'Roundabout';
      case 'merge':
      case 'fork':
      case 'on ramp':
      case 'off ramp':
      case 'end of road':
        return this._camelCase(maneuver.type);
      // These are all reduced to the same instruction in the current model
      //case 'turn':
      //case 'ramp': // deprecated in v5.1
      default:
        return this._camelCase(maneuver.modifier);
    }
  }

  _maneuverToModifier(maneuver: any) {
    var modifier = maneuver.modifier;

    switch (maneuver.type) {
      case 'merge':
      case 'fork':
      case 'on ramp':
      case 'off ramp':
      case 'end of road':
        modifier = this._leftOrRight(modifier);
    }

    return modifier && this._camelCase(modifier);
  }

  _bearingToDirection(bearing: any) {
    var oct = Math.round(bearing / 45) % 8;
    return ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'][oct];
  }

  _toWaypoints(inputWaypoints: any, vias: any) {
    var wps = [],
      i,
      viaLoc;
    for (i = 0; i < vias.length; i++) {
      viaLoc = vias[i].location;
      wps.push(new L.Routing.Waypoint(L.latLng(viaLoc[1], viaLoc[0]),
        inputWaypoints[i].name,
        inputWaypoints[i].options));
    }

    return wps;
  }

  _locationKey(location: any) {
    return location.lat + ',' + location.lng;
  }

  _saveHintData(actualWaypoints: any, waypoints: any) {
    var loc;
    this._hints = {
      locations: {}
    } as any;
    for (var i = actualWaypoints.length - 1; i >= 0; i--) {
      loc = waypoints[i].latLng;
      this._hints.locations[this._locationKey(loc)] = actualWaypoints[i].hint;
    }
  }

  _routeDone(response: any, inputWaypoints: any, options: any, callback: any, context: any) {
    var alts = [],
      actualWaypoints,
      i,
      route: any;

    context = context || callback;
    if (response.code !== 'Ok') {
      callback.call(context, {
        status: response.code
      });
      return;
    }

    actualWaypoints = this._toWaypoints(inputWaypoints, response.waypoints);

    for (i = 0; i < response.routes.length; i++) {
      route = this._convertRoute(response.routes[i]);
      route.inputWaypoints = inputWaypoints;
      route.waypoints = actualWaypoints;
      route.properties = { isSimplified: !options || !options.geometryOnly || options.simplifyGeometry };
      alts.push(route);
    }

    this._saveHintData(response.waypoints, inputWaypoints);

    callback.call(context, null, alts);
  }

  _convertRoute(responseRoute: any) {
    var result = {
      responseComplete: responseRoute,
      name: '',
      coordinates: [] as any[],
      instructions: [] as any[],
      summary: {
        totalDistance: responseRoute.distance,
        totalTime: responseRoute.duration
      },
      waypointIndices: [] as any[],
    },
      legNames = [],
      waypointIndices = [] as any[],
      index = 0,
      legCount = responseRoute.legs.length,
      hasSteps = responseRoute.legs[0].steps.length > 0,
      i,
      j,
      leg,
      step,
      geometry,
      type,
      modifier,
      text,
      stepToText;

    if (this.options.stepToText) {
      stepToText = this.options.stepToText;
    } else {
      stepToText = L.bind(this.osrmTextInstructions.compile, this.osrmTextInstructions, this.options.language);
    }
    for (i = 0; i < legCount; i++) {
      leg = responseRoute.legs[i];
      legNames.push(leg.summary && leg.summary.charAt(0).toUpperCase() + leg.summary.substring(1));
      for (j = 0; j < leg.steps.length; j++) {
        step = leg.steps[j];
        geometry = this._decodePolyline(step.geometry);
        result.coordinates.push.apply(result.coordinates, geometry);
        type = this._maneuverToInstructionType(step.maneuver, i === legCount - 1);
        modifier = this._maneuverToModifier(step.maneuver);
        text = stepToText(step, { legCount: legCount, legIndex: i });

        if (type) {
          if ((i == 0 && step.maneuver.type == 'depart') || step.maneuver.type == 'arrive') {
            waypointIndices.push(index);
          }

          result.instructions.push({
            type: type,
            distance: step.distance,
            time: step.duration,
            road: step.name,
            direction: this._bearingToDirection(step.maneuver.bearing_after),
            exit: step.maneuver.exit,
            index: index,
            mode: step.mode,
            modifier: modifier,
            text: text,
          });
        }

        index += geometry.length;
      }
    }

    result.name = legNames.join(', ');
    if (!hasSteps) {
      result.coordinates = this._decodePolyline(responseRoute.geometry);
    } else {
      result.waypointIndices = waypointIndices;
    }

    return result;
  }



  route(waypoints: L.Routing.Waypoint[], callback: (args?: any) => void, context?: {}, options?: L.Routing.RoutingOptions) {
    super.route(waypoints, callback, context, options);
  }


  buildRouteUrl(waypoints: L.Routing.Waypoint[], options: L.Routing.RoutingOptions) {
    return super.buildRouteUrl(waypoints, options);
  }
  requiresMoreDetail(route: { inputWaypoints: L.Routing.Waypoint, waypoints: L.Routing.Waypoint, properties?: any }, zoom: any, bounds: LatLng[]) {
    return super.requiresMoreDetail(route, zoom, bounds)
  };

}

export function Routing({ points, onRoutesFound, color, onRouteClick }: Props) {
  const context = useLeafletContext();
  const routingControl = useRef<any>();
  const notify = useNotify();
  useEffect(() => {
    const map = context.map;

    routingControl.current = L.Routing.control({
      routeLine: function (route) {
        const line = L.routing.line(route, {
          styles: [
            {
              color: color ? color : "black",
              opacity: 1,
              weight: 5,
            },
          ],
          extendToWaypoints: false,
          missingRouteTolerance: 1,
          addWaypoints: false,
        });
        line.eachLayer(function (l) {
          l.on("click", function (e) {
            onRouteClick && onRouteClick();
          });
        });
        return line;
      },
      plan: new L.Routing.Plan([], {
        createMarker: () =>
          new L.Marker([0, 0], {
            icon: new L.DivIcon({ className: "d-none" }),
          }),
      }),
      fitSelectedRoutes: false,
      waypoints: [],
      alternativeClassName: "d-none",
      itineraryClassName: "d-none",
      router: new OSRMNEW({}),
    })
      .on("routesfound", function (e) {
        const routes = e.routes;
        const summary = routes[0].summary;
        let points_mapping: any[] = routes[0].responseComplete["points_mapping"];

        onRoutesFound &&
          onRoutesFound(
            Math.round(summary.totalDistance / 1000),
            summary.totalTime,
            points_mapping
          );
      })
      .on("routingerror", function (e) {
        notify("Errore nel calcolo del tracciato", "warning");
      });

    map.addControl(routingControl.current);

    return () => {
      routingControl.current && routingControl.current.setWaypoints([]);
      routingControl.current &&
        map &&
        map.hasLayer(routingControl.current) &&
        map.removeControl(routingControl.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.map]);

  useEffect(() => {
    routingControl && routingControl.current.setWaypoints(points);
  }, [points]);

  return null;
}
