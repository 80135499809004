import { DatagridProps, SimpleFormProps } from "react-admin";

export interface LoginPayload {
  username: string;
  password: string;
}

export interface UpdatePayload<T> {
  newValues: T;
  id: number;
}

export interface ProvinceRegione {
  id: number;
  label: string;
}

export interface Province {
  codice_provincia: number;
  name: string;
  codice_targa: string;
}
export interface TermsDescription {
  description: string;
  end_of_month: boolean;
  invoice_date: boolean;
  days?: string | null;
}

export interface TermsPayment {
  terms?: TermsDescription;
  terms_id?: number | null;
  method: string;
}

export interface SubjectOpening {
  id: number;
  day: string;
  hour_from: string;
  hour_to: string;
  last_update: Date;
}

export interface Subject {
  id: number;
  first_name: string;
  surname: string;
  main_subject?: Subject | null;
  main_subject_id?: number;
  back_point?: Subject | null;
  back_point_id?: number;
  type: string[];
  business_name: string;
  address: string;
  postal_code: string;
  locality: string;
  province: string;
  latitude: number;
  longitude: number;
  altitude?: number;
  nation: string;
  vat_number?: string;
  fiscal_code?: string;
  phone_number?: string;
  fax?: string;
  email?: string;
  external_reference?: string;
  business_name_print?: string;
  cod_sdi?: string;
  pec?: string;
  payment_terms?: TermsPayment | null;
  payment_terms_id?: number;
  specification_notes: SpecificationNote[];
  starting_point?: Subject | null;
  starting_point_id?: number;
  to_be_tractioned?: boolean;
  internal_notes?: boolean;
  partial?: boolean;
  subjectopening_set?: SubjectOpening[];
}
export interface SpecificationNote {
  id?: number;
  client?: number;
  type: string;
  notes: string;
}
export enum DeliveryType {
  NORMAL = "NO",
  WITHDRAW = "RI",
  RETURN = "RC",
  BACK = "BA",
  PLACEHOLDER = "PH",
}

export enum ChargeType {
  NORMAL = "NO",
  WITHDRAW = "RI",
  RETURN = "RC",
  BACK = "BA",
  CACHET = "CO",
  TRACTION = "TR",
  SAME_DAY = "SD",
  INSURANCE = "AS",
  STOCK = "GI",
}
export enum TariffType {
  SALE = "S",
  PURCHASE = "P",
}
export interface Delivery {
  id: number;
  document_number?: string;
  document_date?: Date;
  first_delivery_reference?: string;
  second_delivery_reference?: string;
  protocol_reference?: string;
  address?: string;
  postal_code?: string;
  locality?: string;
  province?: string;
  weight?: number;
  volume?: number;
  value?: number;
  packages_number?: number;
  status?: string;
  sequence?: number;
  client?: null | Subject;
  client_id: number | null;
  sender?: null | Subject;
  sender_id: number | null;
  receiver?: null | Subject;
  starting_point?: null | Subject;
  starting_point_id: number | null;

  receiver_id: number | null;
  parent_delivery?: Delivery | null;
  tracking_number?: string;
  delivery_type: DeliveryType;

  route_id: number | null;
  packages?: Package[];
  hide: boolean;
  notes?: string;
  partial?: boolean;
  bill_sent?: Date | null;
  evasion_date?: Date;
  delivery_date?: Date;
  editable?: boolean;
  note_set?: {
    id?: number;
    file?: string | boolean;
    json?: string;
    notes?: string;
    sent?: Date;
    created_date?: Date;
    last_update?: Date;
  }[];
  import_file?: number;
  cachet_set?: Cachet[];
}
export interface Item {
  id?: number;
  description: string;
  code: string;
}
export interface Driver {
  id?: number;
  name: string;
  surname: string;
  gender: "male" | "female";
  date_of_birth: Date | null;
  phone_number: string | null;
  license: string;
  device: string | null;
  fuel_card_number?: number | null;
}

export interface Vehicle {
  id?: number;
  description: string;
  plate: string;
  status: string;
  pallets: number;
  size?: number;
  max_weigth?: number;
  max_items?: number;
  owner?: string;
  notes?: string;
  default_driver?: Driver | null;
  driver_id?: number | null;
}

export interface Price {
  id?: number;
  tariff_type: string;
  supplier?: null | Subject;
  supplier_id?: number | null;
  client?: null | Subject;
  client_id?: number | null;
  charge_type: string;
  item?: null | Item;
  item_id: number;
  start_date: Date;
  price: number;
  quantity_type: string;
  minimum_price: number;
  notes?: string;
}
export interface Note {
  id?: number;
  delivery?: Delivery;
  delivery_id?: number | null;
  final?: boolean;
  file?: string | boolean;
  json?: string;
  notes?: string;
  sent?: Date;
  created_date?: Date;
  last_update?: Date;
}

export interface Package {
  id?: number;
  price?: null | Price;
  price_id: number | null;
  delivery_id: number;
  items_number: number;
  quantity: number;
}
export interface SubjectNotFound {
  not_found_subject: {
    id: number | string;
    business_name: string;
    delivery__address: string;
    delivery__locality: string;
    delivery__province: string;
    delivery__postal_code: string;
  };
  results: Partial<Subject>[];
}

export interface Log {
  history_date: Date;
  history_user: string;
  history_type: string;
  changed_fields: string[];
}


export interface Cachet {
  id?: number;
  total?: number;
  cash?: number;
  rebate?: number;
  cashed?: number;
  residue?: number;
  check?: number;
  credit_card?: number;
  collection_date?: Date;
  note?: string;
  verified?: boolean;
  last_update?: Date;
  created_date?: Date;
}

export type DatagridPropsWithOptions = { options: any } & DatagridProps;

export type FormProps = Omit<SimpleFormProps, "children"> & {
  canDelete?: boolean;
  canEdit?: boolean;
  fieldOptions?: any;
};

export enum DeliveryStatusChoice {
  TO_PLAN = "I",
  PLANNED = "G",
  STOCKED = "S",
  STOCKED_END = "SE",
  LOAD = "L",
  DELIVERING = "ID",
  DELIVERED = "D",
  SUSPENDED = "SU",
  CANCELED = "CA",
  TRANSITING = "T",
}


export interface ReasonStatus {
  id: number;
  status: any;
  description: string;
  fault: boolean;
}