import { DeliveryGroup, Segment } from "./types";
import { LatLngExpression } from "leaflet";
import randomColor from "randomcolor";
import L from "leaflet";
import { DeliveryType, Subject } from "../shared/types";
import { ca, de, tr } from "date-fns/locale";
import { Typography } from "@material-ui/core";
import { start } from "repl";
export function randomColorExcludeBlack(length: number) {
  let colors = randomColor({ luminosity: "light", count: length });
  if (colors.includes("#000000")) randomColorExcludeBlack(length);
  return colors;
}

export function keyToLatLon(values: string) {
  return values
    .split(",")
    .map((value) => parseFloat(value)) as LatLngExpression;
}

export const getKeyLatLon = (latitude: number, longitude: number) =>
  `${latitude},${longitude}`;

export const getKeyStore = (id: number | string | undefined) => {
  return `${id}-store`;
};
export const getKeyEndStore = (id: number | string | undefined) => {
  return `${id}-endstore`;
};

export const getGroupKey = (segment: Segment) => {
  return `${segment.arrival_point.id}-${segment.starting_point.id}`;
};
export const getGroupEndKey = (segment: Segment) => {
  return `${segment.starting_point.id}-${segment.arrival_point.id}`;
};

export const getAddedSegments = (
  groupedDeliveries: DeliveryGroup[],
  addedSegments: Segment[]
) => {
  return groupedDeliveries.reduce((deliveries, group, index) => {
    if (
      (!group.isPickUpPoint && group.delivery_type !== DeliveryType.WITHDRAW) ||
      (group.isPickUpPoint && (group.delivery_type === DeliveryType.WITHDRAW))
    ) {
      const orderStartingPoint =
        groupedDeliveries.findIndex((item: DeliveryGroup) => {
          return (
            (
              (item.isPickUpPoint && group.delivery_type !== DeliveryType.WITHDRAW && getKeyStore(group.startingPointId) === item.id) ||
              (item.isPickUpPoint && group.delivery_type === DeliveryType.WITHDRAW && group.id === item.id)
            )
          );
        }) + 1;
      deliveries.push(
        ...addedSegments
          .filter(
            (segment) => {
              return (segment.delivery_type !== DeliveryType.WITHDRAW &&
                segment.arrival_point.id === group.receiverId &&
                segment.starting_point.id === group.startingPointId) ||
                (
                  (segment.delivery_type === DeliveryType.WITHDRAW) &&
                  segment.arrival_point.id === group.startingPointId &&
                  segment.starting_point.id === group.receiverId)
            }
          )
          .map((item) => ({
            ...item,
            sequence_starting_point: orderStartingPoint,
            sequence: index + 1,
          }))
      );
    }
    return deliveries;
  }, [] as Partial<Segment>[]);
};

export const sortDeliveryGroupForSequence = (
  a: any & { sequence: number },
  b: any & { sequence: number }
) => {
  if (a.sequence < b.sequence) {
    return -1;
  }
  if (a.sequence > b.sequence) {
    return 1;
  }
  return 0;
};

export const getSegmentsGrouped = (addedSegments: Segment[], endPointId?: number, startPointId?: number, startDate?: Date) => {
  let validDate = true;
  if (typeof startDate === 'string' || startDate instanceof String) {
    startDate = new Date(startDate);
  }
  try {
    if (!startDate || isNaN(startDate.getTime())) validDate = false;
  }
  catch {
    validDate = false;
  }
  if ((!startPointId || !endPointId || !validDate) && addedSegments.length > 0) {
    alert("Seleziona prima punti di partenza, arrivo e ora inizio.")
    return [];
  }
  let tableData: DeliveryGroup[] = [];
  for (const segment of addedSegments) {
    if (segment.delivery_type === DeliveryType.WITHDRAW) {
      const storeKey = getKeyEndStore(segment.arrival_point.id);
      let storeIndex = tableData.findIndex(
        (groupRow) => groupRow.id === storeKey
      );
      const startingPoint = segment.starting_point;
      const delivery = segment.delivery;
      const arrivalPoint = segment.arrival_point;
      const id = getGroupEndKey(segment);
      const groupRowIndex = tableData.findIndex((groupRow) => groupRow.id === id);
      if (groupRowIndex !== -1) {
        const groupRow = { ...tableData[groupRowIndex] };
        groupRow.weight! += delivery.weight!;
        groupRow.packages_number! += delivery.packages_number!;
        groupRow.sequence =
          segment.sequence &&
            segment.route_id &&
            segment.sequence > groupRow.sequence
            ? segment.sequence
            : groupRow.sequence;
        tableData.splice(groupRowIndex, 1, groupRow);
      } else {
        let newSequence = segment.sequence_starting_point;
        if (segment.sequence_starting_point === 0 && !segment.route_id) {
          try {
            newSequence = tableData[tableData.length - 1].sequence + 1;
          }
          catch {
            newSequence = 0
          }
        }
        tableData.push({
          main_id: segment.id,
          id: id,
          receiver: startingPoint.business_name,
          receiverId: startingPoint.id,
          sequence: newSequence,
          weight: delivery.weight,
          packages_number: delivery.packages_number,
          address: `${startingPoint.address} ${startingPoint.locality}, ${startingPoint.postal_code} ${startingPoint.province}`,
          longitude: startingPoint.longitude,
          latitude: startingPoint.latitude,
          isPickUpPoint: true,
          startingPointId: arrivalPoint.id,
          startingPointLatitude: arrivalPoint.latitude,
          startingPointLongitude: arrivalPoint.longitude,
          isDisabled: false,
          delivery_type: segment.order_on_delivery > 0 ? DeliveryType.NORMAL : segment.delivery_type,
          subjectopening_set: startingPoint.subjectopening_set,
          notes: segment.operative_notes
        });
      }
      if (storeIndex === -1) {
        tableData.push({
          main_id: segment.id,
          id: storeKey,
          receiver: arrivalPoint.business_name,
          receiverId: arrivalPoint.id,
          sequence:
            (segment.sequence === 0 || !segment.route_id) &&
              tableData.length > 0
              ? tableData[tableData.length - 1].sequence
              : segment.sequence,
          address: `${arrivalPoint.address} ${arrivalPoint.locality}, ${arrivalPoint.postal_code} ${arrivalPoint.province}`,
          longitude: arrivalPoint.longitude,
          latitude: arrivalPoint.latitude,
          startingPointId: startingPoint.id,
          startingPointLatitude: startingPoint.latitude,
          startingPointLongitude: startingPoint.longitude,
          isPickUpPoint: false,
          isDisabled: false,
          delivery_type: segment.order_on_delivery > 0 ? DeliveryType.NORMAL : segment.delivery_type,
          subjectopening_set: arrivalPoint.subjectopening_set,
          notes: segment.operative_notes
        });
      }
    }
    else {
      const storeKey = getKeyStore(segment.starting_point.id);
      let storeIndex = tableData.findIndex(
        (groupRow) => groupRow.id === storeKey
      );
      const startingPoint = segment.starting_point;
      const delivery = segment.delivery;
      const arrivalPoint = segment.arrival_point;
      if (storeIndex === -1) {
        tableData.push({
          main_id: segment.id,
          id: storeKey,
          receiver: startingPoint.business_name,
          receiverId: startingPoint.id,
          sequence:
            (segment.sequence_starting_point === 0 || !segment.route_id) &&
              tableData.length > 0
              ? tableData[tableData.length - 1].sequence
              : segment.sequence_starting_point,
          address: `${startingPoint.address} ${startingPoint.locality}, ${startingPoint.postal_code} ${startingPoint.province}`,
          longitude: startingPoint.longitude,
          latitude: startingPoint.latitude,
          isPickUpPoint: true,
          isDisabled: false,
          delivery_type: segment.order_on_delivery > 0 ? DeliveryType.NORMAL : segment.delivery_type,
          subjectopening_set: startingPoint.subjectopening_set,
          notes: segment.operative_notes
        });
        storeIndex = tableData.length - 1;
      }
      const id = getGroupKey(segment);
      const groupRowIndex = tableData.findIndex((groupRow) => groupRow.id === id);
      if (groupRowIndex !== -1) {
        const groupRow = { ...tableData[groupRowIndex] };
        groupRow.weight! += delivery.weight!;
        groupRow.packages_number! += delivery.packages_number!;
        if (!groupRow.notes) groupRow.notes = segment.operative_notes;
        else if (segment.operative_notes) groupRow.notes += " " + segment.operative_notes;
        groupRow.sequence =
          segment.sequence &&
            segment.route_id &&
            segment.sequence > groupRow.sequence
            ? segment.sequence
            : groupRow.sequence;
        tableData.splice(groupRowIndex, 1, groupRow);
      } else {
        let newSequence = segment.sequence;
        if (segment.sequence === 0 && !segment.route_id) {
          newSequence = tableData[tableData.length - 1].sequence + 1;
        }
        tableData.push({
          main_id: segment.id,
          id: id,
          receiver: arrivalPoint.business_name,
          receiverId: arrivalPoint.id,
          sequence: newSequence,
          weight: delivery.weight,
          packages_number: delivery.packages_number,
          address: `${arrivalPoint.address} ${arrivalPoint.locality}, ${arrivalPoint.postal_code} ${arrivalPoint.province}`,
          longitude: arrivalPoint.longitude,
          latitude: arrivalPoint.latitude,
          isPickUpPoint: false,
          startingPointId: startingPoint.id,
          startingPointLatitude: startingPoint.latitude,
          startingPointLongitude: startingPoint.longitude,
          isDisabled: false,
          delivery_type: segment.order_on_delivery > 0 ? DeliveryType.NORMAL : segment.delivery_type,
          subjectopening_set: arrivalPoint.subjectopening_set,
          notes: segment.operative_notes
        });
      }
    }

  }

  for (let s of tableData) {
    if (
      (s.delivery_type === DeliveryType.WITHDRAW) &&
      s.isPickUpPoint === false //&& (s.receiverId === endPointId)
    ) {
      s.sequence = tableData[tableData.length - 1].sequence + 1000;
    }
    if (
      (s.delivery_type !== DeliveryType.WITHDRAW) &&
      s.isPickUpPoint === true && (s.receiverId === startPointId)) {
      s.sequence = 0;
    }

  }

  /*
  for (const segment of addedSegments) {
    const storeKey = getKeyStore(segment.starting_point.id);
    let storeIndex = tableData.findIndex(
      (groupRow) => groupRow.id === storeKey
    );
    const startingPoint = segment.starting_point;
    const delivery = segment.delivery;
    const arrivalPoint = segment.arrival_point;
    if (storeIndex === -1) {
      tableData.push({
        main_id: segment.id,
        id: storeKey,
        receiver: startingPoint.business_name,
        receiverId: startingPoint.id,
        sequence:
          (segment.sequence_starting_point === 0 || !segment.route_id) &&
            tableData.length > 0
            ? tableData[tableData.length - 1].sequence
            : segment.sequence_starting_point,
        address: `${startingPoint.address} ${startingPoint.locality}, ${startingPoint.postal_code} ${startingPoint.province}`,
        longitude: startingPoint.longitude,
        latitude: startingPoint.latitude,
        isPickUpPoint: true,
        isDisabled: false,
        delivery_type: segment.order_on_delivery > 0 ? DeliveryType.NORMAL : segment.delivery_type
      });
      storeIndex = tableData.length - 1;
    }
    const id = getGroupKey(segment);
    const groupRowIndex = tableData.findIndex((groupRow) => groupRow.id === id);
    if (groupRowIndex !== -1) {
      const groupRow = { ...tableData[groupRowIndex] };
      groupRow.weight! += delivery.weight!;
      groupRow.packages_number! += delivery.packages_number!;
      groupRow.sequence =
        segment.sequence &&
          segment.route_id &&
          segment.sequence > groupRow.sequence
          ? segment.sequence
          : groupRow.sequence;
      tableData.splice(groupRowIndex, 1, groupRow);
    } else {
      let newSequence = segment.sequence;
      if (segment.sequence === 0 && !segment.route_id) {
        newSequence = tableData[tableData.length - 1].sequence + 1;
      }
      tableData.push({
        main_id: segment.id,
        id: id,
        receiver: arrivalPoint.business_name,
        receiverId: arrivalPoint.id,
        sequence: newSequence,
        weight: delivery.weight,
        packages_number: delivery.packages_number,
        address: `${arrivalPoint.address} ${arrivalPoint.locality}, ${arrivalPoint.postal_code} ${arrivalPoint.province}`,
        longitude: arrivalPoint.longitude,
        latitude: arrivalPoint.latitude,
        isPickUpPoint: false,
        startingPointId: startingPoint.id,
        startingPointLatitude: startingPoint.latitude,
        startingPointLongitude: startingPoint.longitude,
        isDisabled: false,
        delivery_type: segment.order_on_delivery > 0 ? DeliveryType.NORMAL : segment.delivery_type
      });
    }
  }
  if (endPointId) {
    for (let s of tableData) {
      if (
        (s.delivery_type === DeliveryType.WITHDRAW || s.delivery_type === DeliveryType.BACK) &&
        s.isPickUpPoint === false && (s.receiverId === endPointId)) {
        s.sequence = 100000
      }
    }
  }
  */
  tableData.sort(sortDeliveryGroupForSequence);
  return tableData;

};

export const getPointsGroupedDeliveries = (
  groupedDeliveries: DeliveryGroup[],
  startPointSubject?: Subject,
  finalPointSubject?: Subject
) => {
  const points = groupedDeliveries.map((groupedDelivery) =>
    L.latLng(groupedDelivery.latitude, groupedDelivery.longitude)
  );
  if (finalPointSubject && points.length > 0)
    points.push(
      L.latLng(finalPointSubject.latitude, finalPointSubject.longitude)
    );
  if (startPointSubject && points.length > 0)
    points.unshift(
      L.latLng(startPointSubject.latitude, startPointSubject.longitude)
    );
  return points;
};

function openInfoToWeekDayNum(opening: any) {
  return opening.day === "MO" ? 1 : opening.day === "TU" ? 2 : opening.day === "WE" ? 3 : opening.day === "TH" ? 4 : opening.day === "FR" ? 5 : opening.day === "SA" ? 6 : 7
}

function stringTimeToMinute(hour: string) {
  return parseInt(hour.split(":")[0]) * 60 + parseInt(hour.split(":")[1]);
}

export function timeForEta(date?: Date, duration?: number, isOpen?: boolean) {
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  try {
    if (!date || isNaN(date.getTime()) || duration === null || duration === undefined) return <Typography></Typography>;
  }
  catch {
    return <Typography></Typography>;
  }
  const eta = new Date(date.getTime() + duration * 1000);
  let hours = eta.getHours() < 10 ? "0" + eta.getHours() : eta.getHours();
  let minutes = eta.getMinutes() < 10 ? "0" + eta.getMinutes() : eta.getMinutes();
  return (<Typography style={{ fontSize: "1.35em", fontWeight: "bolder", color: isOpen ? "" : "red" }}>{hours + ":" + minutes}</Typography>);
}

export function etaIsGood(date?: Date, duration?: number, subjectopening_set?: any) {
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(date);
  }
  try {
    if (!date || isNaN(date.getTime()) || duration === null || duration === undefined) return true;
  }
  catch {
    return true;
  }
  const eta = new Date(date.getTime() + duration * 1000);
  let isOpen = false;
  if (subjectopening_set && subjectopening_set.length > 0) {
    //set the 00:00 on date
    let today = new Date(date);
    today.setHours(0, 0, 0, 0);
    for (let opening of subjectopening_set) {
      // check if opening is on the same day
      if (openInfoToWeekDayNum(opening) === today.getDay() &&
        today.getTime() + stringTimeToMinute(opening.hour_from) * 60000 <= eta.getTime() &&
        today.getTime() + stringTimeToMinute(opening.hour_to) * 60000 >= eta.getTime()) {
        isOpen = true;
        break;
      }
    }
  }
  else {
    isOpen = true;
  }
  return isOpen;
}
