import { Record as RecordRa } from "react-admin";
import {
  Cluster,
  DeliveryGroup,
  Vehicle,
  Driver,
  Route,
  Segment,
  Tag,
} from "./types";
import { DeliveryType, Subject } from "../shared/types";
import {
  getAddedSegments,
  getKeyEndStore,
  getKeyLatLon,
  getKeyStore,
  getSegmentsGrouped,
  randomColorExcludeBlack,
} from "./utils";
import create from "zustand";
import produce from "immer";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import { el, tr } from "date-fns/locale";

interface DeliveryPlannerState {
  subjectByCoordsMap: { [key: string]: Subject };
  colors: string[];
  addedSegments: Segment[];
  segments_done: number[];
  clusters: Cluster;
  activeClusterKey?: string;
  groupedDeliveries: DeliveryGroup[];
  editMode: boolean;
  vehicle: Vehicle | undefined;
  carrier: Subject | undefined;
  driver: Driver | undefined;
  routes: Route[];
  selectedRoute: Route | undefined;
  tags: Tag[];
  finalPointId?: number;
  startPointId?: number;
  startDate: Date;
  warehouseAlert: boolean;
  //   startingPoints: Subject[];
  onActiveRouteChange: (
    editMode: boolean,
    selectedRoute: Route | undefined
  ) => void;
  toggleEditMode: (callback?: (routes: Route[]) => void) => void;
  toggleSegment: (segment: Segment) => void;
  deleteDeliveries: (
    startingPointId: string | number,
    receiverId: string | number
  ) => void;

  addDeliveries: (clusterKey: string) => void;
  initPlanner: (segments: Segment[]) => void;
  setWarehouseAlert: (segments: Segment[]) => void;
  setActiveClusterKey: (key: string) => void;
  resetActiveClusterKey: () => void;
  reorderGroupedDeliveries: (dragIndex: number, hoverIndex: number) => void;
  updateSelectedCarrier: (carrier: RecordRa | undefined) => void;
  updateSelectedDriver: (driver: RecordRa | undefined) => void;
  updateTags: (tags: Tag[]) => void;
  updateFinalPointId: (id: number | undefined) => void;
  updateStartDate: (date: Date) => void;
  updateStartPointId: (id: number | undefined) => void;
  resetCarrierDriverVehicle: () => void;
  updateSelectedVehicle: (driver: RecordRa | undefined) => void;
  updateSelectedRoute: (
    routeId: number | undefined,
    routes: RecordRa[]
  ) => void;
  toggleSelectedRoutes: (
    route: RecordRa,
    callback?: (routes: Route[]) => void
  ) => void;
  groupDeliveries: (addedDeliveries: any) => void;
  setSelectedRoutes: (
    routes: RecordRa[],
    callback?: (routes: RecordRa[]) => void
  ) => void;
  addMultipleKeys: (clusterKeys: string[]) => void;
  deleteSegments: (segments: Segment[]) => void;
}
const checkIfEqual = (value: Segment, other: Segment) => value.id === other.id;
const updateClusterIfnotThere = (
  clusterGroup: Cluster,
  point: Subject,
  key: string,
  subjectsMap: { [key: string]: Subject },
  segment: Segment
) => {
  if (!(key in clusterGroup)) {
    clusterGroup[key] = [];
  }
  clusterGroup[key].push(segment);

  if (!(key in subjectsMap)) {
    subjectsMap[key] = point;
  }
};
export const useStore = create<DeliveryPlannerState>(
  devtools((set, get) => {
    return {
      subjectByCoordsMap: {},
      tags: [],
      finalPointId: undefined,
      startPointId: undefined,
      segments_done: [],
      addedSegments: [],
      //   startingPoints: [],
      first_opening: true,
      editMode: false,
      vehicle: undefined,
      clusters: {},
      groupedDeliveries: [],
      activeClusterKey: undefined,
      colors: [],
      carrier: undefined,
      driver: undefined,
      routes: [],
      selectedRoute: undefined,
      startDate: new Date(""),
      warehouseAlert: false,
      toggleEditMode: (callback) =>
        set(
          produce((state) => {
            if (state.first_opening) {
              state.first_opening = false;
              state.editMode = true;
            }
            else {
              state.editMode = !state.editMode;
              state.first_opening = false;
            }
            if (!state.editMode) {
              state.carrier = undefined;
              state.driver = undefined;
              state.vehicle = undefined;
              state.routes = [];
              state.selectedRoute = undefined;
              state.tags = [];
              callback && callback([]);
            }
            state.startDate = undefined;
          })
        ),
      setWarehouseAlert: (segments: Segment[]) => {
        let startPoints: any = []
        for (let i = 0; i < segments.length; i++) {
          if (segments[i].delivery_type === DeliveryType.NORMAL) {
            if (!startPoints.includes(segments[i].starting_point.id))
              startPoints.push(segments[i].starting_point.id)
          }
        }
        set(
          produce((state) => {
            state.warehouseAlert = startPoints.length > 1 || (startPoints.length === 1 && startPoints[0] !== get().startPointId)
          })
        );
      },
      resetCarrierDriverVehicle: () =>
        set(
          produce((state) => {
            state.startDate = undefined;
            state.carrier = undefined;
            state.driver = undefined;
            state.vehicle = undefined;
            state.tags = [];
          })
        ),
      updateSelectedCarrier: (carrier) =>
        set(
          produce((state) => {
            state.carrier = carrier as Subject;
            state.driver = undefined;
            state.vehicle = undefined;
          })
        ),
      updateSelectedDriver: (driver) =>
        set(
          produce((state) => {
            state.driver = driver as Driver;
            state.carrier = undefined;
          })
        ),
      updateStartDate: (date) =>
        set(
          produce((state) => {
            state.startDate = date;
          })
        ),
      updateTags: (tags) =>
        set(
          produce((state) => {
            state.tags = tags;
          })
        ),
      updateFinalPointId: (id) =>
        set(
          produce((state) => {
            state.finalPointId = id;
          })
        ),
      updateStartPointId: (id) => {
        set(
          produce((state) => {
            state.startPointId = id;
          })
        )
        get().setWarehouseAlert(get().addedSegments)

      },
      updateSelectedRoute: (routeId, routes) => {
        set(
          produce((state) => {
            state.selectedRoute = routes.find((route) => route.id! === routeId);
            if (state.selectedRoute) {
              state.vehicle = state.selectedRoute.vehicle;
              state.carrier = state.selectedRoute.carrier;
              state.driver = state.selectedRoute.driver;
              state.finalPointId = state.selectedRoute.final_point_id;
              state.startPointId = state.selectedRoute.start_point_id;
              state.startDate = state.selectedRoute.planned_date;
              state.tags = state.selectedRoute.tags;
            }
          })
        )
        get().setWarehouseAlert(get().addedSegments)
      },
      setSelectedRoutes: (routes, callback) => {
        set(
          produce((state) => {
            state.routes = routes;
            state.colors = randomColorExcludeBlack(state.routes.length);
          })
        );
        callback && callback(routes);
      },
      toggleSelectedRoutes: (route, callback) => {
        const selecedRoutes = get().routes;
        const exists = selecedRoutes.find(
          (oldRoute) => oldRoute.id === route.id
        );
        const newSelectedRoutes = exists
          ? selecedRoutes.filter((oldRoute) => oldRoute.id !== route.id)
          : [...selecedRoutes, route as Route];
        set(
          produce((state) => {
            state.routes = newSelectedRoutes;
            state.colors = randomColorExcludeBlack(state.routes.length);
          })
        );
        callback && callback(newSelectedRoutes);
        get().setWarehouseAlert(get().addedSegments)
      },
      updateSelectedVehicle: (vehicle) =>
        set(
          produce((state) => {
            state.vehicle = vehicle as Vehicle;
            if (state.vehicle.default_driver && !state.driver) {
              state.driver = state.vehicle.default_driver;
              state.carrier = undefined;
            }
          })
        ),
      reorderGroupedDeliveries: (dragIndex, hoverIndex) => {
        const groupedDeliveries = [...get().groupedDeliveries];
        const delivery = { ...groupedDeliveries[dragIndex] }; //trasportando
        if (delivery.delivery_type !== DeliveryType.WITHDRAW) {
          if (delivery.isPickUpPoint === false) {
            const startingPointIndex = groupedDeliveries.findIndex(
              (deliveryCompare) => {
                return (
                  deliveryCompare.id === getKeyStore(delivery.startingPointId!)
                );
              }
            );
            if (startingPointIndex >= hoverIndex) {
              return;
            }
          } else {
            const tempGroupedDeliveries = [...groupedDeliveries];
            tempGroupedDeliveries.splice(dragIndex, 1);
            tempGroupedDeliveries.splice(hoverIndex, 0, delivery);
            const check = tempGroupedDeliveries.some((deliveryTemp, index) => {
              return (
                getKeyStore(deliveryTemp.startingPointId) === delivery.id &&
                index < hoverIndex
              );
            });
            if (check) return;
          }
          groupedDeliveries.splice(dragIndex, 1);
          groupedDeliveries.splice(hoverIndex, 0, delivery);

        }
        else {
          if (delivery.isPickUpPoint === true) {
            const startingPointIndex = groupedDeliveries.findIndex(
              (deliveryCompare) => {
                return (
                  deliveryCompare.id === getKeyEndStore(delivery.startingPointId!)
                );
              }
            );
            if (startingPointIndex <= hoverIndex) {
              return;
            }
          } else {
            const tempGroupedDeliveries = [...groupedDeliveries];
            tempGroupedDeliveries.splice(dragIndex, 1);
            tempGroupedDeliveries.splice(hoverIndex, 0, delivery);
            const check = tempGroupedDeliveries.some((deliveryTemp, index) => {
              let temp_string = delivery.startingPointId?.toString() + "-" + delivery.receiverId?.toString();
              return (
                temp_string === deliveryTemp.id &&
                index > hoverIndex
              );
            });
            if (check) return;
          }
          groupedDeliveries.splice(dragIndex, 1);
          groupedDeliveries.splice(hoverIndex, 0, delivery);
        }


        set(
          produce((state) => {
            state.addedSegments = getAddedSegments(
              groupedDeliveries,
              state.addedSegments
            );
            state.groupedDeliveries = groupedDeliveries;
          })
        );
        get().setWarehouseAlert(get().addedSegments)
      },
      addDeliveries: (clusterKey) => {
        //if (get().startPointId && get().finalPointId) {
        const selectedRoute = get().selectedRoute;
        const segmentsToAdd = get().clusters[clusterKey].filter(
          (segment) =>
            !segment.route_id ||
            (selectedRoute && segment.route_id === selectedRoute.id)
        );
        const newAddedSegments = _.unionWith(
          get().addedSegments,
          segmentsToAdd,
          checkIfEqual
        );
        get().groupDeliveries(newAddedSegments);
        //}
        //else {
        //window.alert("Seleziona il punto di partenza e di arrivo del giro.")
        //}
      },
      addMultipleKeys: (clusterKeys) => {
        const selectedRoute = get().selectedRoute;
        const segmentsToAdd: Segment[] = [];
        const clusters = get().clusters;
        clusterKeys.forEach((clusterKey) => {
          segmentsToAdd.push(
            ...clusters[clusterKey].filter(
              (segment) =>
                !segment.route_id ||
                (selectedRoute && segment.route_id === selectedRoute.id)
            )
          );
        });
        const newAddedSegments = _.unionWith(
          get().addedSegments,
          segmentsToAdd,
          checkIfEqual
        );
        get().groupDeliveries(newAddedSegments);
      },
      deleteSegments: (segments: Segment[]) => {
        const newAddedSegments = _.differenceWith(
          get().addedSegments,
          segments,
          checkIfEqual
        );

        let delete_segment_ids = []
        for (let s of segments) {
          delete_segment_ids.push(s.id)
        }

        //Elimina dai giri durante il modifica giro
        let clusterMod: Cluster = {};
        for (let k in get().clusters) {
          clusterMod[k] = []
          for (let i in get().clusters[k]) {
            const temp = JSON.parse(JSON.stringify(get().clusters[k][i]))
            if (delete_segment_ids.includes(temp.id)) {
              temp.route_id = undefined;
              temp.sequence = 0;
              temp.sequence_starting_point = 0;
            }
            clusterMod[k].push(temp)
          }
        }

        get().groupDeliveries(newAddedSegments);

        set(produce((state) => {
          state.clusters = clusterMod;
        })
        )

      },
      deleteDeliveries: (startingPointId, receiverId) => {
        const addedSegments = get().addedSegments.filter((segment) => {
          return (
            (segment.arrival_point.id === receiverId || receiverId == undefined) &&
            segment.starting_point.id === startingPointId
          );
        });
        get().deleteSegments(addedSegments);
      },
      resetActiveClusterKey: () =>
        set(
          produce((state) => {
            state.activeClusterKey = undefined;
          })
        ),
      setActiveClusterKey: (key) =>
        set(
          produce((state) => {
            state.activeClusterKey = key;
          })
        ),
      toggleSegment: (segment) => {
        const addedSegments = [...get().addedSegments];
        const selectedRoute = get().selectedRoute;
        const indexSegment = addedSegments.findIndex(
          (s) => s.id === segment.id
        );
        if (
          indexSegment === -1 &&
          (!segment.delivery.route_id ||
            (selectedRoute && segment.delivery.route_id === selectedRoute.id))
        ) {
          addedSegments.push(segment);
        } else {
          addedSegments.splice(indexSegment, 1);
        }
        get().groupDeliveries(addedSegments);
      },
      onActiveRouteChange: (editMode, selectedRoute) => {
        let addedSegments: Segment[] = [];
        const clusters = get().clusters;
        if (editMode && selectedRoute) {
          addedSegments = Object.keys(clusters).reduce(
            (activeDeliveries, key) => {
              const deliveries = clusters[key].filter(
                (delivery) => delivery.route_id === selectedRoute.id
              );
              for (let d of deliveries) {
                if (activeDeliveries.filter(e => e === d).length === 0) {
                  activeDeliveries.push(d);
                }
              }
              return activeDeliveries;
            },
            [] as Segment[]
          );
        }

        const groupedDeliveries = getSegmentsGrouped(addedSegments, get().finalPointId, get().startPointId, get().startDate);
        set(
          produce((state) => {
            state.addedSegments = getAddedSegments(
              groupedDeliveries,
              addedSegments
            );
            state.groupedDeliveries = groupedDeliveries;
          })
        );
        get().setWarehouseAlert(get().addedSegments)
      },
      initPlanner: (segments) => {
        const clustersGroup: Cluster = {};
        const subjectsMap: { [key: string]: Subject } = {};
        const startingPoints: Subject[] = [];
        for (const segment of segments) {
          const arrivalPoint = segment.arrival_point;
          const keyArrivalPoint = getKeyLatLon(
            arrivalPoint.latitude,
            arrivalPoint.longitude
          );
          updateClusterIfnotThere(
            clustersGroup,
            arrivalPoint,
            keyArrivalPoint,
            subjectsMap,
            segment
          );

          const startingPoint = segment.starting_point;
          const keyStartingPoint = getKeyLatLon(
            startingPoint.latitude,
            startingPoint.longitude
          );
          updateClusterIfnotThere(
            clustersGroup,
            startingPoint,
            keyStartingPoint,
            subjectsMap,
            segment
          );

          //update the startingPoints add if not there
          if (
            !startingPoints.find(
              (listSubject) => startingPoint.id === listSubject.id
            )
          ) {
            startingPoints.push(startingPoint);
          }
        }
        set(
          produce((state) => {
            state.subjectByCoordsMap = subjectsMap;
            state.activeClusterKey = undefined;
            state.addedSegments = [];
            state.clusters = clustersGroup;
            state.selectedRoute = undefined;
            state.startingPoints = startingPoints;
          })
        );
      },
      groupDeliveries: (addedSegments: Segment[]) => {
        const groupedDeliveries = getSegmentsGrouped(addedSegments, get().finalPointId, get().startPointId, get().startDate);
        set(
          produce((state) => {
            state.addedSegments = getAddedSegments(
              groupedDeliveries,
              addedSegments
            );
            state.groupedDeliveries = groupedDeliveries;
          })
        );
        get().setWarehouseAlert(get().addedSegments)
      },
    };
  })
);
