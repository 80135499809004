// botton crea riconsegna
import { Dialog, DialogContent, DialogActions, List, TableContainer } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import {
  useRecordContext,
  Button,
  Datagrid,
  FunctionField,
  SelectField,
  Record,
  TextField,
  useNotify,
  useRedirect,
  ListContextProvider,
  useList,
  useDataProvider,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import { DeliveryStatusChoice, DeliveryType, Subject } from "../../shared/types";
import { DeliveryField } from "../../components/DeliveryField";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { add } from "lodash";
import { getUser } from "../../authProvider";


export const RouteSegmentMonitorShow = ({ label: string = "" }) => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [address, setAddress] = useState("");
  const user = getUser();

  const completedDeliveryRowStyle = (record: any, index: any) => ({
    backgroundColor:
      [DeliveryStatusChoice.DELIVERED].includes(record.delivery_last_status) ? '#b9feb9' : (
        [
          DeliveryStatusChoice.SUSPENDED,
        ].includes(record.delivery_last_status) ? '#feb9b9' : 'white'),
    display: [
      DeliveryStatusChoice.CANCELED,
      DeliveryStatusChoice.STOCKED,
      DeliveryStatusChoice.STOCKED_END,
    ].includes(record.delivery_last_status) ? "none" : ""
  });

  const [loading, setLoading] = useState(false);
  const { data: optionsDeliveryStatus } = useOptions("delivery-states", "GET");

  for (let i = 0; i < record!.ordered_segments.segments.length; i++) {
    record!.ordered_segments.segments[i].sequence = record!.ordered_segments.ids.indexOf(record!.ordered_segments.segments[i].id) + 1;
  }

  const listContext = useList({
    data: record!.ordered_segments.segments,
    ids: record!.ordered_segments.ids,
    loading: false,
    loaded: true,
    sort: { field: "sequence", order: "ASC" },
  });

  const getSubjectAddress = (
    value: number,
  ) => {
    if (value && user.permissions.includes("registry.view_subject")) {
      dataProvider
        .getOne<Subject>("subjects", {
          id: value,
        })
        .then(({ data: subject }) => {
          setAddress(subject.address + " " + subject.locality + " " + subject.province);
        });
    }
  };

  const next = record!.ordered_segments.segments.filter(
    (segment: any) => segment.delivery_last_status === DeliveryStatusChoice.DELIVERING
  ).sort((a: any, b: any) => a.sequence - b.sequence)[0]

  if (next) {
    if (next.delivery_delivery_type === DeliveryType.WITHDRAW) {
      getSubjectAddress(next.starting_point_id);
    } else {
      getSubjectAddress(next.arrival_point_id);
    }
  }


  return record && optionsDeliveryStatus ? (
    <>
      {
        record.ordered_segments &&
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setTrue();
          }}
          label={next ? (
            next.delivery_delivery_type === DeliveryType.WITHDRAW ?
              next.starting_point_id + ":" + next.starting_point_business_name :
              next.arrival_point_id + ":" + next.arrival_point_business_name
          )
            : "Fine consegne"}
          title={address}
        >
          <FiberSmartRecordIcon />
        </Button>
      }

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="lg"
        onClose={setFalse}
      >
        <ListContextProvider value={listContext}
        >
          <Datagrid
            rowStyle={completedDeliveryRowStyle}
          >
            <DeliveryField deliverySource="delivery_id" noTitle label="Missione" sortable={false} />
            <DeliveryTypeField source="delivery_delivery_type" label="Tipo" />
            <TextField source="sequence" sortable={false} />
            <FunctionField
              label={"Destinazione"}
              render={(record: Record | undefined) =>
                record ? (
                  record.delivery_delivery_type === DeliveryType.WITHDRAW ?
                    record.starting_point_id + ":" + record.starting_point_business_name :
                    record.arrival_point_id + ":" + record.arrival_point_business_name
                ) : null
              }
              sortable={false}
            />
            <FunctionField
              label={"Località"}
              render={(record: Record | undefined) =>
                record ? (
                  record.delivery_delivery_type === DeliveryType.WITHDRAW ?
                    record.starting_point_locality :
                    record.arrival_point_locality
                ) : null
              }
              sortable={false}
            />
            <SelectField
              {...initField("delivery_last_status", optionsDeliveryStatus, "array")}
              choices={optionsDeliveryStatus.status.choices}
              label={"Stato"}
              sortable={false}
            />
          </Datagrid>
        </ListContextProvider>
      </Dialog>
    </>
  ) : null;
};
