import { makeStyles, useTheme } from "@material-ui/core";

import {
  Identifier,
  ListContextProvider,
  useList,
  useListContext,
  useUpdate,
} from "ra-core";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ArrayField, Button, ChipField, Datagrid, DateField, FunctionField, Record, SelectField, SingleFieldList, useRecordContext } from "react-admin";
import _ from "lodash";
import { RouteField } from "../../components/RouteField";
import { LoadDeliveriesDatagrid } from "./LoadDeliveriesDatagrid";
import { LoadDeliveryGroup } from "./types";
import { DriverField } from "../../components/DriverField";
import { CarrierField } from "../../components/CarrierField";
import { VehicleField } from "../../components/VehicleField";
import Alert from "@material-ui/lab/Alert/Alert";
import { initField } from "../../shared/utils";
import { useOptions } from "../../shared/hooks/useOptions";
import { Print2XDocumentsButton } from "../../Distribution/Route/Print2XDocuments";
import { SendTrackMeButtonsButtonSingle } from "../../Distribution/Route/SendTrackMeButtonSingle";
import { getUser } from "../../authProvider";
import { DriverCarrierField } from "../../Distribution/Route/DriverCarrierField";



const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: "white",
    color: theme.palette.secondary.dark,
  },
}));

interface RouteGroupedDeliveryeDatagridProps {
  setVersion: Dispatch<SetStateAction<number>>;
  setActiveDelivery: (delivery: Record | undefined) => void;
  activeDelivery?: Record;
  rowStyle: (record: Record, index: number) => any;
  route: Record;
}

export const RouteGroupedDeliveryeDatagrid = (
  props: RouteGroupedDeliveryeDatagridProps
) => {
  const { data: routeOptions } = useOptions("routes", "GET");
  const { setActiveDelivery, rowStyle, activeDelivery } = props;
  const { ids, data, loading, loaded } = useListContext();
  const classes = useStyles();
  const [update, updateResponse] = useUpdate();
  const [groupedData, setGroupedData] = useState<LoadDeliveryGroup[]>([]);
  const [selectedDeliveries, setSelectedDeliveries] = useState<Identifier[]>([]);
  const listContext = useList({
    data: groupedData,
    ids: groupedData.map((group) => group.id),
    loading: loading,
    loaded: loaded,
  });
  const user = getUser()
  const record = useRecordContext();
  const [loadComplete, setLoadComplete] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    const listData = ids.map((id) => data[id]);
    const groupDeliveries = (data: Record[]) => {
      let groupedData: LoadDeliveryGroup[] = [];
      let color = "white";
      const groupedByRoute = _.groupBy<Record>(data, "route_id");
      _.forEach(groupedByRoute, function (value, routeKey) {
        const route_deliveries = {
          route: props.route,
          id: routeKey,
          deliveries: value
            .sort((a: Record, b: Record) => {
              if (a.sequence > b.sequence) {
                return -1;
              }
              if (a.sequence < b.sequence) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            .map((d, index, data) => {
              if (
                index - 1 >= 0 &&
                data[index - 1].delivery !== d.delivery
              ) {
                color = color === "white" ? theme.palette.grey[200] : "white";
              }
              return { ...d, color };
            }),
          delivery_load: value.filter(x => x.loaded === true).length,
          delivery_num: value.length
        };
        if (route_deliveries.delivery_load === route_deliveries.delivery_num) {
          setLoadComplete(true);
        }
        else {
          setLoadComplete(false);
        }
        groupedData.push(route_deliveries);
      });
      if (groupedData.length <= 0) {
        if (props.route) {
          groupedData.push(
            {
              route: props.route,
              id: props.route.id,
              deliveries: [],
              delivery_load: 0,
              delivery_num: 0
            }
          )
          setLoadComplete(true);
        }

      }
      return groupedData;
    };
    setGroupedData(groupDeliveries(listData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, ids]);

  useEffect(() => {
    if (updateResponse &&
      !updateResponse.error &&
      updateResponse.loaded === true
    ) {
      window.location.reload();
    }
  }, [updateResponse])

  const onSelect = (ids: Identifier[]) => {
    setSelectedDeliveries(
      ids.reduce((deliveryIdList, id) => {
        listContext.data[id].deliveries.forEach((delivery: Record) =>
          deliveryIdList.push(delivery.id)
        );
        return deliveryIdList;
      }, [] as Identifier[])
    );
  };

  const onToggleItem = (id: Identifier) => {
    const deliveriesIdRoute = ids.reduce((deliveryIdList, deliveryId) => {
      if (data[deliveryId].route_id.toString() === id.toString())
        deliveryIdList.push(deliveryId);
      return deliveryIdList;
    }, [] as Identifier[]);
    const inSelectedDeliveries = deliveriesIdRoute.every((id) =>
      selectedDeliveries.includes(id)
    );
    setSelectedDeliveries((old) => {
      const newSelectedDeliveries = inSelectedDeliveries
        ? old.filter((deliveryId) => !deliveriesIdRoute.includes(deliveryId))
        : [...old, ...deliveriesIdRoute];
      return newSelectedDeliveries;
    });
  };

  const selectedRoutes = useMemo(
    () =>
      listContext.ids.filter((id) => {
        const record = listContext.data[id] as LoadDeliveryGroup;
        return record.deliveries.every((delivery) =>
          selectedDeliveries.includes(delivery.id)
        );
      }),
    [listContext.data, listContext.ids, selectedDeliveries]
  );
  useEffect(() => {
    const getNext = (id: Identifier, deliveries: Record[]) => {
      const index = deliveries.findIndex((delivery) => delivery.id === id);
      const nextIndex = index + 1 < deliveries.length ? index + 1 : index;
      return deliveries[nextIndex];
    };

    let activeDelivery: Record | undefined = undefined;
    if (selectedDeliveries.length > 0) {
      const lastSelectedDelivery = selectedDeliveries[selectedDeliveries.length - 1];
      const deliveries = listContext.ids.reduce((deliveries, id) => {
        deliveries.push(...listContext.data[id].deliveries);
        return deliveries;
      }, [] as Record[]);

      activeDelivery = getNext(lastSelectedDelivery, deliveries);
    }
    setActiveDelivery && setActiveDelivery(activeDelivery);
  }, [listContext.data, listContext.ids, selectedDeliveries, setActiveDelivery]);



  return (
    <ListContextProvider value={listContext}>

      {updateResponse.error ?
        <Alert severity="error">Errore nella chiusura!</Alert> :
        null
      }

      <Datagrid
        hasBulkActions
        onSelect={onSelect}
        classes={classes}
        selectedIds={selectedRoutes}
        onToggleItem={onToggleItem}
        expand={
          <LoadDeliveriesDatagrid
            selectedDeliveries={selectedDeliveries}
            setSelectedDeliveries={setSelectedDeliveries}
            setActiveDelivery={setActiveDelivery}
            rowStyle={rowStyle}
            activeDelivery={activeDelivery}
            setLoadComplete={setLoadComplete}
          />
        }
        header={<></>}
        isRowSelectable={() => false}
      >

        <RouteField sortable={false} source="id" label="Giro" routeSource={"id"} />
        <DriverCarrierField label={"Autista/Vettore"} source="route" forceTypografy={true} />
        <VehicleField sortable={false} source="route.vehicle" label="Veicolo" />
        <DateField sortable={false} source="route.planned_date" label="Data pianificazione" showTime />
        <FunctionField sortable={false} source="route.tags_objects" label="Tag"
          render={(record: Record | undefined) => {
            return (
              <ArrayField source="route.tags_objects" label="Tag">
                <SingleFieldList>
                  <FunctionField
                    render={(record: any) => (
                      <ChipField size="small" source="label" />
                    )}
                  />
                </SingleFieldList>
              </ArrayField>
            )
          }
          } />

        {routeOptions &&
          <SelectField
            choices={routeOptions.status.choices}
            {...initField("route.status", routeOptions, "array")}
            label="Stato giro"
          />}
        {
          loadComplete &&
          <FunctionField
            render={(record: Record | undefined) =>
              (record && record.route.status !== 'L' && record.route.status !== 'S') ? (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    update({
                      resource: "routes",
                      payload: {
                        id: record.id,
                        data: {
                          status: "L",
                        },
                      },
                    });
                  }}
                  color="primary"
                >

                  <>Termina carico</>
                </Button>
              ) : null
            }
          />
        }
        {
          loadComplete &&
          <FunctionField
            render={(record: Record | undefined) =>
              record && record.route.status === 'L' ? (
                <Print2XDocumentsButton mission_max_page={record.route.mission_max_page} />
              ) : null
            }
          />
        }
        {
          loadComplete && user.is_admin === true &&
          <FunctionField
            render={(record: Record | undefined) =>
              record ? (
                <SendTrackMeButtonsButtonSingle {...{ id: `${record.id}` }} />
              ) : null
            }
          />
        }

      </Datagrid>
      {/*<CustomPagination />*/}
    </ListContextProvider>
  );
};
