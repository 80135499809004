import { useListContext, Record } from "ra-core";
import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  NumberField,
  SelectField,
  linkToRecord,
  Link,
  ReferenceField,
  BooleanField,
} from "react-admin";
import { CustomFileField } from "../components/CustomFIleField";
import { IdentifierField } from "../components/IdentifierField";
import { DatagridPropsWithOptions } from "../shared/types";
import { initField } from "../shared/utils";
import { DownloadInvoiceDetailPdfButton } from "./components/DownloadInvoiceDetailPdfButton";
import { DownloadInvoicePdfButton } from "./components/DownloadInvoicePdfButton";
import { Accordion, AccordionDetails, AccordionSummary, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '50px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);


export const PurchaseInvoiceDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  const { refetch } = useListContext();
  const classes = useStyles();

  const [openAccrodions, setOpenAccrodions] = useState(false);

  return (
    <Datagrid {...rest}>
      <IdentifierField show {...initField("id", options)} />
      <FunctionField
        label="Incassata"
        render={(record: any) => {
          const color = record.last_status__status === "I" ? "green" : "red";
          return (
            <div
              style={{
                backgroundColor: color,
                width: "30px",
                height: "30px",
                borderRadius: "100%",
              }}
            />
          );
        }}
      />
      <FunctionField
        label="Azienda"
        source="company_object"
        render={(record: Record | undefined) => {
          if (record && record.company) {
            return (
              <Link to={linkToRecord("/subjects", record.company_object.company.id, "edit")}>
                <TextField
                  record={record.company_object}
                  source="name"
                  variant="inherit"

                />
              </Link>
            );
          }
          return null;
        }}
      />
      <FunctionField
        label="Fornitore"
        source="client__business_name"
        sortBy="client__business_name"
        render={(record: Record | undefined) => {
          if (record) {
            return (
              <Link to={linkToRecord("/subjects", record.client_id, "edit")}>
                <TextField
                  record={record.client}
                  source="business_name"
                  variant="inherit"
                />
              </Link>
            );
          }
          return null;
        }}
      />
      <SelectField
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />
      <SelectField
        {...initField("invoice_type", options, "array")}
        choices={options.invoice_type.choices}
      />
      <SelectField
        {...initField("type", options, "array")}
        choices={options.type.choices}
      />
      <TextField {...initField("invoice_number", options)} />
      <DateField {...initField("invoice_date", options)} />
      <DateField {...initField("expiration_date", options)} />
      <NumberField {...initField("taxable", options)} />
      <NumberField {...initField("amount", options)} />
      <CustomFileField
        {...initField("file", options)}
        title="name"
        src="url"
      />
      <BooleanField {...initField("chart_account_handle", options)} label="Assegnato" />
      <FunctionField
        label="Piano dei conti"
        source="chart_accounts"
        render={(record: Record | undefined) => {

          if (record) {
            return (
              record.chart_accounts && (
                record.chart_accounts.map((chart_account: any) => (
                  <Typography>
                    {chart_account}
                  </Typography>
                ))
              )
            );
          }
          return null;
        }}
      />

    </Datagrid>
  );
};
