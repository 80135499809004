import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useRefresh } from "ra-core";
import { useEffect, useState } from "react";
import { getAccessToken, getUser } from "../../authProvider";
import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";
import { BoardRoute } from "./BoardRoute";
import { client, clientNoJson } from "../../dataProvider";
import { PresenceQrGenerator } from "../../components/PresenceQrGenerator";
import { de } from "date-fns/locale";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      overflow: "hidden",

      [theme.breakpoints.up("sm")]: {
        height: "100%",
        width: "100%",
      },
    },
    column: {
      padding: "0.5em",
      overflowY: "auto",
      maxHeight: "100%",
    },
  })
);

export const BoardRoutes = () => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [soundEmitted, setSoundEmitted] = useState(false);

  useClosedSidebar();

  const user = getUser();

  useEffect(() => {
    let connectionString = `ws://${window.location.hostname}:${window.location.port}/ws/distribution/?token=${user.access}`;
    if (window.location.protocol === "https:")
      connectionString = `wss://${window.location.hostname}:${window.location.port}/ws/distribution/?token=${user.access}`;
    const client = new WebSocket(connectionString);

    function connect() {
      client.onopen = () => { };
      client.onmessage = (e) => {
        setSoundEmitted(false);
        refresh();
      };
      client.onerror = (e) => {
      };
      client.onclose = () => {
        connect();
      };
    }
    connect();
  }, [refresh, user.access]);


  var counter = 0
  let limitTime = 24 * 60 * 60 * 1000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter < limitTime && (["admin"].includes(user.username) || user.username.toLowerCase().includes("bacheca"))) {
        getAccessToken()

        try {
          clientNoJson(`api/stay_tuned/`, {
            method: "POST",
          });
          if (counter !== 0 && (counter % 600000 === 0)) {
            setSoundEmitted(false);
            refresh();
          }
        } catch (err: any) {
        }

        counter = counter + 120000
      }

    }, 120000);

    return () => clearInterval(timer);
  }, []);

  function getSite() {
    let def_start_points: any = [];
    switch (user.username) {
      case "bacheca.sa":
        def_start_points = [705, 710, 1721, 1725, 1915, 4887, 7990, 73634, 78797, 91978];
        break;
      case "bacheca.na":
        def_start_points = [711];
        break;
      case "bacheca.mt":
        def_start_points = [38091];
        break;
    }
    return def_start_points
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6} className={classes.column}>
        <BoardRoute filters={{
          status: "P", start_points: getSite(),
        }} title="Da caricare"
          soundEmitted={soundEmitted} setSoundEmitted={setSoundEmitted}
        />
      </Grid>

      <Grid item xs={6} className={classes.column}>
        <BoardRoute filters={{ status: "L", start_points: getSite(), }} title="In partenza"
          soundEmitted={soundEmitted} setSoundEmitted={setSoundEmitted} />
      </Grid>

      <div style={{
        position: "fixed",
        bottom: "0",
        left: "50%",
        transform: "translate(-50%, 0)"
      }}>
        <PresenceQrGenerator {...{ compact: true, show_info: false }} />
      </div>
    </Grid>
  );
};
