import {
  AutocompleteArrayInput,
  BulkActionProps,
  BulkDeleteButton,
  DateInput,
  ListProps,
  ReferenceArrayInput,
  SelectInput,
  TextInput,
} from "ra-ui-materialui";
import {ReferenceInput, AutocompleteInput} from 'react-admin';
import { NullableBooleanInput, Record, useRefresh, useUnselectAll } from "react-admin";
import { CustomList } from "../components/CustomList";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { generateError, getDefaultListProps, initField } from "../shared/utils";
import { NumberInput } from "../components/NumberInput";
import { InvoiceDatagrid } from "./InvoiceDatagrid";
import { useOptions } from "../shared/hooks/useOptions";
import { TariffType } from "../shared/types";
import { PurchaseInvoiceDatagrid } from "./PurchaseInvoiceDatagrid";
import { InvoiceListHeader } from "./InvoiceListHeader";
import { Button, FormWithRedirect, RecordContextProvider, required, useNotify } from "react-admin";
import { Box, Card, CardContent, CircularProgress, Dialog, Grid, Typography } from "@material-ui/core";
import { ReferenceInputSite } from "../components/reference-inputs/ReferenceInputSite";
import { ReferenceInputChartAccounts } from "../components/reference-inputs/ReferenceInputChartAccounts";
import { useCallback, useState } from "react";
import { client } from "../dataProvider";
import { set } from "lodash";



const BulkAssignChartAccounts = (props: BulkActionProps) => {
  const selectedIds = props.selectedIds;
  const unselectAll = useUnselectAll();

  const [openAssChartAccDialog, setOpenAssChartAccDialog] = useState(false);
  const openAssChartAccDialogFunc = () => {
    setOpenAssChartAccDialog(true);
  };
  const [saving, setSaving] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();


  const assigniToChartAccounts = useCallback(
    (values: Partial<Record>) => {
      client(`invoicechartaccounts/assign_invoice_to_chart_accounts`, {
        method: "POST",
        data: values,
      })
        .then((data) => {
          setSaving(false);
          setOpenAssChartAccDialog(false);
          notify("Fatto");
          unselectAll();
          refresh();
        })
        .catch((error) => {
          setSaving(false);
          generateError(error, notify);
        });
    },
    [notify, refresh]
  );

  return (
    <>
      <Button
        disabled={saving}
        label="Assegna piano dei conti"
        onClick={openAssChartAccDialogFunc}
      >
        {saving ? <CircularProgress size={18} thickness={2} /> : undefined}
      </Button>
      <Dialog
        open={openAssChartAccDialog}
        onClose={() => setOpenAssChartAccDialog(false)}
      >
        <Card style={{ minWidth: "500px", padding: "15px" }}>
          <Typography variant="h5">
            Assegna a piano dei conti
          </Typography>
          <CardContent>
            <RecordContextProvider value={undefined}>
              <FormWithRedirect
                save={assigniToChartAccounts}
                initialValues={{ invoices: selectedIds }}
                render={(formProps) => (
                  <>
                    <Grid container alignItems="flex-start">

                      <Grid item xs={6}>
                        <ReferenceInputChartAccounts
                          source="chart_accounts"
                          label="Piano dei conti"
                          fullWidth={true}
                          validate={[required()]}
                          filter={{ last_level: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ReferenceInputSite
                          source="site"
                          label="Sede"
                          fullWidth={true}
                          validate={[]}
                        />
                      </Grid>

                    </Grid>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={formProps.handleSubmit}
                        label="Assegna"
                      >
                        <></>
                      </Button>
                    </Box>
                  </>
                )}
              />
            </RecordContextProvider>
          </CardContent>
        </Card>


      </Dialog>
    </>
  )
};

const BulkActionButtons = (props: BulkActionProps) => (
  <>
    {/* default bulk delete action */}
    <BulkAssignChartAccounts {...props} />
    <BulkDeleteButton undoable={false} {...props}
      confirmTitle="Cancella fatture acquisto"
      confirmContent="Sei sicuro di voler cancellare le fatture selezionate?"
    />
  </>
);


export const PurchaseInvoiceList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTerms } = useOptions("payment-terms", "GET");

  const filters =
    options && optionsTerms
      ? [
        <NullableBooleanInput {...initField("chart_account_handle", options)} alwaysOn label="Piano dei conti assegnato" />,
        <DateInput source="invoice_date_after" label="Dal" />,
        <DateInput source="invoice_date_before" label="Al" />,
        <NumberInput {...initField("sequence_number", options)} />,
        <NumberInput {...initField("id", options)} />,

        <ReferenceInputSubject
          filter={{ type: "client" }}
          {...initField("client", options)}
        />,
        <ReferenceInputSubject
          filter={{ type: "supplier" }}
          {...initField("supplier", options)}
          alwaysOn
        />,
        <SelectInput
          choices={options.type.choices}
          {...initField("type", options, "array")}
        />,
        <TextInput {...initField("invoice_number", options)} />,
        <SelectInput
          choices={options.status.choices}
          {...initField("status", options, "array")}
        />,
        <SelectInput
          choices={optionsTerms.method.choices}
          {...initField("payment_method", optionsTerms, "array")}
          label="Metodo di pagamento"
        />,
        <ReferenceArrayInput
          reference="autocomplete/chartaccounts"
          source={"chartaccounts"}
          label="Piano dei conti"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <ReferenceInput
          {...initField("accountcompany", options)}
          filter={{ type: "accountcompany" }}
          reference="autocomplete/accountcompany"
          label="Azienda"
          className="filter-field-small"
          alwaysOn
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
      ]
      : [];

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      filter={{ "invoice_type": TariffType.PURCHASE }}
      {...getDefaultListProps(props)}
      topElement={<InvoiceListHeader />}
      bulkActionButtons={<BulkActionButtons />}
      filterDefaultValues={{
        invoice_date_after: new Date(new Date().getFullYear(), 0, 1, 12).toISOString().split('T')[0],
      }}
    >
      <PurchaseInvoiceDatagrid
        options={options}
        isRowSelectable={(record) => record.status !== "P"}
      />
    </CustomList>
  ) : null;
};
