import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  BooleanInput,
  BulkDeleteButtonProps,
  BulkDeleteButton,
  NullableBooleanInput,
  AutocompleteInput,
  SelectInput,
  DateTimeInput,
  DatagridProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  NumberInput,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { PrintLabelButton } from "./PrintLabelButton";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { DeliveryDatagrid } from "./DeliveryDatagrid";
import { DeliveryListHeader } from "./DeliveryListHeader";
import { HideButton } from "./HideButton";
import { ShowButton } from "./ShowButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { ReferenceInputMainSubject } from "../../components/reference-inputs/ReferenceInputMainSubject";
import { ReferenceArrayInputSubject } from "../../components/reference-inputs/ReferenceArrayInputSubject";
import { AssociateParentDeliveryButton } from "./AssociateParentDeliveryButton";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UpdateStatusConsignmentBulkButton } from "./UpdateStatusConsignmentBulkButton";
import { CloneDeliveriesBulkButton } from "./CloneDeliveriesBulkButton";
import { UpdateInternalNotesBulkButton } from "./UpdateInternalNotesBulkButton";
import { ReloadDeliveryItemBulkButton } from "./ReloadDeliveryItemBulkButton";
import { MigrateDeliveryClientBulkButton } from "./MigrateDeliveryClientBulkButton";
import { PrintDeliveryFinalNotesBulkButton } from "./PrintDeliveryFinalNotesBulkButton";
import { getUser } from "../../authProvider";
import { RecreateDeliveryItemBulkButton } from "./RecreateDeliveryItemBulkButton";
import { PrintDeliveryNotesBulkButton } from "./PrintDeliveryNotesBulkButton";
import { UpdateStatusConsignmentForcedBulkButton } from "./UpdateStatusConsignmentForcedBulkButton";
import { UpdateStatusCanceledForcedBulkButton } from "./UpdateStatusCanceledForcedBulkButton";
import { UpgradeDeliveryItemBulkButton } from "./UpgradeDeliveryItemBulkButton";
import { PrintDeliveryMarkersBulkButton } from "./PrintDeliveryMarkersBulkButton";
import { PrintDeliveryBorderauxBulkButton } from "./PrintDeliveryBorderauxBulkButton";
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, Typography } from "@material-ui/core";

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { PrintDeliveryMarkers8x4BulkButton } from "./PrintDeliveryMarkers8x4BulkButton";
import { PrintDeliveryMarkers10x7BulkButton } from "./PrintDeliveryMarkers10x7BulkButton";
import { UpdateStatusCanceledFixErrorBulkButton } from "./UpdateStatusCanceledFixErrorBulkButton";
import { ResetDeliveryStatusBulkButton } from "./ResetDeliveryStatusBulkButton";
import { DeliveryField } from "../../components/DeliveryField";
import { PrintDeliveryMarkers10x10BulkButton } from "./PrintDeliveryMarkers10x10BulkButton";
import { ZipDeliveryNotesBulkButton } from "./ZipDeliveryNotesBulkButton";
import { PrintDeliveryGroupBorderauxBulkButton } from "./PrintDeliveryGroupBorderauxBulkButton";
import { ProgrammedStockEndBulkButton } from "./ProgrammedStockEndBulkButton";
import { ExportDeliveryBulkButton } from "./ExportDeliveryBulkButton";


const DeliveryListBody = (props: DatagridProps) => {
  const { data: options } = useOptionsResource("GET");

  return options ? (
    <DeliveryDatagrid {...props} stickyHeader options={options} />
  ) : null;
};

const BulkDeliveryActions = (props: BulkDeleteButtonProps) => {


  const [openMenu, setOpenMenu] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick2 = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  //<PrintLabelButton />
  return (
    /*
    <Grid container style={{
      position: "absolute",
      top: "10px",
      right: "calc(150px +15%)",
      width: "200px"
    }}
      onBlur={() => { setOpenMenu(false) }}
    >
      <Grid item style={{ textAlign: "right" }}>
        <Button aria-controls="simple-menu" aria-haspopup="true"
          onClick={() => { setOpenMenu(!openMenu) }}
          style={{ textAlign: "right" }}
        >
          <Typography color="error" style={{ textAlign: "right" }}>Azioni</Typography>
        </Button>
      </Grid>
      {
        openMenu ?
          <Grid item style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            textAlign: "left",
            alignItems: "flex-start",
            backgroundColor: "white",
            width: "200px"
          }}
          >
            <HideButton />
            <ShowButton />
            <AssociateParentDeliveryButton />
            {
              getUser().is_admin === true ? (
                <BulkDeleteButton {...props} />
              ) : null
            }
            <UpdateInternalNotesBulkButton />
            <ReloadDeliveryItemBulkButton />
            {
              getUser().is_admin === true ? (
                <RecreateDeliveryItemBulkButton {...props} />
              ) : null
            }
            {
              getUser().is_admin === true ? (
                <UpgradeDeliveryItemBulkButton {...props} />
              ) : null
            }
            <MigrateDeliveryClientBulkButton />
            <UpdateStatusConsignmentBulkButton />
            {
              getUser().is_admin === true ? (
                <UpdateStatusConsignmentForcedBulkButton {...props} />
              ) : null
            }
            {
              getUser().is_admin === true ? (
                <UpdateStatusCanceledForcedBulkButton {...props} />
              ) : null
            }
            <CloneDeliveriesBulkButton />
            <PrintDeliveryFinalNotesBulkButton />
            <PrintDeliveryNotesBulkButton />
            <PrintDeliveryMarkersBulkButton />

          </Grid> : null
      }

    </Grid>
    */
    /*
    <>
      <HideButton />
      <ShowButton />
      <AssociateParentDeliveryButton />
      {
        getUser().is_admin === true ? (
          <BulkDeleteButton {...props} />
        ) : null
      }
      <UpdateInternalNotesBulkButton />
      <ReloadDeliveryItemBulkButton />
      {
        getUser().is_admin === true ? (
          <RecreateDeliveryItemBulkButton {...props} />
        ) : null
      }
      {
        getUser().is_admin === true ? (
          <UpgradeDeliveryItemBulkButton {...props} />
        ) : null
      }
      <MigrateDeliveryClientBulkButton />
      <UpdateStatusConsignmentBulkButton />
      {
        getUser().is_admin === true ? (
          <UpdateStatusConsignmentForcedBulkButton {...props} />
        ) : null
      }
      {
        getUser().is_admin === true ? (
          <UpdateStatusCanceledForcedBulkButton {...props} />
        ) : null
      }
      <CloneDeliveriesBulkButton />
      <PrintDeliveryFinalNotesBulkButton />
      <PrintDeliveryNotesBulkButton />
      <PrintDeliveryMarkersBulkButton />
    </>
    */

    <>
      <Button aria-controls="simple-menu" aria-haspopup="true"
        onClick={handleClick2}
      >
        <Typography color="error" variant="subtitle2">Azioni</Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={
          Boolean(anchorEl)
        }
        onClose={handleClose2}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Grid style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "flex-start"
        }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <HideButton />
          <ShowButton />
          <AssociateParentDeliveryButton />
          {
            getUser().is_admin === true ? (
              <BulkDeleteButton {...props} />
            ) : null
          }
          <UpdateInternalNotesBulkButton />
          <ReloadDeliveryItemBulkButton />
          {
            getUser().is_admin === true ? (
              <RecreateDeliveryItemBulkButton {...props} />
            ) : null
          }
          {
            getUser().is_admin === true ? (
              <UpgradeDeliveryItemBulkButton {...props} />
            ) : null
          }
          <MigrateDeliveryClientBulkButton />
          <UpdateStatusConsignmentBulkButton />
          {
            getUser().is_admin === true ? (
              <UpdateStatusConsignmentForcedBulkButton {...props} />
            ) : null
          }
          {
            getUser().is_admin === true ? (
              <UpdateStatusCanceledForcedBulkButton {...props} />
            ) : null
          }
          {
            getUser().is_admin === true ? (
              <UpdateStatusCanceledFixErrorBulkButton {...props} />
            ) : null
          }
          {
            getUser().is_admin === true ? (
              <ResetDeliveryStatusBulkButton {...props} />
            ) : null
          }
          <CloneDeliveriesBulkButton />
          <ProgrammedStockEndBulkButton {...props} />
          <ZipDeliveryNotesBulkButton />
          <PrintDeliveryFinalNotesBulkButton />
          <PrintDeliveryNotesBulkButton />
          <PrintDeliveryMarkersBulkButton />
          <PrintDeliveryMarkers8x4BulkButton />
          <PrintDeliveryMarkers10x7BulkButton />
          <PrintDeliveryMarkers10x10BulkButton />
          <PrintDeliveryBorderauxBulkButton />
          <PrintDeliveryGroupBorderauxBulkButton />
          <ExportDeliveryBulkButton />
        </Grid>

      </Menu >
    </>

    /*
  <Grid style={{
    position: "absolute",
    top: "-10px"
  }} >
    <Accordion
      style={{
        color: "red",
        backgroundColor: "transparent",
        minHeight: "10px !important",
        paddingTop: "15px",
        paddingBottom: "0px",
      }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          color: "red",
          backgroundColor: "lightgreen",
          minHeight: "20px !important",
          height: "20px !important",
        }}
      >
        <Grid style={{ height: "10px" }}>
          Azioni
        </Grid>

      </AccordionSummary>
      <AccordionDetails style={{
        backgroundColor: "white"
      }}>
        <Grid style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "flex-start",
          width: "200px"
        }}>
          <HideButton />
          <ShowButton />
          <AssociateParentDeliveryButton />
          {
            getUser().is_admin === true ? (
              <BulkDeleteButton {...props} />
            ) : null
          }
          <UpdateInternalNotesBulkButton />
          <ReloadDeliveryItemBulkButton />
          {
            getUser().is_admin === true ? (
              <RecreateDeliveryItemBulkButton {...props} />
            ) : null
          }
          {
            getUser().is_admin === true ? (
              <UpgradeDeliveryItemBulkButton {...props} />
            ) : null
          }
          <MigrateDeliveryClientBulkButton />
          <UpdateStatusConsignmentBulkButton />
          {
            getUser().is_admin === true ? (
              <UpdateStatusConsignmentForcedBulkButton {...props} />
            ) : null
          }
          {
            getUser().is_admin === true ? (
              <UpdateStatusCanceledForcedBulkButton {...props} />
            ) : null
          }
          <CloneDeliveriesBulkButton />
          <PrintDeliveryFinalNotesBulkButton />
          <PrintDeliveryNotesBulkButton />
          <PrintDeliveryMarkersBulkButton />
        </Grid>
      </AccordionDetails>
    </Accordion>

  </Grid>
  */
  );
};
export const DeliveryList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");

  const handleKeyPress = useCallback((event) => {
    if (event.shiftKey === true && event.key === "N") {
      window.location.href = window.location.href + "/create";
    }
  }, []);

  useMemo(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      //document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const filters =
    options && optionsDeliveryStates
      ? [
        <TextInput alwaysOn {...initField("document_number", options)}
          className="filter-field-small"
          label="DDT/ID/QR" />,
        <DateInput
          {...initField("initial_delivery_date_after", options)}
          label="Data ins. dal"
          alwaysOn
          required
        />,
        <DateInput alwaysOn {...initField("document_date_exact", options)}
          label="Data doc." />,
        <ReferenceInputSubject alwaysOn
          {...initField("client", options)}
          filter={{ type: "client" }}
          className="filter-field-small"
        />,
        <TextInput alwaysOn {...initField("receiver_name", options)} label="Nome destinatario" />,
        <ReferenceArrayInputSubject
          alwaysOn
          {...initField("receiver_multiple", options)}
          label="Destinatario"
          filter={{ type: "receiver" }}
          className="filter-field-small"
        />,
        <SelectArrayInput
          {...initField("delivery_states", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          alwaysOn
          label="Stato"
          className="filter-field-small"
        />,
        <SelectArrayInput
          {...initField("types", options, "array")}
          choices={options.delivery_type.choices}
          alwaysOn
          label="Tipi"
          className="filter-field-small"
        />,
        <SelectInput
          {...initField("note_filter", options, "array")}
          choices={[
            { value: "1", display_name: "NESSUNA" },
            { value: "2", display_name: "FINALE E INIZIALE" },
            { value: "3", display_name: "INIZIALE PRESENTE" },
            { value: "4", display_name: "FINALE PRESENTE" },
            { value: "5", display_name: "SOLO FINALE" },
            { value: "6", display_name: "SOLO INIZIALE" },
            { value: "7", display_name: "ALMENO UNA" },
            { value: "8", display_name: "MANCA INIZIALE" },
            { value: "9", display_name: "MANCA FINALE" },
          ]}
          alwaysOn
          className="filter-field-small"
          label="Bolle"
        />,
        <ReferenceInput
          {...initField("driver", options)}
          filter={{ departments: "Autista" }}
          reference="autocomplete/employee"
          label="Autista"
          alwaysOn
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInputSubject
          {...initField("warehouse_competence", options)}
          label="Magazzino competenza"
          className="filter-field-small"
          alwaysOn
        />,
        <ReferenceInput
          {...initField("carrier", options)}
          filter={{ type: "carrier" }}
          reference="autocomplete/subject"
          label="Vettore"
          className="filter-field-small"
          alwaysOn
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,

        <NullableBooleanInput {...initField("price_warning", options)}
          label="Alert prezzo" />,
        <ReferenceInput
          source="item"
          label="Articolo"
          reference="autocomplete/item"
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <NullableBooleanInput
          {...initField("internal_notes", options)}
          label="Bolle interne?"
        />,
        <NullableBooleanInput {...initField("has_file_borderaux", options)} label="Borderaux firmato" />,
        <ReferenceArrayInput
          reference="autocomplete/category"
          defaultValue={[]}
          source={"categories"}
          label="Categorie"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <ReferenceInputSubject
          {...initField("cessionary", options)}
          className="filter-field-small"
          label="Cessionario"
        />,
        <NumberInput {...initField("packages_number_range_min", options)} label="Colli min" />,
        <NumberInput {...initField("packages_number_range_max", options)} label="Colli max" />,
        <NullableBooleanInput {...initField("packages_number_empty", options)} label="Colli nullo" />,
        <SelectInput
          {...initField("delivery_heirarchy", options, "array")}
          choices={[
            { value: "G", display_name: "VERDE (ULTIMA MISSIONE)" },
            { value: "R", display_name: "ROSSA (PRIMA MISSIONE)" },
            { value: "Y", display_name: "GIALLA (MISSIONE INTERMEDIA)" },
          ]}
          className="filter-field-small"
          label="Colore"
        />,
        <NullableBooleanInput
          label="Contiene servizi"
          source="contains_services"
        />,
        <NullableBooleanInput {...initField("has_cachet", options)} label="Contrassegno" />,
        <DateInput
          {...initField("document_date_after", options)}
          label="Data documento dal"
        />,
        <DateInput
          {...initField("document_date_before", options)}
          label="Data documento fino al"
        />,

        <DateTimeInput
          {...initField("delivery_date_after", options)}
          label="Data missione dal"
        />,
        <DateTimeInput
          {...initField("delivery_date_before", options)}
          label="Data missione fino al"
        />,
        <DateTimeInput
          {...initField("delivery_last_status_date_after", options)}
          label="Data ultimo stato dal"
        />,
        <DateTimeInput
          {...initField("delivery_last_status_date_before", options)}
          label="Data ultimo stato al"
        />,
        <ReferenceInput
          {...initField("import_file", options)}
          reference="autocomplete/imported-files"
          className="filter-field-small"
          label="File di importazione"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInputSubject
          {...initField("stock_warehouse", options)}
          label="Giacente in magazzino a"
          className="filter-field-small"
        />,
        <ReferenceInput
          source="route"
          label="Giro"
          reference="autocomplete/route"
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInput
          source="delivery_group"
          label="Gruppo"
          reference="autocomplete/delivery-group"
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <NullableBooleanInput {...initField("has_file_delivery_group", options)} label="Gruppo missioni firmato" />,
        <TextInput {...initField("id", options)} />,
        <TextInput {...initField("main_delivery", options)}
          className="filter-field-small"
          label="Miss. Principale" />,
        <NullableBooleanInput source="is_recurrent" label="Missioni ricorrenti" />,
        <ReferenceInputMainSubject
          {...initField("sender", options)}
          className="filter-field-small"
          label="Mittente"
        />,
        <NullableBooleanInput {...initField("hide", options)} label="Nascoste" />,
        <NullableBooleanInput {...initField("partial", options)} label="Parziale" />,
        <NumberInput {...initField("weight_range_min", options)} label="Peso min" />,
        <NumberInput {...initField("weight_range_max", options)} label="Peso max" />,
        <NullableBooleanInput {...initField("weight_empty", options)} label="Peso nullo" />,
        <NullableBooleanInput {...initField("portal_client", options)} label="Portale clienti" />,
        //<NullableBooleanInput {...initField("is_main_delivery", options)} label="Principale?" />,
        <ReferenceInput
          source="province"
          label="Provincia"
          reference="autocomplete/province"
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInputSubject
          {...initField("starting_point_on_delivery", options)}
          className="filter-field-small"
          label="Punto di partenza"
        />,
        <ReferenceArrayInput
          reference="reason_status"
          defaultValue={[]}
          source={"reason_status"}
          label="Ragioni"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          reference="reason_status"
          defaultValue={[]}
          source={"reason_status_not"}
          label="Ragioni negate"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          reference="reason_status"
          defaultValue={[]}
          source={"reason_status_not_all"}
          label="Ragioni negate su tutte"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          reference="reason_status"
          defaultValue={[]}
          source={"reason_status_all"}
          label="Ragioni su tutte"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          source="region"
          label="Regioni"
          reference="autocomplete/region"
          className="filter-field-small"
        >
          <AutocompleteArrayInput optionText="label" />
        </ReferenceArrayInput>,
        <NullableBooleanInput
          label="Ritiri in ritardo"
          source="withdraw_delay_alert"
        />,
        <NullableBooleanInput {...initField("programmed_stock_end", options)} label="Sblocco giacenza programmata?" />,
        <NullableBooleanInput {...initField("no_route", options)} label="Senza giro" />,
        <ReferenceInputSubject
          {...initField("subjects_filter", options)}
          label="Soggetti Principali"
          className="filter-field-small"
        />,
        <ReferenceInputSubject
          {...initField("secondary_subjects_filter", options)}
          label="Soggetti Secondari"
          className="filter-field-small"
        />,
        <SelectInput
          {...initField("invoice_status", options, "array")}
          choices={options.invoice_status.choices}
          className="filter-field-small"
          label="Stato fattura"
        />,
        <SelectInput
          {...initField("delivery_type", options, "array")}
          choices={options.delivery_type.choices}
          className="filter-field-small"
        />,
        //<NullableBooleanInput {...initField("is_last_delivery", options)} label="Ultima missione" />,
        <ReferenceInput
          {...initField("user_last_status", options)}
          reference="autocomplete/user"
          label="Utente ultimo stato"
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="zones"
          filter={{ type: "zones" }}
          reference="autocomplete/delivery-zones"
          label="Zone"
        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,

        /*
        <SelectInput
          {...initField("status", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          alwaysOn
        />,
        <SelectInput
          {...initField("delivery_last_status", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          alwaysOn
          label="Stato"
        />,
        <NullableBooleanInput label="Contiene bolle" source="has_notes" />,
        <NullableBooleanInput {...initField("bill_sent", options)} />,
        */

      ]
      : [];
  var appbarMain = document.getElementById("appbar-main");
  var secBar = document.getElementById("secondary-app-bar");

  if (appbarMain) {
    appbarMain.style.display = ""
  }
  if (secBar) {
    secBar.style.display = ""
  }

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      topElement={<DeliveryListHeader />}
      bulkActionButtons={<BulkDeliveryActions />}
      {...getDefaultListProps(props)}
      component={"div"}
      filterDefaultValues={{ initial_delivery_date_after: new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split("T")[0] }}

    //euroExport={true}
    >
      <DeliveryListBody />
    </CustomList>
  ) : null;
};

