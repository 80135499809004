import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormControl,
    Box,
    makeStyles,
    Button,
    Icon,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    timeField: {
        margin: theme.spacing(1),
        width: 150,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

const SubjcetTimeSelector = (
            {days, timeFrom, timeTo, handleChangeDays, handleTimeFromChange, handleTimeToChange, handleSubjectOpenings}:
            {days: [], timeFrom: string, timeTo: string, handleChangeDays: (e:any) => void, 
                handleTimeFromChange: (e:any) => void, handleTimeToChange: (e:any) => void, handleSubjectOpenings: () => void}) => {
    const classes = useStyles();

    

    return (
        <Box>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="days-select-label">Seleziona giorni</InputLabel>
                        <Select
                            labelId="days-select-label"
                            id="days-select"
                            multiple
                            value={days}
                            onChange={handleChangeDays}
                        >
                            {[["MO", "Lunedì"], ["TU", "Martedì"], ['WE',"Mercoledì"], ['TH',"Giovedì"], ['FR',"Venerdì"], ['SA',"Sabato"], ['SU',"Domenica"]].map((day) => (
                                <MenuItem key={day[0]} value={day[0]}>
                                    {day[1]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                id="time-from"
                                label="Ora da"
                                type="time"
                                value={timeFrom}
                                onChange={handleTimeFromChange}
                                className={classes.timeField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                id="time-to"
                                label="Ora a"
                                type="time"
                                value={timeTo}
                                onChange={handleTimeToChange}
                                className={classes.timeField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                fullWidth
                            />
                        </Grid>   
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button color="secondary" onClick={handleSubjectOpenings}><Icon className="fa fa-plus-circle" color="secondary" /> Aggiungi orario</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SubjcetTimeSelector;