import { Grid, Typography } from "@material-ui/core";
import { getUser } from "../authProvider";
import { client } from "../dataProvider";
import { useState } from "react";
import { set } from "lodash";


export const InfoPage = (props: any) => {
  const user = getUser();

  let frontVersion = "4.3.12";
  let frontDate = "03/04/2025";

  const [backVersion, setBackVersion] = useState("");
  const [backDate, setBackDate] = useState("");


  client(`version`, {
    method: "GET",
  }).then((response) => {
    setBackDate(response.date);
    setBackVersion(response.version);
  })
    .catch((error) => {
    })
    .finally(() => {
    });

  return (

    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h3" align="center">Info sistema</Typography>
      </Grid>

      <Grid item xs={12} >
        <Typography variant="h5" align="center">Versione front-end: {frontVersion} del {frontDate}</Typography>
      </Grid >


      <Grid item xs={12} >
        <Typography variant="h5" align="center">Versione back-end: {backVersion} del {backDate}</Typography>
      </Grid >

      <Grid item xs={12} >
        <Typography variant="h5" align="center">Powered by <a href="http://be20.it">Be20</a></Typography>
      </Grid >
    </Grid>


  );
};
