import { AutocompleteInput, BooleanField, Datagrid, DateField, FunctionField, ListProps, NumberField, Record, ReferenceInput, SelectField, SelectInput, TextField, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Grid, Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { DriverField } from "../../components/DriverField";

export const BadgeList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");


  const filters = options
    ? [
      /*

      <ReferenceInput
        {...initField("employee", options)}
        reference="autocomplete/employee"
        source="employee"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("user", options)}
        reference="autocomplete/user"
        source="user"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      */

    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <EmployeeField {...initField("employee_object", options)} label="Operatore" />
        <FunctionField
          label="Utente"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {

              return (
                <Typography>
                  {tableRecord["user_object"].id}: {tableRecord["user_object"].username}
                </Typography>
              );
            }
          }}
          sortable={false}
        />
        <FunctionField
          label="Ultima presenza"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord && tableRecord["last_presence"]) {

              return (
                <a
                  href={"/presences/??displayedFilters={\"id\"%3Atrue}&filter={\"id\"%3A" + tableRecord["last_presence"].id + "}"}
                  target="_blank"
                >
                  <Grid container>
                    <Grid item xs={1}>
                      {tableRecord["last_presence"].id}
                    </Grid>
                    <Grid item container xs={11}>
                      <Grid item xs={12}>
                        {tableRecord["last_presence"].date_in.substring(0, 10)} {tableRecord["last_presence"].date_in.substring(11, 19)}
                      </Grid>
                      <Grid item xs={12}>
                        {
                          tableRecord["last_presence"].date_out ?
                            tableRecord["last_presence"].date_out.substring(0, 10) + " " + tableRecord["last_presence"].date_out.substring(11, 19)
                            : "IN CORSO"
                        }

                      </Grid>
                    </Grid>
                  </Grid>
                </a>
              );
            }
          }}
          sortable={false}
        />
        <NumberField {...initField("max_work_time", options)} label="Massime ore lavoro" />
        <BooleanField {...initField("can_login", options)} />
      </Datagrid>
    </CustomList>
  ) : null;
};
