import { Typography } from "@material-ui/core";
import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  Record,
  BooleanField,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { CachetBoxHeader } from "./CachetBoxHeader";


export const CachetBoxList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <ReferenceArrayInput
      source="username_multiple"
      label="Username"
      reference="autocomplete/user"
      className="filter-field-small"
    >
      <AutocompleteArrayInput optionText="label" />
    </ReferenceArrayInput>,
      <DateInput
        {...initField("created_date_after", options)}
        label="Data dal"
        alwaysOn
      />,
      <DateInput
        {...initField("created_date_before", options)}
        label="Data al"
        alwaysOn
      />,
    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
      topElement={<CachetBoxHeader/>}
    >
      <Datagrid {...options}
        isRowSelectable={() => false}>
        <TextField {...initField("id", options)} />

        <DateField {...initField("created_date", options)} showTime />

        <TextField
          {...initField("username", options)}
        />
        <BooleanField
          {...initField("closed", options)}
        />

        <FunctionField
          label="Totali"
          source="totals"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord && tableRecord.totals) {

              let text = "";
              for (let x in tableRecord.totals) {
                text += ` ${x}: ${Math.round(tableRecord.totals[x] * 100) / 100} // `;
              }
              return <Typography>
                {
                  text
                }
              </Typography>

            }
            return null;
          }}
        />

      </Datagrid>
    </CustomList>
  ) : null;
};
